import React, { useEffect, useState } from "react";
import {
  Button,
  Chip,
  ChipList,
  ChipProps,
  DropDownButton,
} from "@progress/kendo-react-buttons";
import { Tooltip } from "@progress/kendo-react-tooltip";
import BuzopsLoader from "generic-components/BuzopsLoader";
import moment from "moment";
import { PackageService } from "services/package/index.service";
import { TenantService } from "services/tenant/index.service";
import { renderFrequencyItem } from "components/package/PurchasePackageList";
import {
  CheckUserMemberStatus,
  CurrencyFormat,
  CustomToFixed,
  checkHasTaxEnable,
  checkIfUserAddPaymentGateway,
  checkIfUserCanAddBankAccount,
  paymentDropdownItems,
} from "utils";
import { filterBy } from "@progress/kendo-data-query";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import BuzopsButton from "generic-components/BuzopsButton";
import withNotification from "components/Hoc/withNotification";
import PackageCoupons from "../PackageCoupons";
import { ManageTaxesService } from "services/managetaxes/index.service";
import { TooltipContentPackageService } from "components/checkout/OrderSummary";
import PurchaseClassList from "components/class/PurchaseClassList";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import {
  CreditsErrorMessage,
  PackageFrequencyEnum,
  PackageInstanceClassMappingStatus,
  PackageInstanceStatus,
  PackageInstanceStatusEnum,
  RenewOptionsNoLastPayment,
  SessionTypeEnum,
  TypeEnum,
  recurrenceUnits,
} from "utils/form-utils";
import { usePackageUtilization } from "components/package/PackageUtilization.hook";
import { Error } from "@progress/kendo-react-labels";
import PackageUtilization from "components/package/PackageUtilization";
import { BiEdit, BiHistory } from "react-icons/bi";
import {
  Checkbox,
  Input,
  NumericTextBox,
  Switch,
} from "@progress/kendo-react-inputs";
import { Notification as Notify } from "@progress/kendo-react-notification";
import ReactTooltip from "react-tooltip";
import AppointmentTabs from "../AppointmentTabs";
import { Card, CardBody } from "@progress/kendo-react-layout";
import AppointmentsBox from "../AppointmentsBox";
import { BuzopsTooltip } from "generic-components/BuzopsTooltip";
import { useHistory } from "react-router-dom";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import {
  PaymentItemRender,
  PaymentValueRender,
} from "components/Payment/PaymentRenderItems";
import { Hint } from "@progress/kendo-react-labels";
import { useApiClient } from "services/axios-service-utils";
import {
  SessionSignOffPermission,
  signOffComplimentaryPermission,
} from "utils/permission-utils";
import { useSelector } from "react-redux";
import { GetLocalStore } from "utils/storage";
import PaymentModalCreation from "components/PaymentCheckout/PaymentModalCreation";
import {
  Upload,
  UploadFileInfo,
  UploadOnAddEvent,
  UploadOnRemoveEvent,
} from "@progress/kendo-react-upload";
const TooltipContentSchedules = (propData: any) => {
  return (
    <>
      <ul className="list-group list-group-flush taxes-list">
        <li className="list-group-item d-flex justify-content-between align-items-center">
          <span>{"Rollover Appointments"}</span>
          <span className="price">{propData.data?.RollOverSchedules}</span>
        </li>
        {/* <li className="list-group-item d-flex justify-content-between align-items-center">
            <span>
              {"Reverted Appointments"}
            </span>
            <span className="price">{propData.data?.RevertedSchedules}</span>
          </li> */}
        {/* {propData.data?.RollOverSchedules > 0 &&      <li className="list-group-item">
          <span>
            {"RolledOverSchedules Rolled over to next billing cycle"}
          </span>
        </li>} */}
      </ul>
    </>
  );
};
const TooltipContentAppointments = (propData: any) => {
  return (
    <>
      <ul className="list-group list-group-flush taxes-list rolled-over-text">
        <li className="list-group-item d-flex justify-content-between align-items-center">
          <span>
            {`${propData.data?.RolledOverSchedules} Appointment(s) rolled over to next billing`}
          </span>
        </li>
      </ul>
    </>
  );
};
interface AppointmentDialogData {
  costPerAppointment: number;
  apptCount: number;
  PaymentProfile: any;
  refNumber: string;
  IsTaxSkipped: boolean;
}
const PackageOverView = (props: any) => {
  const history = useHistory();
  const redirectionObj = useSelector((state: any) => state?.redirection);
  const { axiosRequest } = useApiClient();
  const [loading, setLoading] = useState<any>(true);
  const [removeCouponValue, setRemoveCouponValue] = useState<any>(null);
  const [couponValue, setCouponValue] = useState<any>(null);
  const [showCoupon, setShowCoupon] = useState<boolean>(false);
  const [CouponsToAdd, setCouponsToAdd] = useState<any>([]);
  const [OriginalCouponsToAdd, setOriginalCouponsToAdd] = useState<any>([]);
  const [selectedPackage, setSelectedPackage] = useState<any>(null);
  const [packagesToUse, setPackagesToUse] = useState<any>(null);
  const [couponsList, setCouponsList] = useState<any>([]);
  const tenantService = new TenantService();
  const [couponAddVisible, setCouponAddVisible] = useState<any>(false);
  const [couponRemoveVisible, setCouponRemoveVisible] = useState<any>(false);
  const [dialogLoading, setDialogLoading] = useState<any>(false);
  const [rolloverLoading, setRollOverLoading] = useState<any>(false);
  const [PredictedTaxes, setPredictedTaxes] = useState<any>([]);
  const [PredictedTotalTaxAmount, setPredictedTotalTaxAmount] =
    useState<number>(0);
  const [groupDialog, setGroupDialog] = useState(false);
  const [showUtilizations, setShowUtilizations] = useState(false);
  const [showRolloverDialog, setShowRollOverDialog] = useState(false);
  const [selectedBillingPeriod, setSelectedBillingPeriod] = useState<any>(null);
  const [rolloverAppointment, setRolloverAppointment] = useState<any>(0);

  const [renewChecked, setRenewChecked] = useState<boolean>(false);
  const [PayInFull, setPayInFull] = useState<boolean>(false);
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [renewOptions, setRenewOptions] = useState(RenewOptionsNoLastPayment);
  const [selectedRenewOption, setSelectedRenewOption] = useState(
    renewOptions.find((e) => e.id === 0)
  );
  const [visibleAC, setVisibleAC] = useState<boolean>(false);
  const [paymentProfile, setPaymentProfile] = useState<any>([]);
  const [selectedPaymentProfile, setSelectedPaymentProfile] =
    useState<any>(undefined);
  const [formModified, setFormModified] = useState<any>(false);
  const [updateRecurrenceSubmitLoading, setUpdateRecurrenceSubmitLoading] =
    useState(false);
  const [showAppointmentDialog, setShowAppointmentDialog] = useState(false);
  const [showAppointmentHistoryDialog, setShowAppointmentHistoryDialog] = useState(false);
  const [showAppointmentHistoryLoading, setShowAppointmentHistoryLoading] = useState(false);
  const [appointmentHistory, setAppointmentHistory] = useState([]);
  const [appointmentDialogData, setAppointmentDialogData] =
    useState<AppointmentDialogData>({
      costPerAppointment: 0,
      apptCount: 0,
      PaymentProfile: null,
      refNumber: "",
      IsTaxSkipped: !selectedPackage?.CancollectTaxes,
    });
  const [selectedAppointmentDataItem, setSelectedAppointmentDataItem] =
    useState<any>(null);
  const [showPaymentDialog, setShowPaymentDialog] = useState(false);
  const [addNewItem, setAddNewItem] = useState<any>(null);
  const [btnLoading, setBtnLoading] = useState<any>(false);
  const [Taxes, setTaxes] = useState<any>([]);
  const [TotalTaxAmount, setTotalTaxAmount] = useState<number>(0);
  //handling for Backabutton
  const [showPreset, setshowpreset] = useState(
    props?.location?.state?.showPreset || props?.showPreset || false
  );
  const [showMore, setShowMore] = useState(false);

  const initialRenewalData = {
    PackageCost: 0,
    PrivateServiceInstanceId: 0,
    CanRenew: false,
    RenewalOcurrsEvery: 0,
    RenewalRepeatFrequency: 0,
    RenewalNoOfInstallments: 0,
    RenewalDownPayment: 0,
    RenewalType: 0,
    PayInFull: false,
    RenewalPaymentGatewayPaymentProfileId: 0,
    RecurringAmount: 0,
    PredictedRenewalDateString: null,
  };
  let initialItemsToShow = 9;

  const [renewalDetailsCurrent, setRenewalDetailsCurrent] =
    useState<any>(initialRenewalData);
  const [renewalFormDetails, setRenewalFormDetails] =
    useState<any>(initialRenewalData);
  const [itemsToShow, setItemsToShow] = useState(initialItemsToShow);
  const [filteredItems, setFilteredItems] = useState([]);

  //upload
  const [originalFiles, setOriginalFiles] = useState<Array<UploadFileInfo>>([]);
  const [files, setFiles] = useState<Array<any>>([]);

  useEffect(() => {
    constructFileWithBas64(originalFiles);
  }, [originalFiles]);

  const handleTaxCalculationForAppt = (purchaseAmt: any, TaxItems = Taxes) => {
    const TaxesArray = TaxItems.map((i: any) => {
      const amount = (purchaseAmt * i?.Percentage) / 100;
      const calcAmt = CustomToFixed(amount, 2);
      return {
        TaxId: i?.TaxId,
        TaxName: i?.TaxName,
        Percentage: i?.Percentage,
        Amount: calcAmt,
      };
    });
    const taxAmt = TaxesArray?.reduce((acc: number, currentValue: any) => {
      return acc + currentValue?.Amount;
    }, 0);
    setTaxes(TaxesArray);
    setTotalTaxAmount(taxAmt);
  };
    const amountCell = (props: any) => {
      return (
        <td className={"capitalize"}>
          {CurrencyFormat(props.dataItem?.Rate.toFixed(2))}
        </td>
      );
    };

  const constructFileWithBas64 = (records: any) => {
    if (records.length > 0) {
      let fileData: any = [];
      records.forEach((file: any) => {
        const reader: any = new FileReader();
        const test = {
          Type: 1,
          ImageAsBase64: null,
          FileName: file?.name,
          Extension: file?.extension,
          Size: file?.size,
          InternalDataId: file?.uid,
        };
        reader.onloadend = function () {
          // Since it contains the Data URI, we should remove the prefix and keep only Base64 string
          fileData.push({ ...test, ImageAsBase64: reader?.result });
          setFiles(fileData);
        };
        reader.readAsDataURL(file.getRawFile());
      });
    } else {
      setFiles([]);
    }
  };

  const onAdd = (event: UploadOnAddEvent) => {
    setOriginalFiles(event.newState);
  };

  const onRemove = (event: UploadOnRemoveEvent) => {
    setOriginalFiles(event.newState);
  };

  const {
    loading: utilizationLoading,
    billingPeriods,
    handleChange,
    selectedPeriod,
    fetchBillingAPI,
  } = usePackageUtilization(props);

  useEffect(() => {
    let memberId = props?.userData?.UserMemberId;
    if (memberId) {
      fetchAllApis(memberId);
    }
  }, []);

  useEffect(() => {
    if (
      renewalDetailsCurrent.PackageCost ===
      renewalDetailsCurrent.RenewalDownPayment
    ) {
      setPayInFull(true);
    } else {
      setPayInFull(false);
    }
  }, [renewalDetailsCurrent]);

  useEffect(() => {
    if (
      PayInFull ||
      renewalDetailsCurrent.PackageCost ===
        renewalDetailsCurrent.RenewalDownPayment
    ) {
      renewalDetailsCurrent.RenewalType
        ? setSelectedRenewOption(renewOptions.find((e) => e.id === 2))
        : setSelectedRenewOption(renewOptions.find((e) => e.id === 0));
      handleUpdateRenewal(
        renewalDetailsCurrent.RenewalType ? 2 : 0,
        "RenewalType"
      );
    } else {
      setSelectedRenewOption(
        renewOptions.find((e) => e.id === renewalDetailsCurrent.RenewalType)
      );
      handleUpdateRenewal(renewalDetailsCurrent.RenewalType, "RenewalType");
    }
  }, [renewOptions]);

  useEffect(() => {
    if (renewChecked) {
      if (
        PayInFull ||
        renewalDetailsCurrent.PackageCost ===
          renewalDetailsCurrent.RenewalDownPayment
      ) {
        renewalDetailsCurrent.RenewalType
          ? setSelectedRenewOption(renewOptions.find((e) => e.id === 2))
          : setSelectedRenewOption(renewOptions.find((e) => e.id === 0));
        handleUpdateRenewal(
          renewalDetailsCurrent.RenewalType ? 2 : 0,
          "RenewalType"
        );
      } else {
        setSelectedRenewOption(
          renewOptions.find((e) => e.id === renewalDetailsCurrent.RenewalType)
        );
        handleUpdateRenewal(renewalDetailsCurrent.RenewalType, "RenewalType");
      }
    } else {
      setSelectedRenewOption(renewOptions.find((e) => e.id === 0));
      handleUpdateRenewal(0, "RenewalType");
    }
  }, [renewChecked]);

  const fetchAllApis = async (memberId = null) => {
    setLoading(true);
    const taxes = await fetchTaxes();
    await fetchPackageSummary(taxes, true);
    await fetchPackagesToUse();
    await getCouponData();
    setLoading(false);
  };

  const fetchTaxes = async () => {
    const req = {};
    const taxService = new ManageTaxesService();
    const res = await taxService.getTaxes(req);
    setPredictedTaxes(res);
    setTaxes(res);
    return res;
  };

  const fetchPackageSummary = async (
    taxes = PredictedTaxes,
    initial = false
  ) => {
    const req = {
      UserMemberId: props?.userData?.UserMemberId,
      PackageInstanceId: props?.selectedPackageId,
    };
    if (!initial) {
      setLoading(true);
    }
    const service = new PackageService();
    const getPackageDetails = await service.packageInstanceFilter(req);
    const data = {
      ...getPackageDetails?.Items[0],
      Occurrence: getPackageDetails?.Items[0]?.Occurrs,
    };
    props?.setSelectedSubscription(data);
    const statusDescription = PackageInstanceStatus?.filter(
      (i: any) =>
        i?.id ===
        (selectedPackage?.Status === 1 && selectedPackage?.CancelledOn
          ? 8
          : selectedPackage?.Status)
    )?.[0]?.text;
    // props?.setSelectedSubscriptionStatus(statusDescription);
    setSelectedPackage(data);
    setFilteredItems(data?.AssociatedClasses?.slice(0, itemsToShow));
    const d = {
      PackageCost: data?.Cost,
      PackageInstanceId: data?.PackageInstanceId,
      CanRenew: data?.RenewalType ? true : false,
      RenewalOcurrsEvery: data.RenewalOcurrsEvery,
      RenewalRepeatFrequency: data.RenewalFrequency,
      RenewalNoOfInstallments: data?.RenewalNoOfInstallments,
      RenewalDownPayment: data?.RenewalDownPayment,
      RenewalType: data?.RenewalType,
      PayInFull: data.Cost === data.RenewalDownPayment ? true : false,
      RenewalPaymentGatewayPaymentProfileId:
        data?.RenewalPaymentGatewayPaymentProfileId,
      RecurringAmount: data?.RecurringAmount,
    };
    setRenewalFormDetails(d);
    setRenewalDetailsCurrent(d);
    setRenewChecked(d.RenewalType > 0 ? true : false);
    setSelectedRenewOption(
      renewOptions.find((e) => e.id === data?.RenewalType)
    );
    let couponsArray = [...data?.CompletedCoupons, ...data?.ActiveCoupons];
    setCouponsList(couponsArray || []);
    setShowCoupon(false);
    setCouponValue(null);
    handleTaxCalculation(data?.RecurringAmount, taxes);
    if (!initial) {
      setLoading(false);
    }
  };

  const fetchPackagesToUse = async () => {
    let packageInstanceId = props?.selectedPackageId;
    if (typeof packageInstanceId === "string") {
      packageInstanceId = parseInt(packageInstanceId);
    }
    let startDate = moment().format("YYYY-MM-DD") + "T00:00:00";
    let ToDate = moment().add(1, "year").format("YYYY-MM-DD") + "T23:59:59";
    const req = {
      UserMemberId: props?.userData?.UserMemberId,
      FromDate: startDate,
      ToDate,
      PackageInstanceId: packageInstanceId,
    };
    const service = new PackageService();
    const result = await service.packageInstanceToUse(req);
    const dataItem = result?.find(
      (i: any) => i?.PackageInstanceId === packageInstanceId
    );
    setPackagesToUse(dataItem || null);
  };

  const checkBillingPeriodsforSessionSignoff = () => {
    const check = billingPeriods?.filter((i: any) => {
      return i?.Schedules - i?.RolledOverSchedules - i?.UsedSchedules > 0;
    });
    if (check?.length > 0) {
      return true;
    }
    return false;
  };

  const handleConfirmation = async () => {
    const userDetails = await fetchClientData(selectedPackage?.UserMemberId);
    //  const displayPackage=(selectedPackage?.SessionType === 1 &&
    //     checkBillingCycleValidation()) ||
    //   selectedPackage?.SessionType === 2
    history.push(
      `/member/${props?.uuid}/${props?.userData?.UserMemberId}/scheduleappointment`,
      {
        // selectedPackageInstanceId: displayPackage ? selectedPackage?.PackageInstanceId : undefined,
        staffPage: true,
        userData: userDetails ? userDetails : props?.userData,
        uuid: props?.uuid,
        Complimentary: false,
        quickcheckout: true,
        redirectedFrom: `package-${selectedPackage?.PackageInstanceId}`,
        showPreset: showPreset,
        showOnlySignoff: true,
        Type: selectedPackage?.Type,
        purchaseGroup:selectedPackage?.Type === TypeEnum.Class ? true : false
      }
    );
  };

  const handleTaxCalculation = (
    purchaseAmt: number,
    TaxItems = PredictedTaxes
  ) => {
    const TaxesArray = TaxItems.map((i: any) => {
      const amount = (purchaseAmt * i?.Percentage) / 100;
      const calcAmt = CustomToFixed(amount, 2);
      return {
        TaxId: i?.TaxId,
        TaxName: i?.TaxName,
        Percentage: i?.Percentage,
        Amount: calcAmt,
      };
    });
    const taxAmt = TaxesArray?.reduce((acc: number, currentValue: any) => {
      return acc + currentValue?.Amount;
    }, 0);
    setPredictedTaxes(TaxesArray);
    setPredictedTotalTaxAmount(taxAmt);
  };
  const getCouponData = async () => {
    const req = {
      UserMemberId: props?.userData?.UserMemberId,
      EntityType: 7,
      EntityId: props?.selectedPackageId,
      CouponType: 1,
    };
    const res = await tenantService.AvailableCouponsToApply(req);
    setCouponsToAdd(res);
    setOriginalCouponsToAdd(res);
  };
  const handleCoupon = (val: any) => {
    setCouponValue(val);
  };

  const CouponfilterChange = (e: any) => {
    const filteredData = CouponFilterData(e.filter);
    setCouponsToAdd(filteredData);
  };

  const CouponFilterData = (filter: any) => {
    const data = OriginalCouponsToAdd.slice();
    return filterBy(data, filter);
  };

  const handleAddCouponToPackage = async (val: boolean) => {
    if (val) {
      await AddCouponToPackage();
    }
    toggleAddCouponDialog();
  };

  const AddCouponToPackage = async () => {
    const req = {
      ...couponValue,
      EntityType: 5,
      EntityId: props?.selectedPackageId,
      UserMemberId: props?.userData?.UserMemberId,
    };
    setDialogLoading(true);
    const result = await tenantService?.AddCouponToExistingPlan(req);
    setDialogLoading(false);
    if (result?.ResponseCode === 100) {
      const successMsg = `The Coupon Applied Successfully`;
      props?.handleNotificationMessage(successMsg, "success");
      await fetchPackageSummary();
    } else {
      const errorMsg = result?.ErrorMessages?.[0] || "Error In Applying Coupon";
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };

  const handleRemoveCouponFromPackage = async (val: boolean) => {
    if (val) {
      await handleRemoveCoupon();
    }
    toggleRemoveCouponDialog();
  };
  const handleRemoveCoupon = async () => {
    const { MemberCouponId } = removeCouponValue;
    const req = {
      MemberCouponId,
      EntityType: 5,
      UserMemberId: props?.userData?.UserMemberId,
      EntityId: props?.selectedPackageId,
    };
    setDialogLoading(true);
    const result = await tenantService?.DeActivateMemberCoupon(req);
    setDialogLoading(false);
    if (result?.ResponseCode === 100) {
      const successMsg = result?.SuccessMessage;
      props?.handleNotificationMessage(successMsg, "success");
      await fetchPackageSummary();
    } else {
      const errorMsg = result?.ErrorMessages?.[0] || "Error In Removing Coupon";
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };

  const toggleAddCouponDialog = () => {
    if (!couponValue) {
      const errorMsg = "Please select coupon";
      props?.handleNotificationMessage(errorMsg, "error");
      return;
    }
    if (!props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage("", "error", true, "client");
      return;
    } else if (props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage("", "error", false, "staff");
      return;
    }
    setCouponAddVisible(!couponAddVisible);
  };
  const toggleRemoveCouponDialog = () => {
    setCouponRemoveVisible(!couponRemoveVisible);
  };

  const handleRemoveCouponItem = (dataItem: any) => {
    setRemoveCouponValue(dataItem);
    toggleRemoveCouponDialog();
  };

  const renderAssociatedGS = () => {
    if (loading) {
      return <BuzopsLoader type={"list"} />;
    }
    if (
      !selectedPackage?.AssociatedClasses ||
      selectedPackage?.AssociatedClasses?.length === 0
    ) {
      return selectedPackage?.Type === TypeEnum.Class
        ? "No Group to redeem"
        : "No Appointment to redeem";
    }

    return (
      <div className="row bz-mt-2rem bz-mb-2_5rem">
        <div className="bz-class-description bz-packages-tags-new bz-redeem-package redeempacages">
          <ChipList
            style={{ width: "100%", flexWrap: "wrap" }}
            defaultData={filteredItems}
            data={filteredItems}
            chip={(props1: ChipProps) => {
              return (
                <Chip
                  style={{
                    width: "auto",
                    borderRadius: "11px",
                    fontSize: "14px",
                    height: "22px",
                    marginBottom: "5px",
                  }}
                  type={"success"}
                  disabled={true}
                  text={props1?.dataItem?.Name}
                  removable={false}
                />
              );
            }}
          />
        </div>
        <div className="d-flex justify-content-center mt-2">
          {selectedPackage?.AssociatedClasses.length > initialItemsToShow && (
            <span
              onClick={() => {
                if (!showMore) {
                  setFilteredItems(selectedPackage?.AssociatedClasses);
                  setShowMore(!showMore);
                } else {
                  setItemsToShow(initialItemsToShow);
                  setFilteredItems(
                    selectedPackage?.AssociatedClasses?.slice(
                      0,
                      initialItemsToShow
                    )
                  );
                  setShowMore(!showMore);
                }
              }}
              className="btn btn-link"
            >
              {!showMore ? "Show more" : "Show less"}
            </span>
          )}
        </div>
      </div>
    );
  };
  const DateCell = (props: any) => {
    if (
      props?.dataItem?.IsCurrentBillingPeriod &&
      props?.field === "RenewalOn" &&
      selectedPackage?.CancelledOn
    ) {
      return <td>NA</td>;
    }
    return (
      <td>
        {props?.dataItem[props?.field]
          ? moment(props?.dataItem[props?.field]).format("L")
          : "NA"}
      </td>
    );
  };
  const RollOverRevertedAppts = (propData: any, packageItem: any) => {
    // if (propData?.dataItem?.BillingPeriod === 1) {
    //   return <td>{`NA`}</td>;
    // }
    let check = false;
    if (
      billingPeriods?.length > 1 &&
      billingPeriods?.find((i: any) => i?.Status === 2) &&
      propData?.dataItem?.Status === 1 &&
      packageItem?.Status === 1 &&
      props?.staffPage
    ) {
      check = true;
    }
    const RollOverSchedules = propData?.dataItem?.RollOverSchedules;
    const UsedRollOverSchedules = propData?.dataItem?.UsedRollOverSchedules;
    const RevertedSchedules = propData?.dataItem?.RevertedSchedules;
    const totalSchedules = RollOverSchedules + RevertedSchedules;
    if (totalSchedules === 0) {
      return <td>{totalSchedules}</td>;
    }
    return (
      <td className="d-flex">
        <span>
          {UsedRollOverSchedules > 0 ? (
            <div className="d-flex">
              <span
                className="btn-link"
                onClick={() => {
                  setShowUtilizations((prev) => !prev);
                  handleChange(propData?.dataItem);
                }}
                data-for={"appointment-link"}
                data-tip={"Used Rollover Appointments"}
              >
                {propData?.dataItem?.UsedRollOverSchedules}
              </span>{" "}
              <span className="px-1">{` of `}</span>{" "}
              <span>
                <Tooltip
                  content={(tooltipProps: any) => {
                    return (
                      <TooltipContentSchedules
                        title={tooltipProps.title}
                        data={propData?.dataItem}
                      />
                    );
                  }}
                  tooltipClassName="bz-tooltip-table"
                >
                  <a
                    title="Schedules"
                    style={{
                      borderBottom: "1px dotted #0d6efd",
                      cursor: "pointer",
                    }}
                  >
                    {totalSchedules}
                  </a>
                </Tooltip>
              </span>
            </div>
          ) : (
            <div className="d-flex">
              <span>{propData?.dataItem?.UsedRollOverSchedules}</span>{" "}
              <span className="px-1">{` of `}</span>
              <span>
                <Tooltip
                  content={(tooltipProps: any) => {
                    return (
                      <TooltipContentSchedules
                        title={tooltipProps.title}
                        data={propData?.dataItem}
                      />
                    );
                  }}
                  tooltipClassName="bz-tooltip-table"
                >
                  <a
                    title="Schedules"
                    style={{
                      borderBottom: "1px dotted #0d6efd",
                      cursor: "pointer",
                    }}
                  >
                    {totalSchedules}
                  </a>
                </Tooltip>
              </span>
            </div>
          )}
        </span>
        {/* {check && (
          <span
            className="cursor-pointer pl-2"
            data-for={"edit-rollover"}
            data-tip={"Edit Rollover Appointments"}
          >
            <BiEdit
              color="#008DB9"
              size={"16px"}
              onClick={() => handleEditRollOver(propData.dataItem)}
            />
          </span>
        )} */}
        {/* <ReactTooltip
          id={`edit-rollover`}
          place="top"
          type="dark"
          effect="float"
          html={true}
        />{" "} */}
      </td>
    );
  };
  const handleEditRollOver = (dataItem: any) => {
    setSelectedBillingPeriod(dataItem);
    setShowRollOverDialog(true);
    setRolloverAppointment(dataItem?.RollOverSchedules);
  };
  const getAvailableAppts = (dataItem: any) => {
    if(selectedPackage?.SessionType===SessionTypeEnum.PRESET){
      return selectedPackage?.UnusedPaidSchedules || 0
    }else{
      const RollOverSchedules = dataItem?.RollOverSchedules;
      const UsedRollOverSchedules = dataItem?.UsedRollOverSchedules;
      const RevertedSchedules = dataItem?.RevertedSchedules;
      const usedSchedules = dataItem?.UsedSchedules;
      const schedules = dataItem?.Schedules;
      return (
        schedules +
          RollOverSchedules +
          RevertedSchedules -
          (usedSchedules + UsedRollOverSchedules) || 0
      );
    }
  };
  const handleAppointmentsEdit = (dataItem: any,preset=false) => {
    fetchPaymentProfiles(props?.userData?.MainUserId);
    setShowAppointmentDialog(true);
    setAppointmentDialogData({
      costPerAppointment: 0,
      apptCount: 0,
      PaymentProfile: null,
      refNumber: "",
      IsTaxSkipped: !selectedPackage?.CancollectTaxes,
    });
    setSelectedAppointmentDataItem(dataItem);
  };
  const handleAppointmentshistory = async(dataItem: any) => {
    setShowAppointmentHistoryDialog(true);
    setSelectedAppointmentDataItem(dataItem);
    const PackageInstanceBillingPeriod =
    dataItem?.PackageInstanceClassMappingId;
    const req = {
      PackageInstanceId: selectedPackage?.PackageInstanceId,
      PackageInstanceBillingPeriod: PackageInstanceBillingPeriod,
    };
    setShowAppointmentHistoryLoading(true)
    await axiosRequest.post("PackageInstance/ondemandpurchasehistory", req, {
      successCallBack: async (response: any) => {
        setShowAppointmentHistoryLoading(false);
        setAppointmentHistory(response?.data?.Items || [])
      },
      errorCallBack: (response: any) => {
        setShowAppointmentHistoryLoading(false);
        const errorMsg =
          response?.response?.data?.Messages?.[0] ||
          response?.data?.Messages?.[0] ||
          "unable to fetch Data";
        console.log(errorMsg, "error");
      },
    });
  };
  const handleIncreaseApptsCount = () => {
    const count = appointmentDialogData.apptCount + 1;
    setAppointmentDialogData({
      ...appointmentDialogData,
      apptCount: count,
    });
    handleTaxCalculationForAppt(
      appointmentDialogData?.costPerAppointment * count
    );
  };

  const handleRemoveAppointments = async () => {
    const PackageInstanceBillingPeriod =
      selectedAppointmentDataItem?.PackageInstanceClassMappingId;
    const req = {
      UserMemberId: selectedPackage?.UserMemberId,
      PackageInstanceId: selectedPackage?.PackageInstanceId,
      PackageInstanceBillingPeriod: selectedPackage?.SessionType === SessionTypeEnum.PRESET ? undefined : PackageInstanceBillingPeriod,
      NoOfAppointments: Math.abs(appointmentDialogData.apptCount),
    };
    setBtnLoading(true);
    await axiosRequest.post("PackageInstance/RemoveAppointment", req, {
      successCallBack: async (response: any) => {
        setBtnLoading(false);
        const successMsg = "Appointment(s) Removed Successfully";
        props?.handleNotificationMessage(successMsg, "success");
        setShowAppointmentDialog(false);
        await fetchAllApis();
        await fetchBillingAPI();
      },
      errorCallBack: (response: any) => {
        setBtnLoading(false);
        const errorMsg =
          response?.response?.data?.Messages?.[0] ||
          response?.data?.Messages?.[0] ||
          "unable to remove appointments";
        props?.handleNotificationMessage(errorMsg, "error");
      },
    });
  };

  const handleAddAppointments = async () => {
    if (
      appointmentDialogData.costPerAppointment > 0 &&
      !appointmentDialogData?.PaymentProfile
    ) {
      const errorMsg = "Please select Payment Profile";
      props?.handleNotificationMessage(errorMsg, "error");
      return;
    }
    let paymentAttributes = null;
    if (
      appointmentDialogData.costPerAppointment > 0 &&
      appointmentDialogData?.PaymentProfile?.CardTypeId === -2
    ) {
      paymentAttributes = {
        Reference: appointmentDialogData.refNumber,
        OfflinePaymentDescription:
          appointmentDialogData?.PaymentProfile?.MaskedCCNumber,
        ReferrenceDocumentName: files[0]?.FileName || "",
        ReferrenceDocumentContent: files[0]?.ImageAsBase64 || "",
      };
    }
    const PackageInstanceBillingPeriod =
      selectedAppointmentDataItem?.PackageInstanceClassMappingId;
    const apptCount = Math.abs(appointmentDialogData.apptCount);
    const DownPayment = apptCount * appointmentDialogData.costPerAppointment;
    const req = {
      UserMemberId: selectedPackage?.UserMemberId,
      PaymentGatewayPaymentProfileId:
        appointmentDialogData?.PaymentProfile?.PaymentGatewayPaymentProfileID,
      PackageInstanceId: selectedPackage?.PackageInstanceId,
      PackageInstanceBillingPeriod: selectedPackage?.SessionType === SessionTypeEnum.PRESET ? undefined : PackageInstanceBillingPeriod,
      NoOfAppointments: Math.abs(appointmentDialogData.apptCount),
      DownPayment: DownPayment,
      PaymentAttributes: paymentAttributes,
      TotalTaxAmount:
        appointmentDialogData.costPerAppointment > 0 &&
        !appointmentDialogData?.IsTaxSkipped
          ? TotalTaxAmount
          : 0,
      Taxes:
        appointmentDialogData.costPerAppointment > 0 &&
        !appointmentDialogData?.IsTaxSkipped
          ? Taxes
          : [],
      AlternatePaymentProfileId: 0,
      AmountChargeByAlternatePaymentProfile: 0,
      Rate: appointmentDialogData?.costPerAppointment,
      CanCollectTaxes: !appointmentDialogData?.IsTaxSkipped,
    };

    setBtnLoading(true);
    await axiosRequest.post("PackageInstance/AddAppointment", req, {
      successCallBack: async (response: any) => {
        setBtnLoading(false);
        const successMsg = "Appointment(s) Added Successfully";
        props?.handleNotificationMessage(successMsg, "success");
        setShowAppointmentDialog(false);
        await fetchAllApis();
        await fetchBillingAPI();
      },
      errorCallBack: (response: any) => {
        setBtnLoading(false);
        const errorMsg =
          response?.response?.data?.Messages?.[0] ||
          response?.data?.Messages?.[0] ||
          "unable to add appointments";
        props?.handleNotificationMessage(errorMsg, "error");
      },
    });
  };

  const handleDecreaseApptsCount = () => {
    const appts = getAvailableAppts(selectedAppointmentDataItem);
    if (appts + appointmentDialogData.apptCount <= 0) {
      return;
    }
    const count = appointmentDialogData.apptCount - 1;
    setAppointmentDialogData({
      ...appointmentDialogData,
      apptCount: count,
    });
    handleTaxCalculationForAppt(
      appointmentDialogData?.costPerAppointment * count
    );
  };

  const handleUpdateRollOver = async () => {
    let mininmumRolloverAppts = 0;
    if (selectedBillingPeriod) {
      const totalUsedSchedules =
        selectedBillingPeriod?.UsedRollOverSchedules +
        selectedBillingPeriod?.UsedSchedules;
      const check = totalUsedSchedules - selectedBillingPeriod?.Schedules;
      if (check > 0) {
        mininmumRolloverAppts = check;
      }
    }
    if (rolloverAppointment < mininmumRolloverAppts) {
      return;
    }
    const req = {
      PackageInstanceClassMappingId:
        selectedBillingPeriod.PackageInstanceClassMappingId,
      RollOverSchedules: rolloverAppointment,
    };
    const service = new PackageService();
    setRollOverLoading(true);
    const res = await service.UpdateRollOverAppoinments(req);
    setRollOverLoading(false);
    if (res?.ResponseCode === 100) {
      const successMsg = `Rollover Appointment Updated Successfully`;
      props?.handleNotificationMessage(successMsg, "success");
      handleCancelRollOver();
      await fetchPackageSummary();
      await fetchBillingAPI();
    } else {
      const errorMsg =
        res?.ErrorMessages?.[0] || "Error In Updating  Rollover Appointment";
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };

  const handleCancelRollOver = () => {
    setSelectedBillingPeriod(null);
    setShowRollOverDialog(false);
    setRolloverAppointment(0);
  };

  const handleEditRequency = () => {
    props?.handleSubMenuNav(25, false);
  };

  async function fetchPaymentProfiles(userMemberId: any, modeVal = null) {
    const paymentProfileData = new TenantService();
    const result = await paymentProfileData.paymentProfile(userMemberId);
    if (result) {
      if (result.length > 0) {
        if (modeVal) {
          const record = result?.find((i: any) => i?.CardTypeId === -1);
          if (record) {
            const data = {
              ...record,
              CardTypeId: -2,
              MaskedCCNumber: modeVal,
              CardDescription: modeVal,
              CardDescriptionWithoutExpiryDate: modeVal,
            };
            const res = [...result];
            res.push(data);
            setPaymentProfile(JSON.parse(JSON.stringify(res)));
            setAppointmentDialogData({
              ...appointmentDialogData,
              PaymentProfile: data,
            });
          }
        } else {
          setPaymentProfile(result);
          setSelectedPaymentProfile(
            result.find(
              (r: any) =>
                r.PaymentGatewayPaymentProfileID ===
                renewalDetailsCurrent.RenewalPaymentGatewayPaymentProfileId
            )
          );
        }
      }
    }
  }

  const handleEditRenewal = async () => {
    fetchPaymentProfiles(props?.userData?.MainUserId);
    setVisibleAC(!visibleAC);
  };

  const renderRedeemDetails = () => {
    return (
      selectedPackage?.SessionType !== SessionTypeEnum.PRESET && (
        <>
          <h5 className="mb-md-3 pt-3 bz_fs_1_5 mt-3">Redeem for</h5>
          <div className="mb-3">
            <div className="row">
              <div className="bz-associated-groups bz-classes-tag-card">
                <div className="card-body p-0">{renderAssociatedGS()}</div>
              </div>
            </div>
          </div>
        </>
      )
    );
  };

  const renderPresetSubscriptionDetails = () => {
    if (loading) {
      return <BuzopsLoader type={"list"} />;
    }
    if (selectedPackage === null) {
      return "No Subscriptions Data Found";
    }

    const {
      Name,
      Status,
      PurchasedOn,
      Cost,
      SessionType,
      ExpireOn,
      Schedules,
      RecurringAmount,
      SalePerson,
      Frequency,
      CancollectTaxes,
      CancelledOn,
      AmountPaid,
      RatePerSchedule,
      NextRecurrence,
      Occurrs,
      RepeatFrequency,
      NoOfInstallments,
      RenewalType = 0,
      RenewalStatus,
    } = selectedPackage;
    let expiryAfter = null;
    if (SessionType === 2 && Frequency === 1) {
      expiryAfter = moment(ExpireOn).format("L");
    }

    let recurrenceUnit = { id: 2, text: "Days", value: "Day(s)" };
    if (selectedPackage.Frequency === 4) {
      recurrenceUnit = { id: 4, text: "Months", value: "Month(s)" };
    }
    return (
      <>
        <div className="bz-mt-2rem bz-mb-2_5rem">
          <div className="bz-content-text-row">
            <div className="bz-content-title text-right">Purchased Date</div>
            <span className="bz-colon text-center">:</span>
            <div className="bz-content-body">
              {moment(PurchasedOn).format("MM/DD/YYYY")}
            </div>
          </div>
          <div className="bz-content-text-row show-tax-content-row">
            <div className="bz-content-title text-right">
              <div className="d-flex flex-column justify-content-end">
                <span>Total Cost {CancollectTaxes && <span>*</span>}</span>
                {CancollectTaxes && (
                  <small style={{ fontSize: "10px", color: "#777" }}>
                    (Exclusion of Surcharges)
                  </small>
                )}
              </div>
            </div>
            <span className="bz-colon text-center">:</span>
            <div className="bz-content-body">
              <b>{CurrencyFormat(Cost)}</b>{" "}
              {`(Paid ${CurrencyFormat(AmountPaid)}, Remaining ${CurrencyFormat(
                Cost - AmountPaid
              )})`}
            </div>
          </div>
          <div className="bz-content-text-row">
            <div className="bz-content-title text-right">
              Total Appointments
            </div>
            <span className="bz-colon text-center">:</span>
            <div className="bz-content-body">
              {`${Schedules} (Per Appointment Cost ${CurrencyFormat(
                Cost / Schedules
              )})`}{" "}
              {/* {props?.staffPage && selectedPackage?.Status !==
                PackageInstanceStatusEnum.CANCELLED &&
                SessionType === SessionTypeEnum.PRESET && (
                  <BiEdit
                    color="#008DB9"
                    size={"16px"}
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      handleAppointmentsEdit(selectedPackage,true)
                    }
                  />
                )} */}
            </div>
          </div>
          <div className="bz-content-text-row  show-tax-content-row">
            <div className="bz-content-title text-right">
              <div className="d-flex flex-column justify-content-end">
                <span>
                  Recurring{" "}
                  {checkHasTaxEnable() &&
                    CancollectTaxes &&
                    NextRecurrence !== "N/A" && <span>*</span>}
                </span>
                {checkHasTaxEnable() &&
                  CancollectTaxes &&
                  NextRecurrence !== "N/A" && (
                    <small style={{ fontSize: "10px", color: "#777" }}>
                      {" "}
                      <Tooltip
                        content={(tooltipProps: any) => {
                          return (
                            <TooltipContentPackageService
                              title={tooltipProps.title}
                              data={PredictedTaxes}
                              TotalTaxAmount={PredictedTotalTaxAmount}
                              Name={Name}
                            />
                          );
                        }}
                        tooltipClassName="bz-tooltip-table"
                      >
                        <a
                          title="Surcharges"
                          style={{
                            borderBottom: "1px dotted #0d6efd",
                            cursor: "pointer",
                          }}
                        >
                          {"(Surcharges)"}
                        </a>
                      </Tooltip>
                    </small>
                  )}
              </div>
            </div>
            <span className="bz-colon text-center">:</span>
            <div className="bz-content-body">
              {RecurringAmount &&
              RecurringAmount !== 0 &&
              NextRecurrence &&
              NextRecurrence !== "N/A"
                ? `${CurrencyFormat(RecurringAmount)} (${moment(
                    NextRecurrence
                  ).format("MM/DD/YYYY")})`
                : Cost - AmountPaid > 0
                ? "N/A"
                : "N/A (PIF)"}
              {CancollectTaxes &&
                NextRecurrence &&
                NextRecurrence !== "N/A" && (
                  <small className="pl-1">{`(Exclusion of Surcharges)`}</small>
                )}
            </div>
          </div>
          {RecurringAmount !== 0 && Occurrs !== 0 && RepeatFrequency && (
            <div className="bz-content-text-row">
              <div className="bz-content-title text-right">Frequency</div>
              <span className="bz-colon text-center">:</span>
              <div
                className="bz-content-body"
                style={{ display: "flex", gap: "10px" }}
              >
                {`For Every  ${Occurrs} ${recurrenceUnit?.value}`}
                {props?.staffPage &&
                  ![3, 4, 7].includes(Status) &&
                  SessionType === SessionTypeEnum.PRESET && (
                    <BuzopsTooltip
                      html={
                        <span style={{ cursor: "pointer" }}>
                          <a
                            title="Update Recurrence Options"
                            onClick={() => handleEditRequency()}
                          >
                            <i
                              title="Update Recurrence Options"
                              style={{ fontSize: "16px" }}
                              className="fa fa-edit"
                            ></i>
                          </a>
                        </span>
                      }
                    />
                  )}
              </div>
            </div>
          )}
          {RecurringAmount !== 0 && Occurrs !== 0 && RepeatFrequency && (
            <div className="bz-content-text-row">
              <div className="bz-content-title text-right">
                No. of Installments
              </div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">{NoOfInstallments}</div>
            </div>
          )}
          <div className="bz-content-text-row">
            <div className="bz-content-title text-right">Signed up by</div>
            <span className="bz-colon text-center">:</span>
            <div className="bz-content-body">{SalePerson}</div>
          </div>
          {/* {StatusDescription !=="Completed" ?  */}
          <div className="bz-content-text-row">
            <div className="bz-content-title text-right">Renewal Option</div>
            <span className="bz-colon text-center">:</span>
            <div
              className="bz-content-body"
              style={{ display: "flex", gap: "10px" }}
            >
              {RenewalType === "0"
                ? "None"
                : RenewOptionsNoLastPayment?.find(
                    (i: any) => i?.id === RenewalType
                  )?.text}
              {props?.staffPage &&
                ![3, 4, 7].includes(Status) &&
                SessionType === SessionTypeEnum.PRESET &&
                RenewalStatus !== 3 && (
                  <BuzopsTooltip
                    html={
                      <span style={{ cursor: "pointer" }}>
                        <a
                          title="Update Renewal Options"
                          onClick={() => handleEditRenewal()}
                        >
                          <i
                            title="Update Renewal Options"
                            style={{ fontSize: "16px" }}
                            className="fa fa-edit"
                          ></i>
                        </a>
                      </span>
                    }
                  />
                )}
            </div>
          </div>
        </div>
      </>
    );
  };

  const getCurrentBillingPeriod = (billingPeriods: any) => {
    const now = moment();
    return billingPeriods?.find((period: any) => {
      const start = moment(period.ExpireFrom);
      const end = moment(period.ExpireOn).endOf("day");
      return now.isBetween(start, end, null, "[]");
    });
  };

  const renderSubscriptionDetails = () => {
    if (loading) {
      return <BuzopsLoader type={"list"} />;
    }
    if (selectedPackage === null) {
      return "No Subscriptions Data Found";
    }

    const currentBillingPeriod = getCurrentBillingPeriod(
      packagesToUse?.BillingPeriods
    );
    const schedulesToUse = currentBillingPeriod
      ? currentBillingPeriod.SchedulesToUse
      : selectedPackage?.SchedulesToUse;

    const {
      Name,
      Status,
      PurchasedOn,
      Cost,
      SessionType,
      ExpireOn,
      Schedules,
      RecurringAmount,
      SalePerson,
      Frequency,
      CancollectTaxes,
      CancelledOn,
    } = selectedPackage;
    let expiryAfter = null;
    if (SessionType === 2 && Frequency === 1) {
      expiryAfter = moment(ExpireOn).format("L");
    }

    return (
      <>
        <div className="bz-mt-2rem bz-mb-2_5rem">
          <div className="bz-content-text-row">
            <div className="bz-content-title text-right">Name</div>
            <span className="bz-colon text-center">:</span>
            <div className="bz-content-body">{Name}</div>
          </div>
          <div className="bz-content-text-row">
            <div className="bz-content-title text-right">Purchased On</div>
            <span className="bz-colon text-center">:</span>
            <div className="bz-content-body">
              {PurchasedOn ? moment(PurchasedOn).format("L") || "-" : "-"}
            </div>
          </div>

          <div className="bz-content-text-row  show-tax-content-row">
            <div className="bz-content-title text-right">
              <div className="d-flex flex-column justify-content-end">
                <span>Total Cost {CancollectTaxes && <span>*</span>}</span>
                {CancollectTaxes && (
                  <small style={{ fontSize: "10px", color: "#777" }}>
                    (Exclusion of Surcharges)
                  </small>
                )}
              </div>
            </div>
            <span className="bz-colon text-center">:</span>
            <div className="bz-content-body">{`${CurrencyFormat(Cost)}`}</div>
          </div>
          <div className="bz-content-text-row  show-tax-content-row">
            <div className="bz-content-title text-right">
              <div className="d-flex flex-column justify-content-end">
                <span>Total Appointments</span>
              </div>
            </div>
            <span className="bz-colon text-center">:</span>
            <div className="bz-content-body">
              {SessionType === 2 ? "Unlimited" : `${Schedules}`}
            </div>
          </div>

          <div className="bz-content-text-row show-tax-content-row">
            <div className="bz-content-title text-right">
              <div className="d-flex flex-column justify-content-end">
                <span>
                  Recurring Amount{" "}
                  {checkHasTaxEnable() &&
                    CancollectTaxes &&
                    RecurringAmount > 0 && <span>*</span>}
                </span>
                {checkHasTaxEnable() &&
                  CancollectTaxes &&
                  RecurringAmount > 0 && (
                    <small style={{ fontSize: "10px", color: "#777" }}>
                      <Tooltip
                        content={(tooltipProps: any) => {
                          return (
                            <TooltipContentPackageService
                              title={tooltipProps.title}
                              data={PredictedTaxes}
                              TotalTaxAmount={PredictedTotalTaxAmount}
                            />
                          );
                        }}
                        tooltipClassName="bz-tooltip-table"
                      >
                        <a
                          title="Surcharges"
                          style={{
                            borderBottom: "1px dotted #0d6efd",
                            cursor: "pointer",
                          }}
                        >
                          {"(Surcharges)"}
                        </a>
                      </Tooltip>
                    </small>
                  )}
              </div>
            </div>
            <span className="bz-colon text-center">:</span>
            <div className="bz-content-body">
              {Frequency === PackageFrequencyEnum.ONETIME
                ? `NA`
                : RecurringAmount
                ? `${CurrencyFormat(RecurringAmount)}`
                : CurrencyFormat(0)}
              {CancollectTaxes && RecurringAmount > 0 && (
                <small className="pl-1">{`(Exclusion of Surcharges)`}</small>
              )}
            </div>
          </div>
          <div className="bz-content-text-row">
            <div className="bz-content-title text-right">Repeat Every</div>
            <span className="bz-colon text-center">:</span>
            <div
              className="bz-content-body"
              style={{ textTransform: "capitalize" }}
            >
              {Frequency === PackageFrequencyEnum.ONETIME
                ? "NA being One Time"
                : renderFrequencyItem(selectedPackage, expiryAfter)}
            </div>
          </div>
          {CancelledOn ? (
            <div className="bz-content-text-row">
              <div className="bz-content-title text-right">Cancels On</div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">
                {ExpireOn ? moment(ExpireOn).format("L") : "NA"}
              </div>
            </div>
          ) : (
            selectedPackage.SessionType !== 3 && (
              <div className="bz-content-text-row">
                <div className="bz-content-title text-right">Expires On</div>
                <span className="bz-colon text-center">:</span>
                <div className="bz-content-body">
                  {ExpireOn ? moment(ExpireOn).format("L") : "NA"}
                </div>
              </div>
            )
          )}

          <div className="bz-content-text-row">
            <div className="bz-content-title text-right">Signed up by</div>
            <span className="bz-colon text-center">:</span>
            <div className="bz-content-body">{SalePerson}</div>
          </div>
        </div>
        <div>
          <Grid
            data={billingPeriods}
            className="bzc-grid bzc-pkg-overview-grid"
            style={{ maxHeight: "212px", overflow: "auto" }}
          >
            <Column field="EffectiveFrom" title="Start Date" cell={DateCell} />
            <Column
              field="RenewalOn"
              title="Next Renewal Date"
              cell={DateCell}
            />
            {SessionType === 1 &&
              Frequency !== PackageFrequencyEnum.ONETIME && (
                <Column
                  field="RollOverSchedules"
                  title="Rollover Appts"
                  cell={(propData) =>
                    RollOverRevertedAppts(propData, selectedPackage)
                  }
                />
              )}
            <Column
              field="RepeatEvery"
              title="Appointments"
              cell={(propsData) => {
                return (
                  <td>
                    {propsData?.dataItem?.UsedSchedules > 0 ? (
                      <span className="d-flex">
                        <span
                          className="btn-link"
                          onClick={() => {
                            setShowUtilizations((prev) => !prev);
                            handleChange(propsData?.dataItem);
                          }}
                          data-for={"appointment-link"}
                          data-tip={"Used Appointments"}
                        >
                          {propsData?.dataItem?.UsedSchedules}
                        </span>{" "}
                        <span className="px-1">{` of `}</span>{" "}
                        <span className="d-flex">
                          {SessionType === 2 ? (
                            "Unlimited"
                          ) : (
                            <span>
                              {propsData?.dataItem?.RolledOverSchedules > 0 ? (
                                <Tooltip
                                  content={(tooltipProps: any) => {
                                    return (
                                      <TooltipContentAppointments
                                        title={tooltipProps.title}
                                        data={propsData?.dataItem}
                                      />
                                    );
                                  }}
                                  tooltipClassName="bz-tooltip-table"
                                  position="top"
                                  anchorElement="pointer"
                                >
                                  <a
                                    title="Schedules"
                                    style={{
                                      borderBottom: "1px dotted #0d6efd",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {propsData?.dataItem?.Schedules}
                                  </a>
                                </Tooltip>
                              ) : (
                                <span>{propsData?.dataItem?.Schedules}</span>
                              )}
                            </span>
                          )}
                        </span>
                        <ReactTooltip
                          id={`appointment-link`}
                          place="top"
                          type="dark"
                          effect="float"
                          html={true}
                        />
                        {props?.staffPage &&
                          propsData?.dataItem?.Status === 1 && selectedPackage?.Status !==
                          PackageInstanceStatusEnum.CANCELLED &&
                          SessionType === SessionTypeEnum.LIMITED && (
                            <BiEdit
                              color="#008DB9"
                              size={"16px"}
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                handleAppointmentsEdit(propsData.dataItem)
                              }
                            />
                          )}
                      </span>
                    ) : (
                      <span className="d-flex">
                        <span
                          data-for={"appointment-link1"}
                          data-tip={"Used Appointments"}
                        >
                          {propsData?.dataItem?.UsedSchedules}
                        </span>{" "}
                        <span className="px-1">{` of `}</span>
                        <span>
                          {SessionType === 2
                            ? "Unlimited"
                            : <span>
                            {propsData?.dataItem?.RolledOverSchedules > 0 ? (
                              <Tooltip
                                content={(tooltipProps: any) => {
                                  return (
                                    <TooltipContentAppointments
                                      title={tooltipProps.title}
                                      data={propsData?.dataItem}
                                    />
                                  );
                                }}
                                tooltipClassName="bz-tooltip-table"
                              >
                                <a
                                  title="Schedules"
                                  style={{
                                    borderBottom: "1px dotted #0d6efd",
                                    cursor: "pointer",
                                  }}
                                >
                                  {propsData?.dataItem?.Schedules}
                                </a>
                              </Tooltip>
                            ) : (
                              <span>{propsData?.dataItem?.Schedules}</span>
                            )}
                          </span>}
                        </span>
                        <ReactTooltip
                          id={`appointment-link1`}
                          place="top"
                          type="dark"
                          effect="float"
                          html={true}
                        />
                        {props?.staffPage &&
                          propsData?.dataItem?.Status === 1 && selectedPackage?.Status !==
                          PackageInstanceStatusEnum.CANCELLED &&
                          SessionType !== SessionTypeEnum.UNLIMITED && (
                            <BiEdit
                              color="#008DB9"
                              size={"16px"}
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                handleAppointmentsEdit(propsData.dataItem)
                              }
                            />
                          )}
                      </span>
                    )}
                  </td>
                );
              }}
            />
            <Column
              field="Status"
              title="Status"
              cell={(props) => {
                const statusItem = PackageInstanceClassMappingStatus?.filter(
                  (i: any) => i?.id === props?.dataItem?.Status
                )?.[0]?.text;
                return <td>{statusItem}
                    {/* {SessionType ===SessionTypeEnum.LIMITED && selectedPackage?.Schedules !== props?.dataItem?.Schedules &&  <BiHistory  
                  color="#008DB9"
                  size={"16px"}
                  style={{ cursor: "pointer", marginLeft:'2px' }}
                  onClick={() =>
                    handleAppointmentshistory(props.dataItem)
                  }
                />} */}
                
                </td>;
              }}
            />
          </Grid>
        </div>
      </>
    );
  };

  const renderUtilizedClasses = () => {
    return (
      <div className="d-flex flex-column">
        <h5 className="bz_fs_1_5">
          {" "}
          <button
            className="btn btn-primary bz-back-btn btn-sm"
            onClick={() => setShowUtilizations(false)}
          >
            <i className="fas fa-arrow-left"></i>
          </button>
          &nbsp;{"Package Utilization"}
        </h5>
        <PackageUtilization
          {...props}
          selectedPeriodData={selectedPeriod}
          loading={utilizationLoading}
          billingPeriods={billingPeriods}
          frequency={selectedPackage?.Frequency}
        />
      </div>
    );
  };
  const renderGroupClassesDialog = () => {
    const memberId =
      props?.userData?.PrimaryMemberId ||
      props?.userData?.ParentId ||
      props?.userData?.UserMemberId;
    return (
      <>
        <div className="d-flex">
          <button
            type="button"
            className="btn btn-primary bz-back-btn btn-sm mr-2"
            onClick={() => toggleGroupDialog()}
          >
            <i className="fas fa-arrow-left"></i>
          </button>
          <h5>{`${selectedPackage?.Name}`}</h5>
        </div>
        <PurchaseClassList
          page={"inhouse"}
          userMemberId={memberId}
          handleWindow={() => toggleGroupDialog()}
          selectedPackage={selectedPackage}
          staffPage={props?.staffPage}
        />
      </>
    );
  };
  const checkBillingCycleValidation = () => {
    const records = packagesToUse?.BillingPeriods?.filter(
      (i: any) => i?.SchedulesToUse > 0
    );
    if (records?.length > 0) {
      return true;
    }
    return false;
  };
  const toggleGroupDialog = () => {
    setGroupDialog(!groupDialog);
  };
  const handleTogglePurchasePage = (e: any, val: any) => {
    e.stopPropagation();
    if (!props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage("", "error", true, "client");
      return;
    } else if (props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage("", "error", false, "staff");
      return;
    }

    if (packagesToUse?.Type === 1) {
      toggleGroupDialog();
    } else {
      if (props?.staffPage) {
        const urlRedirection = `/member/${props?.uuid}/${props?.userData?.UserMemberId}/scheduleappointment`;
        history.push(urlRedirection, {
          selectedPackageInstanceId: val?.PackageInstanceId,
          staffPage: true,
          userData: props?.userData,
          Complimentary: false,
          enrollPackage: true,
          redirectedFrom: `package-${val?.PackageInstanceId}`,
          Type: val?.Type
        });
      } else {
        const obj = {
          userData: props?.userData,
          staffPage: false,
          selectedPackageInstanceId: val?.PackageInstanceId,
          Complimentary: false,
          enrollPackage: true,
          redirectedFrom: `package-${val?.PackageInstanceId}`,
          Type: val?.Type

        };
        if (redirectionObj?.firstPathValue !== "") {
          history.push(
            `/${redirectionObj?.firstPathValue}/scheduleappointment`,
            obj
          );
        } else {
          history.push(`/scheduleappointment`, obj);
        }
      }
    }
  };

  let mininmumRolloverAppts = 0;
  if (selectedBillingPeriod) {
    const totalUsedSchedules =
      selectedBillingPeriod?.UsedRollOverSchedules +
      selectedBillingPeriod?.UsedSchedules;
    const check = totalUsedSchedules - selectedBillingPeriod?.Schedules;
    if (check > 0) {
      mininmumRolloverAppts = check;
    }
  }
  const statusDescription = PackageInstanceStatus?.filter(
    (i: any) =>
      i?.id ===
      (selectedPackage?.Status === 1 && selectedPackage?.CancelledOn
        ? 8
        : selectedPackage?.Status)
  )?.[0]?.text;
  const filteredSessionSignOffItems = [
    {
      text: "Paid",
      key: "Paid",
      disabled: !SessionSignOffPermission(),
    },
    {
      text: "Complimentary",
      key: "Complimentary",
      disabled: !signOffComplimentaryPermission(),
    },
  ];
  const filteredScheduleItems = [
    {
      text: "Paid",
      key: "Paid",
    },
    {
      text: "Complimentary",
      key: "Complimentary",
      disabled: !signOffComplimentaryPermission(),
    },
  ];

  async function fetchClientData(userMemberId: any) {
    const clientservice = new TenantService();
    let result: any = await clientservice.getClient(userMemberId);
    if (result) {
      result = {
        Email: result?.User?.Email,
        FirstName: result?.User?.FirstName,
        LastName: result?.User?.LastName,
        PrimaryMemberId: result?.UserMemberId,
        UserId: result?.UserId,
        UserMemberId: result?.UserMemberId,
        MemberNumber: result?.MemberNumber,
        Status: result?.Status,
      };
    }
    return result;
  }

  const handleStaffSchedule = (e: any, data: any) => {
    let complimentary = false;
    let quickCheckout = false;
    if (e.item.key === "Complimentary") {
      complimentary = true;
    }
    if (data === "QuickCheckout") {
      quickCheckout = true;
    }
    handleRedirectionForStaff(selectedPackage, complimentary, quickCheckout);
  };

  const handleRedirectionForStaff = async (
    dataItem: any,
    IsComplementary = false,
    IsQuickCheckOut = false
  ) => {
    if (!CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage("", "error", false, "staff");
      return;
    }

    if (
      IsQuickCheckOut &&
      dataItem?.SessionType === SessionTypeEnum.LIMITED &&
      !checkBillingPeriodsforSessionSignoff()
    ) {
      setIsDialogVisible(true);
      return;
    } else {
      setIsDialogVisible(false);
    }

    const userDetails = await fetchClientData(dataItem?.UserMemberId);
    if (props?.staffPage) {
      history.push(
        `/member/${props?.uuid}/${props?.userData?.UserMemberId}/scheduleappointment`,
        {
          selectedPackageInstanceId: dataItem?.PackageInstanceId,
          staffPage: true,
          userData: userDetails ? userDetails : props?.userData,
          uuid: props?.uuid,
          Complimentary: IsComplementary,
          quickcheckout: IsQuickCheckOut,
          redirectedFrom: `package-${dataItem?.PackageInstanceId}`,
          showPreset: showPreset,
          // showOnlySignoff: dataItem?.Type === TypeEnum.Class ? true : undefined,
          Type: dataItem?.Type

        }
      );
    } else {
      const obj = {
        userData: props?.userData,
        staffPage: false,
        selectedPackageInstanceId: dataItem?.PackageInstanceId,
        Complimentary: false,
        quickcheckout: false,
        redirectedFrom: `package-${dataItem?.PackageInstanceId}`,
        showPreset: showPreset,
        Type: dataItem?.Type

      };
      if (redirectionObj?.firstPathValue !== "") {
        history.push(
          `/${redirectionObj?.firstPathValue}/scheduleappointment`,
          obj
        );
      } else {
        history.push(`/scheduleappointment`, obj);
      }
    }
  };
  const handleSynchronizeEvents = async () => {
    const req = {
      PackageInstanceId: props?.selectedPackageId,
    };

    setLoading(true);
    await axiosRequest.post("associate/SynchornizeEvents", req, {
      successCallBack: (response: any) => {
        setLoading(false);
        const successMsg = "Calendar sync successfull";
        props?.handleNotificationMessage(successMsg, "success");
      },
      errorCallBack: (response: any) => {
        setLoading(false);
        const errorMsg =
          response?.response?.data?.Messages?.[0] ||
          response?.data?.Messages?.[0] ||
          "unable to process calendar sync";
        props?.handleNotificationMessage(errorMsg, "error");
      },
    });
  };
  const toggleACDialog = () => {
    if (renewalDetailsCurrent) resetRenewalForm();
    setVisibleAC(!visibleAC);
  };

  const resetRenewalForm = () => {
    setRenewalFormDetails(renewalDetailsCurrent);
    setPayInFull(renewalDetailsCurrent.PayInFull);
    setSelectedRenewOption(
      renewOptions.find((e) => e.id === renewalDetailsCurrent?.RenewalType)
    );
    setSelectedPaymentProfile(
      paymentProfile.find(
        (r: any) =>
          r.PaymentGatewayPaymentProfileID ===
          renewalDetailsCurrent.RenewalPaymentGatewayPaymentProfileId
      )
    );
    setFormModified(false);
    //getSelectedService(props.selectedService);
  };

  const showErrorMessage = (key: any, mainKey: any) => {
    if (!renewalFormDetails?.[key] || renewalFormDetails?.[key] <= 0) {
      return <Error>{`Please provide atleast one ${mainKey}`}</Error>;
    }
    return <></>;
  };

  const showRecurrenceDetails = () => {
    const { PredictedRenewalDateString, Cost } = selectedPackage;
    let data = [];
    const every = renewalFormDetails?.RenewalOcurrsEvery;
    const unit = renewalFormDetails?.RenewalRepeatFrequency;
    for (
      let index = 0;
      index < renewalFormDetails?.RenewalNoOfInstallments;
      index++
    ) {
      const item = {
        DisplayDate:
          unit === 2
            ? moment(PredictedRenewalDateString)
                .add("days", every * index)
                .format("L")
            : moment(PredictedRenewalDateString)
                .add("months", every * index)
                .format("L"),

        InstallmentAmount:
          (Cost - renewalFormDetails?.RenewalDownPayment) /
          renewalFormDetails?.RenewalNoOfInstallments,
      };
      data.push(item);
    }

    if (data?.length === 0) {
      return <>{"No Installments found"}</>;
    }
    return (
      <div
        className={"installemt-grid bz-installment-details"}
        style={{ width: "268px", margin: "0px" }}
      >
        <Grid data={data} style={{ maxHeight: "300px", border: 0 }}>
          <Column field="DisplayDate" title={"Dates"} cell={customDateCell} />
          <Column
            field="InstallmentAmount"
            title={"Amount"}
            cell={InstallmentAmtCell}
          />
        </Grid>
      </div>
    );
  };

  const customDateCell = (props: any) => {
    return <td className={"capitalize "}>{props.dataItem.DisplayDate}</td>;
  };
  const InstallmentAmtCell = (props: any) => {
    return (
      <td className={"capitalize"}>
        {CurrencyFormat(props.dataItem.InstallmentAmount.toFixed(2))}
      </td>
    );
  };

  const saveRenewalOtions = async () => {
    const {
      PredictedRenewalDateString = null,
      PackageCost = 0,
      ...req
    } = { ...renewalFormDetails };
    if (
      !req.PayInFull &&
      req.RenewalType !== 0 &&
      (!renewalFormDetails?.RenewalOcurrsEvery ||
        renewalFormDetails?.RenewalOcurrsEvery <= 0 ||
        !renewalFormDetails?.RenewalNoOfInstallments ||
        renewalFormDetails?.RenewalNoOfInstallments <= 0)
    ) {
      return;
    }
    if (
      (!req?.RenewalPaymentGatewayPaymentProfileId ||
        req?.RenewalPaymentGatewayPaymentProfileId === 0) &&
      req.RenewalType !== 0
    ) {
      return;
    }
    if (
      selectedPaymentProfile?.CardTypeId === 8 &&
      req.RenewalDownPayment > selectedPaymentProfile?.Credit
    ) {
      const errorMsg = CreditsErrorMessage;
      props?.handleNotificationMessage(errorMsg, "error");
      return;
    }
    if (
      req.PayInFull ||
      req.RenewalType === 0 ||
      req.RenewalDownPayment === selectedPackage?.Cost
    ) {
      req.RecurringAmount = 0;
      req.RenewalNoOfInstallments = 0;
      req.RenewalOcurrsEvery = 0;
      req.RenewalRepeatFrequency = 0;
    } else if (req.RenewalRepeatFrequency === 0) {
      req.RenewalRepeatFrequency = 4;
    }
    setUpdateRecurrenceSubmitLoading(true);
    await axiosRequest.post("PackageInstance/UpdateRenewal", req, {
      successCallBack: (response: any) => {
        const successMsg = "Renewal Updated Successfully";
        props?.handleNotificationMessage(successMsg, "success");
        toggleACDialog();
        fetchPackageSummary();
        setUpdateRecurrenceSubmitLoading(false);
      },
      errorCallBack: (response: any) => {
        setUpdateRecurrenceSubmitLoading(false);
        const errorMsg =
          response?.response?.data?.Messages?.[0] ||
          response?.data?.Messages?.[0] ||
          "Error occured while adding credits to wallet";
        props?.handleNotificationMessage(errorMsg, "error");
      },
    });
  };

  const onCardOrBankAdd = (e: any) => {
    setShowPaymentDialog(true);
    setAddNewItem(e.item.key);
  };

  const handleClosePaymethodDialog = async () => {
    setShowPaymentDialog(!showPaymentDialog);
  };

  const handleSuccessClosePaymethodDialog = async (
    msg = null,
    modeVal = null
  ) => {
    if (msg) {
      props?.handleNotificationMessage(msg, "success");
    }
    setShowPaymentDialog(!showPaymentDialog);
    await fetchPaymentProfiles(props?.userData?.MainUserId, modeVal);
  };
  const PaymethodDialog = () => {
    return (
      <>
        <Dialog
          className="bz-addCard-dialog"
          title={`${
            addNewItem !== "addOther"
              ? checkIfUserCanAddBankAccount()
                ? "Add Bank or Card"
                : "Add Card"
              : "Add Payment Mode"
          }`}
          onClose={() => handleClosePaymethodDialog()}
          style={{ zIndex: 9999 }}
        >
          <PaymentModalCreation
            staffPage={true}
            addNewItem={addNewItem}
            handleClosePaymethodDialog={handleClosePaymethodDialog}
            handleSuccessClosePaymethodDialog={
              handleSuccessClosePaymethodDialog
            }
            UserMemberId={props?.userData?.MainUserId}
          />
        </Dialog>
      </>
    );
  };

  const handleUpdateRenewal = (val: any, key: string, touched = false) => {
    let CanRenew = false;
    if (key === "RenewalType") {
      CanRenew = val ? true : false;
    } else {
      CanRenew = renewalFormDetails.RenewalType ? true : false;
    }
    let value = val;
    if (key === "RenewalDownPayment" || key === "RenewalNoOfInstallments") {
      if (val >= 0) {
        value = val;
      } else {
        value = 0;
      }
      if (key === "RenewalNoOfInstallments" && val > 999) {
        value = 999;
      }
      let recurringAmt = renewalFormDetails?.RecurringAmount;
      if (key === "RenewalDownPayment") {
        recurringAmt =
          (selectedPackage?.Cost - value) /
          renewalFormDetails?.RenewalNoOfInstallments;
      } else {
        if (value > 0) {
          recurringAmt =
            (selectedPackage?.Cost - renewalFormDetails?.RenewalDownPayment) /
            value;
        }
      }
      setRenewalFormDetails({
        ...renewalFormDetails,
        [key]: value,
        RecurringAmount: recurringAmt,
        CanRenew,
      });
    } else if (key === "PayInFull") {
      setRenewalFormDetails({
        ...renewalFormDetails,
        [key]: value,
        RenewalDownPayment: value
          ? selectedPackage?.Cost
          : selectedPackage?.RenewalDownPayment,
        RecurringAmount: value
          ? 0
          : (selectedPackage?.Cost - selectedPackage?.RenewalDownPayment) /
            renewalFormDetails?.RenewalNoOfInstallments,
      });
    } else {
      setRenewalFormDetails({
        ...renewalFormDetails,
        [key]: value,
        CanRenew,
      });
    }
    if (touched) {
      setFormModified(true);
    }
  };

  const renderUpdateRenewal = () => {
    const { Cost, RatePerSchedule, RenewalDownPayment } = selectedPackage;
    return (
      <>
        <div className="bz-mt-2rem bz-mb-2_5rem">
          <div className="bz-content-text-row" style={{ alignItems: "start" }}>
            <div className="bz-content-title text-right">Cost</div>
            <span className="bz-colon text-center">:</span>
            <div className="bz-content-body">{CurrencyFormat(Cost)}</div>
          </div>
          <div className="bz-content-text-row" style={{ alignItems: "start" }}>
            <div className="bz-content-title text-right">Rate per session</div>
            <span className="bz-colon text-center">:</span>
            <div className="bz-content-body">
              {CurrencyFormat(RatePerSchedule)}
            </div>
          </div>
          <div className="bz-content-text-row" style={{ alignItems: "start" }}>
            <div className="bz-content-title text-right">Down Payment</div>
            <span className="bz-colon text-center">:</span>
            <div className="bz-content-body">
              {CurrencyFormat(RenewalDownPayment)}
            </div>
          </div>
          <div className="bz-content-text-row" style={{ alignItems: "start" }}>
            <div className="bz-content-title text-right">
              <Checkbox
                label={"Renew"}
                checked={renewChecked}
                defaultChecked={true}
                onChange={(e: any) => {
                  setRenewChecked(!renewChecked);
                  if (renewChecked) {
                    setSelectedRenewOption(
                      renewOptions.find((e) => e.id === 0)
                    );
                    handleUpdateRenewal(0, "RenewalType", true);
                  } else {
                    setSelectedRenewOption(
                      renewOptions.find(
                        (e) => e.id === renewalDetailsCurrent.RenewalType
                      )
                    );
                    handleUpdateRenewal(
                      renewalDetailsCurrent.RenewalType,
                      "RenewalType",
                      true
                    );
                  }
                }}
                name="renew"
              />
            </div>
            <span className="bz-colon text-center">:</span>
            <div className="bz-content-body">
              <div style={{ display: "flex" }}>
                <DropDownList
                  id={"renewOptionsDD"}
                  data={renewOptions}
                  onChange={(e) => {
                    setSelectedRenewOption(e.value);
                    handleUpdateRenewal(e.value.id, "RenewalType");
                    setFormModified(true);
                  }}
                  textField="text"
                  value={selectedRenewOption}
                  defaultValue={renewOptions.find(
                    (e) => e.id === renewalDetailsCurrent.RenewalType
                  )}
                  dataItemKey="id"
                  style={{ width: "100%" }}
                  disabled={!renewChecked}
                />
              </div>
            </div>
          </div>
          {renewalFormDetails.RenewalType !== 0 && renewChecked && (
            <div>
              <div
                className="bz-content-text-row"
                style={{ alignItems: "start" }}
              >
                <div className="bz-content-title text-right">Pay In Full</div>
                <span className="bz-colon text-center">:</span>
                <div className="bz-content-body">
                  <Checkbox
                    label={""}
                    checked={PayInFull}
                    defaultChecked={false}
                    onChange={(e: any) => {
                      setPayInFull(!PayInFull);
                      handleUpdateRenewal(!PayInFull, "PayInFull", true);
                    }}
                    name="PayInFull"
                    // disabled={
                    //   renewalDetailsCurrent.PackageCost ===
                    //   renewalDetailsCurrent.RenewalDownPayment
                    // }
                  />
                </div>
              </div>
              <div
                className="bz-content-text-row"
                style={{ alignItems: "start" }}
              >
                <div className="bz-content-title text-right">
                  Payment Method
                </div>
                <span className="bz-colon text-center">:</span>
                <div className="bz-content-body">
                  <DropDownList
                    id={"selectedPaymentProfile"}
                    name={"selectedPaymentProfile"}
                    data={paymentProfile?.filter(
                      (i: any) => i?.CardTypeId >= 0
                    )}
                    listNoDataRender={() => "No Payment Methods"}
                    textField="MaskedCCNumber"
                    dataItemKey="PaymentGatewayPaymentProfileID"
                    valueRender={(
                      el: React.ReactElement<HTMLSpanElement>,
                      value: any
                    ) => PaymentValueRender(el, value, selectedPaymentProfile)}
                    itemRender={PaymentItemRender}
                    onChange={(e) => {
                      setSelectedPaymentProfile(e.value);
                      handleUpdateRenewal(
                        e.value?.PaymentGatewayPaymentProfileID,
                        "RenewalPaymentGatewayPaymentProfileId",
                        true
                      );
                    }}
                    style={{ width: "100%" }}
                    // defaultItem={paymentProfile.find((r:any ) => r.PaymentGatewayPaymentProfileID === renewalDetailsCurrent.RenewalPaymentGatewayPaymentProfileId)}
                    // defaultValue={paymentProfile.find(
                    //   (r: any) =>
                    //     r.PaymentGatewayPaymentProfileID ===
                    //     renewalDetailsCurrent.RenewalPaymentGatewayPaymentProfileId
                    // )}
                  />
                  {(!renewalFormDetails?.RenewalPaymentGatewayPaymentProfileId ||
                    renewalFormDetails?.RenewalPaymentGatewayPaymentProfileId ===
                      0) && <Error>{"Please select payment profile"}</Error>}
                </div>
              </div>
              {!PayInFull ? (
                <>
                  <div
                    className="bz-content-text-row"
                    style={{ alignItems: "start" }}
                  >
                    <div className="bz-content-title text-right">
                      Down Payment
                    </div>
                    <span className="bz-colon text-center">:</span>
                    <div className="bz-content-body">
                      <div>
                        <NumericTextBox
                          // className={"form-control col-md-6"}
                          width={"100%"}
                          value={renewalFormDetails?.RenewalDownPayment}
                          format="c2"
                          min={0}
                          onChange={(e) => {
                            handleUpdateRenewal(
                              e.target.value,
                              "RenewalDownPayment",
                              true
                            );
                          }}
                        />
                        {(renewalFormDetails?.RenewalDownPayment ===
                          undefined ||
                          renewalFormDetails?.RenewalDownPayment === "" ||
                          renewalFormDetails?.RenewalDownPayment < 0) && (
                          <Error>{"Please provide Renewal Down Payment"}</Error>
                        )}
                      </div>
                    </div>
                  </div>
                  {renewalFormDetails?.RenewalDownPayment === Cost ? (
                    <>
                      <div
                        className="bz-content-text-row"
                        style={{ alignItems: "start" }}
                      >
                        <div className="bz-content-title text-right">
                          Grand Total
                        </div>
                        <span className="bz-colon text-center">:</span>
                        <div className="bz-content-body">
                          {CurrencyFormat(
                            renewalFormDetails?.RenewalDownPayment
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="bz-content-text-row"
                        style={{ alignItems: "start" }}
                      >
                        <div className="bz-content-title text-right">
                          Frequency
                        </div>
                        <span className="bz-colon text-center">:</span>
                        <div className="bz-content-body">
                          <div style={{ display: "flex" }}>
                            <DropDownList
                              data={recurrenceUnits}
                              textField="text"
                              dataItemKey="id"
                              name="recurrenceUnit"
                              value={recurrenceUnits.find(
                                (r) =>
                                  r.id ===
                                  renewalFormDetails?.RenewalRepeatFrequency
                              )}
                              defaultValue={recurrenceUnits.find(
                                (r) => r.id === 4
                              )}
                              style={{ width: "100%" }}
                              onChange={(e) =>
                                handleUpdateRenewal(
                                  e.value?.id,
                                  "RenewalRepeatFrequency",
                                  true
                                )
                              }
                            />
                          </div>
                          <Hint>The billing frequency for the Service.</Hint>
                        </div>
                      </div>
                      <div
                        className="bz-content-text-row"
                        style={{ alignItems: "start" }}
                      >
                        <div className="bz-content-title text-right">
                          Recurring Every
                        </div>
                        <span className="bz-colon text-center">:</span>
                        <div className="bz-content-body">
                          <NumericTextBox
                            // className={"form-control col-md-6"}
                            width={"100%"}
                            value={renewalFormDetails?.RenewalOcurrsEvery}
                            min={1}
                            onChange={(e) => {
                              handleUpdateRenewal(
                                e.target.value,
                                "RenewalOcurrsEvery",
                                true
                              );
                            }}
                          />
                          {showErrorMessage(
                            "RenewalOcurrsEvery",
                            "Recurring Every"
                          )}
                          <Hint>
                            Select how frequently the client would want to
                            repeat the Service payment based on frequency.
                          </Hint>
                        </div>
                      </div>
                      <div
                        className="bz-content-text-row"
                        style={{ alignItems: "start" }}
                      >
                        <div className="bz-content-title text-right">
                          Number Of Installments
                        </div>
                        <span className="bz-colon text-center">:</span>
                        <div className="bz-content-body">
                          <NumericTextBox
                            // className={"form-control col-md-6"}
                            width={"100%"}
                            value={renewalFormDetails?.RenewalNoOfInstallments}
                            min={1}
                            max={999}
                            onChange={(e) => {
                              handleUpdateRenewal(
                                e.target.value,
                                "RenewalNoOfInstallments",
                                true
                              );
                            }}
                          />
                          {showErrorMessage(
                            "RenewalNoOfInstallments",
                            "Installment"
                          )}
                          <Hint>
                            Select the number of times the client would want to
                            repeat the Service payment based on frequency.
                          </Hint>
                        </div>
                      </div>
                      {renewalFormDetails?.RenewalNoOfInstallments > 0 && (
                        <div
                          className="bz-content-text-row"
                          style={{ alignItems: "start" }}
                        >
                          <div className="bz-content-title text-right">
                            Installment Details
                          </div>
                          <span className="bz-colon text-center">:</span>
                          <div className="bz-content-body">
                            {showRecurrenceDetails()}
                          </div>
                        </div>
                      )}
                      {renewalFormDetails?.RenewalNoOfInstallments > 0 && (
                        <div
                          className="bz-content-text-row"
                          style={{ alignItems: "start" }}
                        >
                          <div className="bz-content-title text-right">
                            Recurring Cost
                          </div>
                          <span className="bz-colon text-center">:</span>
                          <div className="bz-content-body">
                            {CurrencyFormat(
                              renewalFormDetails?.RecurringAmount
                            )}
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  <div
                    className="bz-content-text-row"
                    style={{ alignItems: "start" }}
                  >
                    <div className="bz-content-title text-right">
                      Grand Total
                    </div>
                    <span className="bz-colon text-center">:</span>
                    <div className="bz-content-body">
                      {CurrencyFormat(renewalFormDetails?.RenewalDownPayment)}
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </>
    );
  };
  if (groupDialog) {
    return renderGroupClassesDialog();
  }
  if (showUtilizations) {
    return renderUtilizedClasses();
  }

  return (
    <>
      <div className="bz-header-row mb-3">
        <h5 className="mb-0 bz_fs_1_5">Packages Overview</h5>
        <div className={"bz-schedule-link-btn2 packageoverveiw"}>
          {(!selectedPackage?.ExpireOn ||
            moment().format("YYYY-MM-DD") <
              moment(selectedPackage?.ExpireOn).format("YYYY-MM-DD")) &&
            (selectedPackage?.Status === PackageInstanceStatusEnum.ACTIVE ||
              selectedPackage?.Status ===
                PackageInstanceStatusEnum.FUTURENOTPAID ||
              selectedPackage?.Status ===
                PackageInstanceStatusEnum.FUTUREPAID ||
              selectedPackage?.Status ===
                PackageInstanceStatusEnum.CANCELLED) && (
              <>
                {props?.staffPage &&
                  GetLocalStore("tenantUserSession").UserClubId === 1 && (
                    <Button
                      style={{
                        marginRight: "10px",
                      }}
                      primary={false}
                      onClick={handleSynchronizeEvents}
                    >
                      <i className={"fas fa-calendar-alt"}></i>
                      {"Synchronize Third party calendar"}
                    </Button>
                  )}
                {(selectedPackage?.SessionType === 1 ||
                    selectedPackage?.SessionType === 2) &&
                  props?.staffPage && (
                    <Button
                      style={{ marginLeft: "0px" }}
                      primary={false}
                      disabled={selectedPackage?.Status ===
                        PackageInstanceStatusEnum.FUTURENOTPAID}
                      onClick={() =>
                        handleRedirectionForStaff(selectedPackage, false, true)
                      }
                    >
                      <i className={"fas fa-map-marker-alt"}></i>
                      {"Session Sign Off"}
                    </Button>
                  )}

                {(selectedPackage?.Type === TypeEnum.Class ||
                  (selectedPackage?.SessionType === 1 &&
                    checkBillingCycleValidation()) ||
                  selectedPackage?.SessionType === 2) && (
                  <Button
                    style={{ marginLeft: "10px" }}
                    primary={false}
                    onClick={(e) =>
                      handleTogglePurchasePage(e, selectedPackage)
                    }
                  >
                    <i
                      className={
                        selectedPackage?.Type === TypeEnum.Class
                          ? "fas fa-user-plus"
                          : "fas fa-calendar-alt"
                      }
                    ></i>
                    {selectedPackage?.Type === TypeEnum.Class
                      ? "Enroll"
                      : "Schedule"}
                  </Button>
                )}
              </>
            )}
          {selectedPackage?.SessionType === SessionTypeEnum.PRESET &&
            selectedPackage?.Status !== 7 &&
            (selectedPackage?.Status !== 4 ||
              (selectedPackage.UnusedPaidSchedules > 0 &&
                selectedPackage?.CanScheduleAppointment)) && (
              <div className="bz-dropdown-group">
                {props?.staffPage &&
                (SessionSignOffPermission() ||
                  signOffComplimentaryPermission()) ? (
                  <>
                    <DropDownButton
                      iconClass="fas fa-map-marker-alt"
                      className="btn btn-link add-new-btn bz-schedule-btn mt-0"
                      look="flat"
                      text={"Session Sign Off"}
                      onItemClick={(e: any) =>
                        handleStaffSchedule(e, "QuickCheckout")
                      }
                      items={
                        selectedPackage.UnusedPaidSchedules > 0
                          ? filteredSessionSignOffItems
                          : filteredSessionSignOffItems?.filter(
                              (i: any) => i?.key === "Complimentary"
                            )
                      }
                    />
                    <DropDownButton
                      iconClass="fas fa-calendar-alt"
                      look="flat"
                      text={"Schedule"}
                      onItemClick={(e: any) =>
                        handleStaffSchedule(e, "ScheduleAppointment")
                      }
                      items={
                        selectedPackage.Status !== 4 &&
                        selectedPackage?.Schedules -
                          selectedPackage?.SchedulesUsed >
                          0
                          ? filteredScheduleItems
                          : (selectedPackage?.Status === 1 &&
                              selectedPackage.UnusedPaidSchedules -
                                selectedPackage.Scheduled >
                                0) ||
                            (selectedPackage.UnusedPaidSchedules -
                              selectedPackage.Scheduled >
                              0 &&
                              selectedPackage?.CanScheduleAppointment)
                          ? filteredScheduleItems
                          : filteredScheduleItems?.filter(
                              (i: any) => i?.key === "Complimentary"
                            )
                      }
                      className="btn btn-link add-new-btn bz-schedule-btn"
                    />
                  </>
                ) : (
                  packagesToUse?.SchedulesToUse > 0 && (
                    <Button
                      primary
                      onClick={() =>
                        handleRedirectionForStaff(selectedPackage, false)
                      }
                    >
                      {" "}
                      <i className="fas fa-calendar-alt"></i> Schedule
                    </Button>
                  )
                )}
              </div>
            )}
        </div>
      </div>
      <div className="bz-subsection-row subscription-blk bz-pakage-overview">
        <div className="left-section">
          <h5 className="mb-3 pt-3 bz_fs_1_5">
            Subscription Info{" "}
            <span className={`badge badge-status-${statusDescription}`}>
              {statusDescription}
            </span>
          </h5>

          {selectedPackage?.SessionType !== 3
            ? renderSubscriptionDetails()
            : renderPresetSubscriptionDetails()}
          <>{renderRedeemDetails()}</>
          {selectedPackage?.NextRecurrence &&
            props?.staffPage &&
            selectedPackage?.SessionType !== 3 && (
              <div className="applied-coupons">
                <h5 className="mb-3 pt-3 bz_fs_1_5">Applied Coupons</h5>
                <PackageCoupons
                  loading={loading}
                  status={selectedPackage?.Status}
                  showCoupon={showCoupon}
                  selectedPackage={selectedPackage}
                  setShowCoupon={setShowCoupon}
                  CouponsToAdd={CouponsToAdd}
                  handleCoupon={handleCoupon}
                  couponValue={couponValue}
                  CouponfilterChange={CouponfilterChange}
                  couponsList={couponsList}
                  toggleAddCouponDialog={toggleAddCouponDialog}
                  handleRemoveCouponItem={handleRemoveCouponItem}
                  couponsClass="bz-coupon-pkg"
                />
              </div>
            )}
          <hr />
          {selectedPackage?.SessionType === SessionTypeEnum.PRESET &&
            selectedPackage?.PackageInstanceId && (
              <AppointmentTabs
                {...props}
                userData={{
                  ...props?.userData,
                  EntityUserMemberId: selectedPackage?.UserMemberId,
                }}
                key={selectedPackage?.PackageInstanceId}
                serviceId={selectedPackage?.PackageInstanceId}
                isPackage={true}
                presetOverview={true}
              />
            )}
        </div>

        <div className="right-section mt-3">
          {selectedPackage && selectedPackage?.SessionType === 3 && (
            <Card
              className={
                "bz-card bz-overview-cards bz-appoinments-overview-card pt-0"
              }
              style={{ backgroundColor: "#eff4f7" }}
            >
              <CardBody>{AppointmentsBox(selectedPackage)}</CardBody>
            </Card>
          )}
        </div>
      </div>
      {couponAddVisible && (
        <Dialog
          className="bz-book-Appoinment-dialog bz-applyCoupon-dialog"
          title={"Are you sure you want to apply the selected coupon?"}
          onClose={() => toggleAddCouponDialog()}
          width={"30rem"}
        >
          {couponsList?.length > 0 && (
            <>
              <div className={"alert alert-warning py-2 px-3"}>
                <strong>Note:</strong> It will override if there is any existing
                same type of coupon.
              </div>
            </>
          )}
          <div className="ready-to-book-appt-dialog">
            <div className="column-1-section text-right">
              Name<span className="purchased-dots px-3">:</span>
            </div>
            <div className="column-2-section" style={{ fontWeight: 600 }}>
              {couponValue?.Description}
            </div>
          </div>
          <DialogActionsBar>
            <Button onClick={() => handleAddCouponToPackage(false)}>
              {"Cancel"}
            </Button>
            <BuzopsButton
              label="Ok"
              loading={dialogLoading}
              disabled={dialogLoading}
              onClick={() => handleAddCouponToPackage(true)}
            />
          </DialogActionsBar>
        </Dialog>
      )}

      {couponRemoveVisible && (
        <Dialog onClose={() => toggleRemoveCouponDialog()}>
          <p className={"dialogContainer"}>Are you sure to Deactivate ?</p>
          <DialogActionsBar>
            <Button onClick={() => handleRemoveCouponFromPackage(false)}>
              {"Cancel"}
            </Button>
            <BuzopsButton
              label="Ok"
              loading={dialogLoading}
              disabled={dialogLoading}
              onClick={() => handleRemoveCouponFromPackage(true)}
            />
          </DialogActionsBar>
        </Dialog>
      )}
      {showRolloverDialog && (
        <Dialog
          className="bz-book-Appoinment-dialog"
          title={"Adjust Rollover Appointments"}
          onClose={() => handleCancelRollOver()}
          width={"35%"}
        >
          <div className="ready-to-book-appt-dialog pb-1">
            <div className="column-1-section">
              Start Date<span className="purchased-dots">:</span>
            </div>
            <div className="column-2-section">
              {selectedBillingPeriod?.EffectiveFrom
                ? moment(selectedBillingPeriod?.EffectiveFrom).format("L")
                : "NA"}
            </div>
          </div>
          <div className="ready-to-book-appt-dialog pb-1">
            <div className="column-1-section">
              Next Renewal Date<span className="purchased-dots">:</span>
            </div>
            <div className="column-2-section">
              {selectedBillingPeriod?.RenewalOn
                ? moment(selectedBillingPeriod?.RenewalOn).format("L")
                : "NA"}
            </div>
          </div>
          <div className="ready-to-book-appt-dialog pb-1">
            <div className="column-1-section">
              Appointments<span className="purchased-dots">:</span>
            </div>
            <div className="column-2-section">{`${selectedBillingPeriod?.UsedSchedules} of ${selectedBillingPeriod?.Schedules}`}</div>
          </div>
          <div className="ready-to-book-appt-dialog pb-1">
            <div className="column-1-section">
              Rollover Appoinments<span className="purchased-dots">:</span>
            </div>
            <div className="column-2-section">
              <NumericTextBox
                value={rolloverAppointment}
                name={"rolloverAppointments"}
                id={"rolloverAppointments"}
                placeholder="Appointments"
                min={0}
                format={"n0"}
                onChange={(e) => {
                  setRolloverAppointment(e.target.value);
                }}
              />
              <Error>
                {rolloverAppointment < mininmumRolloverAppts &&
                  "The Rollover schedules can't be reduced as utilized schedules with in the billing period"}
              </Error>
            </div>
          </div>
          <p className={"dialogContainer"}>
            <Notify key="info" type={{ style: "info" }}>
              Note: Adjust appointments for the current billing cycle within the
              current period, while any remaining appointments will roll over
              according to the configured rollover settings
            </Notify>
          </p>
          <DialogActionsBar>
            <Button onClick={() => handleCancelRollOver()}>{"Cancel"}</Button>
            <BuzopsButton
              label="Ok"
              loading={rolloverLoading}
              disabled={rolloverLoading}
              onClick={() => handleUpdateRollOver()}
            />
          </DialogActionsBar>
        </Dialog>
      )}

      {visibleAC && (
        <Dialog
          title={"Update Renewal options"}
          onClose={toggleACDialog}
          height={"calc(100vh - 2rem)"}
          width={"calc(100vw/2)"}
          style={{ alignItems: "end", paddingRight: "1rem" }}
        >
          <div>{renderUpdateRenewal()}</div>
          <DialogActionsBar>
            <Button
              className="ml-0"
              onClick={() => resetRenewalForm()}
              disabled={updateRecurrenceSubmitLoading}
            >
              Reset
            </Button>
            <Button
              onClick={toggleACDialog}
              disabled={updateRecurrenceSubmitLoading}
            >
              Cancel
            </Button>
            <BuzopsButton
              onClick={() => saveRenewalOtions()}
              primary
              type={"button"}
              label={"Save"}
              disabled={updateRecurrenceSubmitLoading || !formModified}
              loading={updateRecurrenceSubmitLoading}
            />
          </DialogActionsBar>
        </Dialog>
      )}
      {isDialogVisible && (
        <Dialog className="bz-confirm-dialog">
          <p
            style={{
              marginBottom: 0,
              paddingTop: "0.75rem",
              paddingBottom: "0.25rem",
            }}
          >
            {selectedPackage?.Type=== TypeEnum.Class ? `All Classes for your package have been used. Would you like to purchase additional Classes to signoff more?`:`All appointments for your package have been used. Would you like to
            purchase additional appointments to signoff more?`}
          </p>

          <DialogActionsBar layout={"end"}>
            <Button
              className="dialog-btn"
              onClick={() => setIsDialogVisible(false)}
            >
              Cancel
            </Button>
            <Button
              primary={true}
              className="dialog-btn"
              onClick={() => handleConfirmation()}
            >
              Continue
            </Button>
          </DialogActionsBar>
        </Dialog>
      )}
      {showAppointmentDialog && (
        <Dialog
          className="addaffilatespromotion"
          title={"Manage Appointments for Current Billing Cycle"}
          onClose={() => setShowAppointmentDialog(false)}
          
          style={{ overflow: "auto" }}
        >
          <div className="k-dialog-content" style={{maxHeight:"350px", overflowY:'auto', padding:'0 5px'}}>
            <span className="text-blue-500 font-semibold mb-2">
              {selectedPackage.Name}
            </span>
            <p className="text-sm mb-2">
              <strong>
                Unused Appt(s):{" "}
                <span className="font-bold">
                  {getAvailableAppts(selectedAppointmentDataItem)}
                </span>
              </strong>
            </p>
            <p className="text-sm mb-3 text-center">
              Add or remove appointments for the current billing cycle.
            </p>
            <div
              className="mobileapptsdialog d-flex justify-content-between align-items-center mb-3 px-4 py-3 add-appts-buttons-class"
              style={{ backgroundColor: "#F4F9FF", borderRadius: "8px", border:'1px dashed #d1dbd9' }}
            >
              <button
                className={`button ${
                  appointmentDialogData.apptCount < 0
                    ? "selected"
                    : "notSelected"
                }`}
                style={{
                  borderRadius: "50px",
                  padding: "6px 15px",
                }}
                onClick={handleDecreaseApptsCount}
              >
                Remove Appt(s)
              </button>

              <span
                className="fs-4 buz-font-600 "
                style={{
                  margin: "0px 20px",
                  border: "1px solid #e2e5e9",
                  borderRadius: "7px",
                  padding: "5px 10px",
                  minWidth: '44px',
                  textAlign:'center'
                }}
              >
                {`${appointmentDialogData.apptCount > 0 ? "+" : ""}${
                  appointmentDialogData.apptCount
                }`}
              </span>

              <button
                className={`button ${
                  appointmentDialogData.apptCount > 0
                    ? "selected"
                    : "notSelected"
                }`}
                style={{
                  borderRadius: "50px",
                  padding: "6px 15px",
                }}
                onClick={handleIncreaseApptsCount}
              >
                Add Appt(s)
              </button>
            </div>

            {appointmentDialogData.apptCount > 0 && (
              <>
                {/* Cost Per Appointment */}
                <div className="mb-4">
                  <label className="block text-sm mb-1 ft12 ft500">
                    Cost Per Appointment
                  </label>
                  <div>
                  <NumericTextBox
                    id={"PerAppointment"}
                    name={"PerAppointment"}
                     
                    placeholder="Enter Per Appointment"
                    min={0}
                    format={"c2"}
                    disabled={!props?.staffPage}
                    value={appointmentDialogData?.costPerAppointment}
                    onChange={(e: any) => {
                      if (e.target.value >= 0) {
                        setAppointmentDialogData({
                          ...appointmentDialogData,
                          costPerAppointment: Number(e.target.value),
                        });
                        handleTaxCalculationForAppt(
                          Number(e.target.value) *
                            appointmentDialogData?.apptCount
                        );
                      }
                    }}
                  />
                </div>
                </div>
                {appointmentDialogData?.costPerAppointment > 0 && (
                  <>
                    <div className="addpaymentprofiledialog"> 
                    <div className="mb-2">
                      <div className="bzc-provider-name">
                        <label className="mb-2 block text-sm ft12 ft500">
                          Payment Profile
                        </label>
                        <div className="payment-profile d-flex ">
                          <div className=" paymentdropma paymentdropcont">
                            <DropDownList
                              id={`PaymentGatewayProfileId`}
                              name={`PaymentGatewayProfileId`}
                              data={paymentProfile}
                              textField="MaskedCCNumber"
                              dataItemKey="PaymentGatewayPaymentProfileID"
                              valueRender={(
                                el: React.ReactElement<HTMLSpanElement>,
                                value: any
                              ) =>
                                PaymentValueRender(
                                  el,
                                  value,
                                  appointmentDialogData?.PaymentProfile
                                )
                              }
                              itemRender={PaymentItemRender}
                              onChange={(e) => {
                                setAppointmentDialogData({
                                  ...appointmentDialogData,
                                  PaymentProfile: e.value,
                                });
                              }}
                              style={{ width: "100%" }}
                            />
                          </div>
                          {checkIfUserAddPaymentGateway() && (
                            <div className="addNewBtn">
                              <DropDownButton
                                text="Add New"
                                onItemClick={(e: any) => onCardOrBankAdd(e)}
                                items={paymentDropdownItems()?.filter(
                                  (i: any) => i?.visible === true
                                )}
                                look="flat"
                                className="btn btn-link add-new-btn"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {appointmentDialogData?.PaymentProfile?.CardTypeId ===
                      -2 && (
                      <>
                        <div className={`mb-2`}>
                          <label className="block text-sm mb-1 ft12 ft500">
                            Transaction Reference Number
                          </label>
                          <div className="bz-content-body">
                            <div className={"k-form-field-wrap"}>
                              <Input
                                onChange={(event) =>
                                  setAppointmentDialogData({
                                    ...appointmentDialogData,
                                    refNumber: event?.target?.value as string,
                                  })
                                }
                                value={appointmentDialogData.refNumber}
                                type={"text"}
                                style={{ width: "100%" }}
                                id={"refNumber"}
                              />
                            </div>
                          </div>
                        </div>
                        <div className={`mb-2`}>
                          <label className="block text-sm mb-1 ft12 ft500">
                            Reference Document
                          </label>
                          <div className="bz-content-body">
                            <div className={"k-form-field-wrap"}>
                              {/* <input type="file" /> */}
                              <Upload
                                autoUpload={false}
                                batch={false}
                                restrictions={{
                                  // allowedExtensions: [".jpg", ".png", ".jpeg"],
                                  maxFileSize: 1000000,
                                }}
                                showActionButtons={false}
                                multiple={false}
                                onAdd={onAdd}
                                withCredentials={false}
                                onRemove={onRemove}
                                files={originalFiles}
                              />
                            </div>
                          </div>
                        </div>
                      </>
                        )}
                      </div>
                  </>
                )}
              </>
            )}
            {appointmentDialogData.apptCount > 0 ? (
              <>
                <div className="ready-to-book-appt-dialog pb-1 ">
                  <div className="column-1-section buz-font-600 ft12 ft500">
                    Total Appt(s) Added
                  
                  </div>
                  <div className="column-2-section buz-font-600 ft12 ft500">
                  <span  >:</span>  {CurrencyFormat(appointmentDialogData.costPerAppointment)} x{" "}
                    {appointmentDialogData.apptCount} appt(s)
                  </div>
                </div>
                <div className="ready-to-book-appt-dialog pb-1 ">
                  <div className="column-1-section buz-font-600 ft12 ft500">
                    Total Cost
                  
                  </div>
                  <div className="column-2-section buz-font-600 ">
                  <span  >:</span>  {CurrencyFormat(
                      appointmentDialogData.costPerAppointment *
                        appointmentDialogData.apptCount
                    )}
                  </div>
                </div>
                {appointmentDialogData?.costPerAppointment > 0 &&
                  checkHasTaxEnable() && (
                    <>
                      <div className="ready-to-book-appt-dialog pb-1 ">
                        <div className="column-1-section buz-font-600 ft12 ft500">
                          Surcharges
                        
                        </div>
                        <div className="column-2-section buz-font-600">
                          <div className={"k-form-field-wrap"}>
                          <span >:</span>  <Switch
                              checked={!appointmentDialogData?.IsTaxSkipped}
                              defaultChecked={false}
                              name={`IsTaxSkipped`}
                              onChange={(e: any) =>
                                setAppointmentDialogData({
                                  ...appointmentDialogData,
                                  IsTaxSkipped:
                                    !appointmentDialogData?.IsTaxSkipped,
                                })
                              }
                              className="mr-1 d-inline-flex"
                            />
                          </div>
                        </div>
                      </div>
                      {!appointmentDialogData?.IsTaxSkipped && (
                        <>
                          <div className="ready-to-book-appt-dialog pb-1 ">
                            <div className="column-1-section buz-font-600 ft12 ft500">
                              {" "}
                              <Tooltip
                                content={(tooltipProps: any) => {
                                  return (
                                    <TooltipContentPackageService
                                      title={tooltipProps.title}
                                      data={Taxes}
                                      TotalTaxAmount={TotalTaxAmount}
                                    />
                                  );
                                }}
                                tooltipClassName="bz-tooltip-table"
                              >
                                <a
                                  title="Surcharges"
                                  style={{
                                    borderBottom: "1px dotted #0d6efd",
                                    cursor: "pointer",
                                  }}
                                >
                                  {"Surcharges to be collected"}
                                  
                                </a>
                              </Tooltip>
                            </div>
                            <div className="column-2-section buz-font-600 ">
                            <span >:</span> {CurrencyFormat(TotalTaxAmount)}
                            </div>
                          </div>
                          <div className="ready-to-book-appt-dialog pb-1 ">
                            <div className="column-1-section buz-font-600 ft12 ft500">
                              Total
                             
                            </div>
                            <div className="column-2-section buz-font-600">
                            <span >:</span> {CurrencyFormat(
                                appointmentDialogData.costPerAppointment *
                                  appointmentDialogData.apptCount +
                                  TotalTaxAmount
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
              </>
            ) : (
              appointmentDialogData.apptCount !== 0 && (
                <div className="ready-to-book-appt-dialog pb-1 ">
                  <div className="column-1-section buz-font-600 ">
                    Total Appt(s) To Remove
                    <span className="purchased-dots">:</span>
                  </div>
                  <div className="column-2-section buz-font-600 ">
                    {appointmentDialogData.apptCount
                      .toString()
                      .replace("-", "")}{" "}
                    appt(s)
                  </div>
                </div>
              )
            )}
          </div>

          {/* Footer Actions */}
          <DialogActionsBar>
            {appointmentDialogData.apptCount > 0 ? (
              <BuzopsButton
                label="Purchase"
                disabled={appointmentDialogData.apptCount === 0 || btnLoading}
                loading={btnLoading}
                key={btnLoading?.toString()}
                onClick={() => handleAddAppointments()}
              />
            ) : (
              <BuzopsButton
                label="Update Appt"
                disabled={appointmentDialogData.apptCount === 0 || btnLoading}
                loading={btnLoading}
                key={btnLoading?.toString()}
                onClick={() => handleRemoveAppointments()}
              />
            )}
          </DialogActionsBar>
        </Dialog>
      )}
      {
  showAppointmentHistoryDialog && (
    <Dialog
      className="addaffilatespromotion"
      onClose={() => setShowAppointmentHistoryDialog(false)}
      style={{ overflow: "auto" }}
      width={'45%'}
    >
      <div
        className="k-dialog-content"
        style={{ maxHeight: "350px", overflowY: "auto", padding: "0 5px" }}
      >
        <span className="font-semibold mb-2">
          The appointments have been modified for the current billing cycle.
          Please review the breakdown below:
        </span>
        {showAppointmentHistoryLoading ? <BuzopsLoader type="list" /> : <Grid
          data={appointmentHistory || []}
          className="bzc-grid bz-checkins-grid"
        >
          <Column field="CreateDateTime" title="Date" />
          <Column field="NoOfAppointments" title="No. of Appointments" />
          <Column field="Rate" title="Rate per Appointment"  cell={amountCell} />
          <Column field="SalesPerson" title="By" />
        </Grid>}
        
        <div className="d-flex justify-content-end mt-2">
          <Button
            primary
            onClick={() => setShowAppointmentHistoryDialog(false)}
          >
            Close
          </Button>
        </div>
      </div>
    </Dialog>
  )}

      {showPaymentDialog && PaymethodDialog()}
    </>
  );
};

export default withNotification(PackageOverView);
