import { BuzopsTooltip } from "./BuzopsTooltip";
const BuzopsLongNameHandler = (props: any) => {
  const taxNameLength = props?.name?.length >= props?.maxLength;
  return taxNameLength ? (
    <BuzopsTooltip
      html={
        <span
          className="tax-name-clip"
          style={{ fontWeight: 600, lineHeight: 'normal' }}
          title={props?.name}
        >
          {props?.name}
        </span>
      }
      toolTipClass={props?.className}
    />
  ) : (
    <span style={{ fontWeight: 600 }}>{props?.name}</span>
  );
};

export default BuzopsLongNameHandler;
