import { CommonServiceUtils } from "services/common-service-utils";
import { ApplicationTypeEnum } from "utils/form-utils";
import {
  GetLocalStore,
  LocalStore,
  RemoveAllLocalStore,
  RemoveLocalStore,
  setWithExpiry,
} from "utils/storage";
import TimeZoneSetup from "utils/time-zone-setup";
export class TenantService extends CommonServiceUtils {
  async getTenantDetails(tenant: string,AboutApi=true): Promise<any> {
    // eslint-disable-next-line no-new-object
    const tenantDetails: any = new Object();
    const getToken: any = await this.postData(
      `${this.apiUrlCreator(`authentication/issuetoken/${tenant}`)}`
    );
    tenantDetails.tokenDetails = getToken;
    if (tenant !== GetLocalStore("tenant")) {
      RemoveAllLocalStore();
    }
    RemoveLocalStore("tenant");
    if (getToken?.ResponseCode === 100) {
      LocalStore("tenant", getToken.Item.DomainName);

      if (!GetLocalStore("userDetails")) {
          if(GetLocalStore('CurrentApplicationType')===ApplicationTypeEnum.AFFILIATE && GetLocalStore("AffiliateDetails")){
          }else{
          LocalStore("token", getToken.Item.Token);
          LocalStore("tenantUserSession", {
            ...getToken?.Item?.UserSession,
            DomainName: getToken.Item.DomainName,
            StripePaymentConfiguration: getToken.Item.StripePaymentConfiguration,
            ChargehubSquarePaymentProfile:
              getToken.Item.ChargehubSquarePaymentProfile,
            Configuration: getToken.Item.Configuration,
          });
          LocalStore(
            "CurrentApplicationType",
            getToken?.Item?.UserSession?.CurrentApplicationType
          );
        }
      }
      LocalStore("Configuration", {...getToken.Item.Configuration,IsAffiliateFeatureEnabled:getToken.Item.IsAffiliateFeatureEnabled});
      const ttl = 8 * 60 * 60;
      setWithExpiry("expiry", ttl);
      if(AboutApi){
        const getAboutDetails: any = await this.postData(
          `${this.apiUrlCreator(`site/about`)}`
        );
        tenantDetails.aboutDetails = getAboutDetails;
        if (getAboutDetails?.ResponseCode === 100) {
          LocalStore("tenantDetails", getAboutDetails.Item);
        }
      }
      TimeZoneSetup(
        getToken.Item.UserSession?.TenantTime,
        getToken.Item.UserSession
      );
      return tenantDetails;
    } else {
      return tenantDetails;
    }
  }
  async mergeClient(payload:any):Promise<any>{
    const mergeClientProfile: any = await this.postData(
      `${this.apiUrlCreator(`client/Merge`)}`,
      {Where:payload}
      
    );
    return mergeClientProfile
  }

  async getProviderDetails(
    tenant: string,
    provider: string,
    shortCode = null
  ): Promise<any> {
    if (tenant !== GetLocalStore("tenant")) {
      RemoveAllLocalStore();
    }
    let url = `${tenant}/${provider}`;
    if (shortCode !== null) {
      url = `${tenant}/${provider}/${shortCode}`;
    }
    const providerDetails: any = await this.postData(
      `${this.apiUrlCreator(`authentication/issuetoken/${url}`)}`
    );
    if (providerDetails?.ResponseCode === 100) {
      LocalStore("tenant", tenant);
      LocalStore("token", providerDetails.Item.Token);
      LocalStore("tenantUserSession", {
        ...providerDetails?.Item?.UserSession,
        DomainName: providerDetails.Item.DomainName,
        StripePaymentConfiguration:
          providerDetails.Item.StripePaymentConfiguration,
        ChargehubSquarePaymentProfile:
          providerDetails.Item.ChargehubSquarePaymentProfile,
        Configuration: providerDetails.Item.Configuration,
      });
      LocalStore("Configuration", {...providerDetails.Item.Configuration,IsAffiliateFeatureEnabled:providerDetails.Item.IsAffiliateFeatureEnabled});
      LocalStore("provider", providerDetails.Item.Provider);
      TimeZoneSetup(
        providerDetails.Item.UserSession?.TenantTime,
        providerDetails.Item.UserSession
      );
      const getAboutDetails: any = await this.postData(
        `${this.apiUrlCreator(`site/about`)}`
      );
      if (getAboutDetails?.ResponseCode === 100) {
        providerDetails.aboutDetails = getAboutDetails.Item;
        LocalStore("tenantDetails", getAboutDetails.Item);
      }
    }
    return providerDetails;
  }

  async getServiceDetails(req: any): Promise<any> {
    let response = [];
    const serviceData: any = await this.postData(
      `${this.apiUrlCreator(`service/filter`)}`,
      req
    );
    if (serviceData?.ResponseCode === 100) {
      response = serviceData.Item;
    }
    return response;
  }

  async getAppointmentDetails(req: any): Promise<any> {
    // alert('ser_in');
    let response = [];
    const serviceData: any = await this.postData(
      `${this.apiUrlCreator(`Appointments/Inventory`)}`,
      req
    );
      response=serviceData.Items;
    return response;
  }

  async getAppointmentDetailsWithTotalCount(req: any): Promise<any> {
    // alert('ser_in');
    let response = [];
    const serviceData: any = await this.postData(
      `${this.apiUrlCreator(`Appointments/Inventory`)}`,
      req
    );
    return serviceData;
  }

  async getTransactions(req: any): Promise<any> {
    let response = null;
    const transactionData: any = await this.postData(
      `${this.apiUrlCreator(`payment/Transactions`)}`,
      req
    );
    if (transactionData?.ResponseCode === 100) {
      response = transactionData;
    }
    return response;
  }

  async getIndividualService(serviceId: any): Promise<any> {
    let response = null;
    const serviceData: any = await this.postData(
      `${this.apiUrlCreator(`serviceinstance/instance?entityId=${serviceId}`)}`
    );
    if (serviceData?.ResponseCode === 100) {
      response = serviceData.Item;
    }
    return response;
  }

  async getPresetPackageService(serviceId: any): Promise<any> {
    let response = null;
    const serviceData: any = await this.postData(
      `${this.apiUrlCreator(`Packageinstance/RecurringDetail?packageInstanceId=${serviceId}`)}`
    );

    response = serviceData.Item;

    return response;
  }

  async verifyIfEmailExist(req: any): Promise<any> {
    const findProfile: any = await this.postData(
      `${this.apiUrlCreator(`authentication/FindProfile`)}`,
      req
    );
    let response = false;
    if (findProfile?.ResponseCode === 100) {
      let itemData = findProfile.Item;
      if (
        itemData.UserId !== "00000000-0000-0000-0000-000000000000" &&
        itemData.IsActivated
      ) {
        response = true;
      }
      LocalStore("userId", itemData.UserId);
      LocalStore("loginDetails", itemData);
    }
    LocalStore("emailId", req.EmailId);
    return {
      isEmailExists: response,
      UserId: findProfile.Item.UserId,
      EmailId: findProfile.Item.EmailId,
      IsActivated: findProfile.Item.IsActivated,
      UserMemberId: findProfile.Item.UserMemberId,
    };
  }

  async validateOTP(payload: any) {
    const response: any = await this.postData(
      `${this.apiUrlCreator(`authentication/validateotp`)}`,
      payload
    );
    return response;
  }

  async sendOTP(payload: any) {
    const response: any = await this.postData(
      `${this.apiUrlCreator(`authentication/sendotp`)}`,
      payload
    );
    return response;
  }
  async authenticateUser(req: any): Promise<any> {
    const authentication: any = await this.postData(
      `${this.apiUrlCreator(`authentication`)}`,
      req
    );
    let response = false;
    if (authentication?.ResponseCode === 100) {
      LocalStore("userDetails", authentication.Item);
      LocalStore("tenantUserSession", {
        ...authentication?.Item?.UserSession,
        DomainName: authentication.Item.DomainName,
        StripePaymentConfiguration:
          authentication.Item.StripePaymentConfiguration,
        ChargehubSquarePaymentProfile:
          authentication.Item.ChargehubSquarePaymentProfile,
        Configuration: authentication.Item.Configuration,
      });
      if(authentication.Item.Configuration){
        LocalStore("Configuration", {...authentication.Item.Configuration,IsAffiliateFeatureEnabled:authentication.Item.IsAffiliateFeatureEnabled});
      }else{
        LocalStore("Configuration", {...GetLocalStore("Configuration"),IsAffiliateFeatureEnabled:authentication.Item.IsAffiliateFeatureEnabled});
      }
      LocalStore(
        "CurrentApplicationType",
        authentication?.Item?.UserSession?.CurrentApplicationType
      );
      LocalStore("UserMemberId", authentication.Item.UserSession.UserMemberId);
      response = true;
    }
    return { response: response, data: authentication || [] };
  }
  async resetPassword(req: any): Promise<any> {
    const forgotPassword: any = await this.postData(
      `${this.apiUrlCreator(`authentication/ForgotPassword`)}`,
      req
    );
    return forgotPassword;
  }

  async getClient(userMemberId: number): Promise<any> {
    const getClientDetails: any = await this.getData(
      `${this.apiUrlCreator(`Client?userMemberId=${userMemberId}`)}`
    );
    let response = {};
    if (getClientDetails?.ResponseCode === 100) {
      response = getClientDetails.Item;
    }
    return response;
  }
  async createPaymentProfile(req: any): Promise<any> {
    const createPaymentProfile: any = await this.postData(
      `${this.apiUrlCreator(`PaymentProfile/create`)}`,
      req
    );
    return createPaymentProfile;
  }
  async UpdateRecurringPaymentProfile(req: any): Promise<any> {
    const UpdateRecurringPaymentProfile: any = await this.postData(
      `${this.apiUrlCreator(`PaymentProfile/UpdateRecurringPaymentProfile`)}`,
      req
    );
    return UpdateRecurringPaymentProfile;
  }

  async paymentProfile(userMemberId: number): Promise<any> {
    const paymentProfileDetails: any = await this.postData(
      `${this.apiUrlCreator(`PaymentProfile/filter`)}`,
      { UserMemberId: userMemberId }
    );
    let response = [];
    if (paymentProfileDetails?.ResponseCode === 100) {
      response = paymentProfileDetails.Items;
    }
    return response;
  }
  async GetPaymentProfile(item: any): Promise<any> {
    const linkedSubscription: any = await this.postData(
      `${this.apiUrlCreator(`PaymentProfile/getpaymentprofile`)}`,
      {
        CustomerProfileID: item?.CustomerProfileID,
        PaymentProfileID: item?.PaymentProfileID,
      }
    );
    let response = [];
    if (linkedSubscription?.ResponseCode === 100) {
      response = linkedSubscription.Item;
    }
    return response;
  }

  async LinkedSubscription(userMemberId: number): Promise<any> {
    const linkedSubscription: any = await this.postData(
      `${this.apiUrlCreator(`recurringdetail/filter`)}`,
      {
        where: {
          userMemberId: userMemberId,
        },
      }
    );
    let response = [];
    if (linkedSubscription?.ResponseCode === 100) {
      response = linkedSubscription.Items;
    }
    return response;
  }
  async ChangedSubscription(SubsscrptionData: any): Promise<any> {
    const linkedSubscription: any = await this.postData(
      `${this.apiUrlCreator(`recurringdetail/update`)}`,
      SubsscrptionData
    );

    return linkedSubscription;
  }

  async findCoupon(req: any): Promise<any> {
    const findCoupon: any = await this.postData(
      `${this.apiUrlCreator(`Coupon/applycoupon`)}`,
      req
    );
    return findCoupon;
  }

  async buildAgreement(req: any): Promise<any> {
    const createbuildAgreement: any = await this.postData(
      `${this.apiUrlCreator(`service/buildagreement`)}`,
      req
    );
    return createbuildAgreement;
  }
  async purchaseService(req: any): Promise<any> {
    const purchaseDetails: any = await this.postData(
      `${this.apiUrlCreator(`service/Purchase`)}`,
      req
    );
    return purchaseDetails;
  }

  async AppointmentpurchaseService(req: any): Promise<any> {
    const purchaseDetails: any = await this.postData(
      `${this.apiUrlCreator(`Appointments/Purchase`)}`,
      req
    );
    return purchaseDetails;
  }

  async getServiceInstance(req: any): Promise<any> {
    const getServiceInstanceDetails: any = await this.postData(
      `${this.apiUrlCreator(`serviceinstance`)}`,
      req
    );
    let response = {};
    if (getServiceInstanceDetails?.ResponseCode === 100) {
      response = getServiceInstanceDetails.Items;
    }
    return response;
  }

  async getServiceFilter(req: any): Promise<any> {
    const getServiceFilterDetails: any = await this.postData(
      `${this.apiUrlCreator(`serviceinstance/filter`)}`,
      req
    );
    let response = [];
    if (getServiceFilterDetails?.ResponseCode === 100) {
      response = getServiceFilterDetails.Items;
    }
    return response;
  }

  async getServiceInstanceById(entityId: number): Promise<any> {
    const getServiceInstanceById: any = await this.postData(
      `${this.apiUrlCreator(`serviceinstance/instance?entityId=${entityId}`)}`
    );
    let response = {};
    if (getServiceInstanceById?.ResponseCode === 100) {
      response = getServiceInstanceById.Item;
    }
    return response;
  }

  async getRecurringDetails(entityId: number): Promise<any> {
    const getRecurringDet: any = await this.postData(
      `${this.apiUrlCreator(
        `serviceinstance/RecurringDetails?instanceId=${entityId}`
      )}`
    );
    let response = {};
    if (getRecurringDet?.ResponseCode === 100) {
      response = getRecurringDet.Item;
    }
    return response;
  }
  async deletePaymentProfile(paymentId: number): Promise<any> {
    const deletePaymentProfile: any = await this.postData(
      `${this.apiUrlCreator(
        `PaymentProfile/delete?paymentGatewayPaymentProfileId=${paymentId}`
      )}`
    );

    return deletePaymentProfile;
  }

  async updateRecurrence(req: any): Promise<any> {
    const updateRecurrence: any = await this.postData(
      `${this.apiUrlCreator(`serviceinstance/UpdateRecurringDetails`)}`,
      req
    );
    return updateRecurrence;
  }

  async updatePackageRecurrence(req: any): Promise<any> {
    const updatePackageRecurrence: any = await this.postData(
      `${this.apiUrlCreator(`Packageinstance/UpdateRecurringDetail`)}`,
      req
    );
    return updatePackageRecurrence;
  }

  async PresetPayRemainingDue(req: any): Promise<any> {
    const payRemainingDue: any = await this.postData(
      `${this.apiUrlCreator(`Packageinstance/PayRemaining`)}`,
      req
    );
    return payRemainingDue;
  }

  async updateRenewal(req: any): Promise<any> {
    const updateRenewal: any = await this.postData(
      `${this.apiUrlCreator(`serviceinstance/SaveRenewalOptions`)}`,
      req
    );
    return updateRenewal;
  }

  async getTrainers(): Promise<any> {
    const getTrainerDetails: any = await this.postData(
      `${this.apiUrlCreator(`provider/trainers`)}`
    );
    let response = [];
    if (getTrainerDetails?.ResponseCode === 100) {
      response = getTrainerDetails.Items;
    }
    return response;
  }
  async getServiceLevelTrainers(shortCode: string): Promise<any> {
    const getTrainerDetails: any = await this.getData(
      `${this.apiUrlCreator(`provider/trainers?shortCode=${shortCode}`)}`
    );
    let response = [];
    if (getTrainerDetails?.ResponseCode === 100) {
      response = getTrainerDetails.Items;
    }
    return response;
  }

  //This is the Function for getting the trainers those are not tagged with the Particular service
  async getAppointmentLevelTrainers(shortCode: string,trainerId: any): Promise<any> {
    const getTrainerDetails: any = await this.getData(
      `${this.apiUrlCreator(`provider/trainers?shortCode=${shortCode}&trainerId=${trainerId}`)}`
    );
    let response = [];
    if (getTrainerDetails?.ResponseCode === 100) {
      response = getTrainerDetails.Items;
    }
    return response;
  }
  async getAvailableDays(req: any): Promise<any> {
    const getAvailDays: any = await this.postData(
      `${this.apiUrlCreator(`provider/AvailableDays`)}`,
      req
    );
    let response = [];
    if (getAvailDays?.ResponseCode === 100) {
      response = getAvailDays.Items;
    }
    return response;
  }
  async getAvailableDayTimes(req: any): Promise<any> {
    const getAvailDayTimes: any = await this.postData(
      `${this.apiUrlCreator(`provider/AvailableDayTimes`)}`,
      req
    );
    let response = [];
    if (getAvailDayTimes?.ResponseCode === 100) {
      response = getAvailDayTimes.Items;
    }
    return response;
  }
  async getUpcomingAppointments(req: any): Promise<any> {
    const getAvailAppointments: any = await this.postData(
      `${this.apiUrlCreator(`serviceinstance/SearchAppointments`)}`,
      req
    );
    let response = [];
    if (getAvailAppointments?.ResponseCode === 100) {
      response = getAvailAppointments.Item;
    }
    return response;
  }
  async cancelUpcomingAppointments(req: any): Promise<any> {
    const getAvailAppointments: any = await this.postData(
      `${this.apiUrlCreator(`serviceinstance/RemoveAppointments`)}`,
      req
    );
    return getAvailAppointments;
  }
  async validateAppointmentsOverlap(req: any): Promise<any> {
    const checkValidateAppointments: any = await this.postData(
      `${this.apiUrlCreator(`serviceinstance/ValidateAppointmentsOverlap`)}`,
      req
    );
    let response = [];
    if (checkValidateAppointments?.ResponseCode === 100) {
      response = checkValidateAppointments.Items;
    }
    return response;
  }

  async GetRecurringAppointments(req: any): Promise<any> {
    const getRecurringAppointments: any = await this.postData(
      `${this.apiUrlCreator(`serviceinstance/GetRecurringAppointments`)}`,
      req
    );
    let response = [];
    if (getRecurringAppointments?.ResponseCode === 100) {
      response = getRecurringAppointments.Items;
    }
    return response;
  }

  async saveAppointments(req: any): Promise<any> {
    const SaveAppointments: any = await this.postData(
      `${this.apiUrlCreator(`serviceinstance/SaveAppointments`)}`,
      req
    );
    return SaveAppointments;
  }

  async getInsights(userMemberId: number): Promise<any> {
    const getClientInsights: any = await this.postData(
      `${this.apiUrlCreator(`client/Insights?userMemberId=${userMemberId}`)}`
    );
    let response = {};
    if (getClientInsights?.ResponseCode === 100) {
      response = getClientInsights.Item;
    }
    return response;
  }

  async declinedPayments(req: any): Promise<any> {
    const DeclinedPayments: any = await this.postData(
      `${this.apiUrlCreator(`payment/declined`)}`,
      req
    );
    return DeclinedPayments;
  }

  async getMemberPlans(req: any): Promise<any> {
    const getMemberPlans: any = await this.postData(
      `${this.apiUrlCreator(`MemberPlan/Subscriptions`)}`,
      req
    );
    let response = [];
    if (getMemberPlans?.ResponseCode === 100) {
      response = getMemberPlans.Item?.Plans;
    }
    return response;
  }

  async getMemberPlanSummary(req: any): Promise<any> {
    const getMemberPlanSummary: any = await this.postData(
      `${this.apiUrlCreator(`MemberPlan/Summary`)}`,
      req
    );
    let response = null;
    if (getMemberPlanSummary?.ResponseCode === 100) {
      response = getMemberPlanSummary.Items?.[0];
    }
    return response;
  }
  async getAllMemberPlanSummary(req: any): Promise<any> {
    const getMemberPlanSummary: any = await this.postData(
      `${this.apiUrlCreator(`MemberPlan/Summary`)}`,
      req
    );
    let response = null;
    if (getMemberPlanSummary?.ResponseCode === 100) {
      response = getMemberPlanSummary.Items;
    }
    return response;
  }
  async getAddOnSummary(req: any): Promise<any> {
    const getAddOnSummary: any = await this.postData(
      `${this.apiUrlCreator(`Addon/Filter`)}`,
      req
    );
    const response = getAddOnSummary?.Items?.[0];
    return response;
  }
  async getAllAddOnSummary(req: any): Promise<any> {
    const getAddOnSummary: any = await this.postData(
      `${this.apiUrlCreator(`Addon/Filter`)}`,
      req
    );
    const response = getAddOnSummary?.Items;
    return response;
  }

  async getMemberAddons(req: any): Promise<any> {
    const getMemberAddons: any = await this.postData(
      `${this.apiUrlCreator(`MemberPlan/Subscriptions`)}`,
      req
    );
    let response = [];
    if (getMemberAddons?.ResponseCode === 100) {
      const addons = getMemberAddons.Item?.AddOns;
      const plans = getMemberAddons.Item?.Plans;
      addons.map((a: any) => {
        plans.map((p: any) => {
          if (p.PlanId === a.PlanId) {
            Object.assign(a, { planDetails: p });
          }
        });
      });
      response = addons;
    }
    return response;
  }
  async getMemberPackages(req: any): Promise<any> {
    const getMemberPackages: any = await this.postData(
      `${this.apiUrlCreator(`MemberPlan/Subscriptions`)}`,
      req
    );
    let response = [];
    if (getMemberPackages?.ResponseCode === 100) {
      response = getMemberPackages.Item?.Packages;
    }
    return response;
  }

  async updatePaymentProfile(req: any): Promise<any> {
    const updatePaymentProfile: any = await this.postData(
      `${this.apiUrlCreator(`PaymentProfile/update`)}`,
      req
    );
    return updatePaymentProfile;
  }

  async authenticateUserByUuid(tenant: string, uuid: string): Promise<any> {
    // eslint-disable-next-line no-new-object
    const tenantDetails: any = new Object();
    const getToken: any = await this.postData(
      `${this.apiUrlCreator(`authentication/issuetoken/${uuid}`)}`
    );
    tenantDetails.tokenDetails = getToken;
    if (tenant !== GetLocalStore("tenant")) {
      RemoveAllLocalStore();
    }
    if (getToken?.ResponseCode === 100) {
      LocalStore("token", getToken.Item.Token);
      LocalStore("tenant", getToken.Item?.DomainName);
      LocalStore("userDetails", getToken.Item);
      LocalStore("UserMemberId", getToken.Item.UserSession.UserMemberId);


      LocalStore("tenantUserSession", {
        ...getToken?.Item?.UserSession,
        DomainName: getToken.Item.DomainName,
        StripePaymentConfiguration: getToken.Item.StripePaymentConfiguration,
        ChargehubSquarePaymentProfile:
          getToken.Item.ChargehubSquarePaymentProfile,
        Configuration: getToken.Item.Configuration,
      });
      LocalStore(
        "CurrentApplicationType",
        getToken?.Item?.UserSession?.CurrentApplicationType
      );
      LocalStore("Configuration", {...getToken.Item.Configuration,IsAffiliateFeatureEnabled:getToken.Item.IsAffiliateFeatureEnabled});
      const ttl = 8 * 60 * 60;
      setWithExpiry("expiry", ttl);
      const getAboutDetails: any = await this.postData(
        `${this.apiUrlCreator(`site/about`)}`
      );
      if (getAboutDetails?.ResponseCode === 100) {
        LocalStore("tenantDetails", getAboutDetails.Item);
      }
      TimeZoneSetup(
        getToken.Item.UserSession?.TenantTime,
        getToken.Item.UserSession
      );
      return getToken.Item.UserSession;
    } else {
      return getToken?.Item?.UserSession;
    }
  }

  async getUserInfo(userMemberId: number): Promise<any> {
    const getUserInfo: any = await this.getData(
      `${this.apiUrlCreator(`client?userMemberId=${userMemberId}`)}`
    );
    let response = null;
    if (getUserInfo?.ResponseCode === 100) {
      response = getUserInfo.Item;
    }
    return response;
  }

  async saveUserInfo(req: any): Promise<any> {
    const saveUserInfo: any = await this.postData(
      `${this.apiUrlCreator(`client`)}`,
      req
    );

    return saveUserInfo;
  }

  async getStates(req: any): Promise<any> {
    const getStates: any = await this.postData(
      `${this.apiUrlCreator(`datalist/states`)}`,
      req
    );
    let response = [];
    response = getStates?.Items;
    return response;
  }

  async getCheckIns(req: any): Promise<any> {
    let response = null;
    const getCheckIns: any = await this.postData(
      `${this.apiUrlCreator(`AccessCard/Checkins`)}`,
      req
    );
    if (getCheckIns?.ResponseCode === 100) {
      response = getCheckIns;
    }
    return response;
  }

  async saveDeclinedPayments(req: any): Promise<any> {
    const saveDeclinedPayments: any = await this.postData(
      `${this.apiUrlCreator(`PaymentProfile/PayNow`)}`,
      req
    );

    return saveDeclinedPayments;
  }

  async GetAddonCancellationDetails(req: any): Promise<any> {
    let response = null;
    const getAddonCancellationDetails: any = await this.postData(
      `${this.apiUrlCreator(`Addon/GetCancellationDetails`)}`,
      req
    );
    if (getAddonCancellationDetails?.ResponseCode === 100) {
      response = getAddonCancellationDetails?.Item;
    }
    return response;
  }

  async ApproveAddonCancellation(req: any): Promise<any> {
    const ApproveCancellation: any = await this.postData(
      `${this.apiUrlCreator(`Addon/ApproveCancellation`)}`,
      req
    );

    return ApproveCancellation;
  }

  async GetPlanCancellationDetails(req: any): Promise<any> {
    let response = null;
    const getPlanCancellationDetails: any = await this.postData(
      `${this.apiUrlCreator(`MemberPlan/GetCancellationDetails`)}`,
      req
    );
    if (getPlanCancellationDetails?.ResponseCode === 100) {
      response = getPlanCancellationDetails?.Item;
    }
    return response;
  }
  async GetPlanAddOnCancellationDetails(req: any): Promise<any> {
    let response = null;
    const GetPlanAddOnCancellationDetails: any = await this.postData(
      `${this.apiUrlCreator(`MemberPlan/GetAddOnCancellationDetails`)}`,
      req
    );
    if (GetPlanAddOnCancellationDetails?.ResponseCode === 100) {
      response = GetPlanAddOnCancellationDetails?.Item;
    }
    return response;
  }

  async UpdatePlanCancellationDetails(req: any): Promise<any> {
    const updatePlanCancellationDetails: any = await this.postData(
      `${this.apiUrlCreator(`MemberPlan/UpdatePlanCancellationDetails`)}`,
      req
    );

    return updatePlanCancellationDetails;
  }
  async UpdatePlanAddOnCancellationDetails(req: any): Promise<any> {
    const UpdatePlanAddOnCancellationDetails: any = await this.postData(
      `${this.apiUrlCreator(`MemberPlan/UpdateAddOnCancellationDetails`)}`,
      req
    );

    return UpdatePlanAddOnCancellationDetails;
  }
  async PlanCancelApproveConfirmation(req: any): Promise<any> {
    const PlanCancelApproveConfirmation: any = await this.postData(
      `${this.apiUrlCreator(`MemberPlan/ApproveConfirmation`)}`,
      req
    );

    return PlanCancelApproveConfirmation;
  }

  async PlanCompleteCancellation(req: any): Promise<any> {
    const PlanCompleteCancellation: any = await this.postData(
      `${this.apiUrlCreator(`MemberPlan/CompleteCancellation`)}`,
      req
    );

    return PlanCompleteCancellation;
  }

  async GetAddonFreezeDetails(req: any): Promise<any> {
    let response = null;
    const getAddonFreezeDetails: any = await this.postData(
      `${this.apiUrlCreator(`Addon/GetFreezeDetails`)}`,
      req
    );
    if (getAddonFreezeDetails?.ResponseCode === 100) {
      response = getAddonFreezeDetails?.Item;
    }
    return response;
  }

  async ApproveFreeze(req: any): Promise<any> {
    const ApproveFreeze: any = await this.postData(
      `${this.apiUrlCreator(`Addon/ApproveFreeze`)}`,
      req
    );

    return ApproveFreeze;
  }

  async WithDrawFreeze(req: any): Promise<any> {
    const WithDrawFreeze: any = await this.postData(
      `${this.apiUrlCreator(`Addon/WithDrawFreeze`)}`,
      req
    );

    return WithDrawFreeze;
  }

  async GetPlanFreezeDetails(req: any): Promise<any> {
    let response = null;
    const getPlanFreezeDetails: any = await this.postData(
      `${this.apiUrlCreator(`MemberPlan/GetFreezeDetails`)}`,
      req
    );
    if (getPlanFreezeDetails?.ResponseCode === 100) {
      response = getPlanFreezeDetails?.Item;
    }
    return response;
  }
  async UpdatePlanFreezeDetails(req: any): Promise<any> {
    const updatePlanFreezeDetails: any = await this.postData(
      `${this.apiUrlCreator(`MemberPlan/UpdatePlanFreezeDetails`)}`,
      req
    );

    return updatePlanFreezeDetails;
  }

  async GetPlanAddOnFreezeDetails(req: any): Promise<any> {
    let response = null;
    const GetPlanAddOnFreezeDetails: any = await this.postData(
      `${this.apiUrlCreator(`MemberPlan/GetAddOnFreezeDetails`)}`,
      req
    );
    if (GetPlanAddOnFreezeDetails?.ResponseCode === 100) {
      response = GetPlanAddOnFreezeDetails?.Item;
    }
    return response;
  }

  async GetAddonUnfreezeDetails(req: any): Promise<any> {
    let response = null;
    const GetAddonUnfreezeDetails: any = await this.postData(
      `${this.apiUrlCreator(`Addon/GetUnFreezeDetails`)}`,
      req
    );
    if (GetAddonUnfreezeDetails?.ResponseCode === 100) {
      response = GetAddonUnfreezeDetails?.Item;
    }
    return response;
  }

  async addonUnFreeze(req: any): Promise<any> {
    const addonUnFreeze: any = await this.postData(
      `${this.apiUrlCreator(`Addon/Unfreeze`)}`,
      req
    );

    return addonUnFreeze;
  }

  async GetPlanUnFreezeDetails(req: any): Promise<any> {
    let response = null;
    const GetPlanUnFreezeDetails: any = await this.postData(
      `${this.apiUrlCreator(`MemberPlan/GetUnfreezeDetails`)}`,
      req
    );
    if (GetPlanUnFreezeDetails?.ResponseCode === 100) {
      response = GetPlanUnFreezeDetails?.Item;
    }
    return response;
  }
  async GetPlanWithdrawFreezeDetails(req: any): Promise<any> {
    let response = null;
    const GetPlanWithdrawFreezeDetails: any = await this.postData(
      `${this.apiUrlCreator(`MemberPlan/GetWithdrawFreezeDetails`)}`,
      req
    );
    if (GetPlanWithdrawFreezeDetails?.ResponseCode === 100) {
      response = GetPlanWithdrawFreezeDetails?.Item;
    }
    return response;
  }

  async CompletePlanFreeze(req: any): Promise<any> {
    const CompleteFreeze: any = await this.postData(
      `${this.apiUrlCreator(`MemberPlan/CompleteFreezeDetails`)}`,
      req
    );
    return CompleteFreeze;
  }

  async CompletePlanWithdrawFreeze(req: any): Promise<any> {
    const CompletePlanWithdrawFreeze: any = await this.postData(
      `${this.apiUrlCreator(`MemberPlan/WithDrawFreeze`)}`,
      req
    );
    return CompletePlanWithdrawFreeze;
  }

  async CompletePlanUnfreeze(req: any): Promise<any> {
    const CompletePlanUnfreeze: any = await this.postData(
      `${this.apiUrlCreator(`MemberPlan/Unfreeze`)}`,
      req
    );
    return CompletePlanUnfreeze;
  }

  async GetPlanActivationDetails(req: any): Promise<any> {
    let response = null;
    const GetPlanActivationDetails: any = await this.postData(
      `${this.apiUrlCreator(`MemberPlan/GetActivationDetails`)}`,
      req
    );
    if (GetPlanActivationDetails?.ResponseCode === 100) {
      response = GetPlanActivationDetails?.Item;
    }
    return response;
  }

  async CompletePlanActivation(req: any): Promise<any> {
    const CompletePlanActivation: any = await this.postData(
      `${this.apiUrlCreator(`MemberPlan/CompleteActivation`)}`,
      req
    );
    return CompletePlanActivation;
  }

  async UpdatePlanActivation(req: any): Promise<any> {
    const UpdatePlanActivation: any = await this.postData(
      `${this.apiUrlCreator(`MemberPlan/UpdateActivation`)}`,
      req
    );

    return UpdatePlanActivation;
  }
  async AddCouponToExistingPlan(req: any): Promise<any> {
    const AddCouponToExistingPlan: any = await this.postData(
      `${this.apiUrlCreator(`Coupon/AddCouponToExistingPlan`)}`,
      req
    );
    return AddCouponToExistingPlan;
  }

  async DeActivateMemberCoupon(req: any): Promise<any> {
    const DeActivateMemberCoupon: any = await this.postData(
      `${this.apiUrlCreator(`Coupon/DeActivateMemberCoupon`)}`,
      req
    );
    return DeActivateMemberCoupon;
  }

  async GetPlanRenewalDetails(req: any): Promise<any> {
    let response = null;
    const GetPlanRenewalDetails: any = await this.postData(
      `${this.apiUrlCreator(`MemberPlan/GetRenewalDetails`)}`,
      req
    );
    if (GetPlanRenewalDetails?.ResponseCode === 100) {
      response = GetPlanRenewalDetails?.Item;
    }
    return response;
  }

  async UpdateRenewalPIF(req: any): Promise<any> {
    const UpdateRenewalPIF: any = await this.postData(
      `${this.apiUrlCreator(`MemberPlan/UpdateRenewalPIF`)}`,
      req
    );
    return UpdateRenewalPIF;
  }

  async GetIndividualCancellationDetails(req: any): Promise<any> {
    let response = null;
    const getIndividualCancellationDetails: any = await this.postData(
      `${this.apiUrlCreator(`serviceinstance/GetCancellationDetails`)}`,
      req
    );
    if (getIndividualCancellationDetails?.ResponseCode === 100) {
      response = getIndividualCancellationDetails.Item;
    }
    return response;
  }
  async IndividualServiceCompleteCancellation(req: any): Promise<any> {
    const IndividualServiceCompleteCancellation: any = await this.postData(
      `${this.apiUrlCreator(`serviceinstance/CompleteCancellation`)}`,
      req
    );

    return IndividualServiceCompleteCancellation;
  }

  async EnablePlanRenewal(req: any): Promise<any> {
    const EnablePlanRenewal: any = await this.postData(
      `${this.apiUrlCreator(`MemberPlan/EnableRenewal`)}`,
      req
    );
    return EnablePlanRenewal;
  }

  async DisablePlanRenewal(req: any): Promise<any> {
    const DisablePlanRenewal: any = await this.postData(
      `${this.apiUrlCreator(`MemberPlan/DisableRenewal`)}`,
      req
    );
    return DisablePlanRenewal;
  }
  async GetPayNowTransaction(transac: any): Promise<any> {
    let response = null;
    const getpaynowtransdata: any = await this.getData(
      `${this.apiUrlCreator(
        `PaymentProfile/GetPayNowTransaction?paymentGatewayTransactionHistoryId=${transac}`
      )}`
    );

    if (getpaynowtransdata?.ResponseCode === 100) {
      response = getpaynowtransdata?.Item;
    }
    return response;
  }

  async GetPayNow(req: any): Promise<any> {
    const PayNow: any = await this.postData(
      `${this.apiUrlCreator(`PaymentProfile/PayNow`)}`,
      req
    );
    return PayNow;
  }
  async GetRefundTransaction(transac: any): Promise<any> {
    let response = null;

    const getrefuntransdata: any = await this.getData(
      `${this.apiUrlCreator(
        `PaymentProfile/GetRefundTransaction?paymentGatewayTransactionHistoryId=${transac}`
      )}`
    );

    if (getrefuntransdata?.ResponseCode === 100) {
      response = getrefuntransdata?.Item;
    }
    return response;
  }

  async SaveRefundTransaction(req: any): Promise<any> {
    const saverefundtrans: any = await this.postData(
      `${this.apiUrlCreator(`PaymentProfile/RefundTransaction`)}`,
      req
    );
    return saverefundtrans;
  }
  async GetTransactionsToVoid(transac: any): Promise<any> {
    const gettransdatavoid: any = await this.getData(
      `${this.apiUrlCreator(
        `PaymentProfile/GetTransactionsToVoid?transactionId=${transac}`
      )}`
    );
    return gettransdatavoid;
  }

  async VoidTransactions(req: any): Promise<any> {
    const voidtransaction: any = await this.postData(
      `${this.apiUrlCreator(`PaymentProfile/VoidTransactions`)}`,
      req
    );
    return voidtransaction;
  }

  async ParentPaymentTransactionHistoryId(transac: any,includeParentHistoryDetailsOnly:boolean=false): Promise<any> {
    let response = null;

    const parentPaymentTransactionHistoryId: any = await this.getData(
      `${this.apiUrlCreator(
        `Payment/transactionhistorydetails?parentPaymentTransactionHistoryId=${transac}&includeParentHistoryDetailsOnly=${includeParentHistoryDetailsOnly}`
      )}`
    );

    if (parentPaymentTransactionHistoryId?.ResponseCode === 100) {
      response = parentPaymentTransactionHistoryId?.Items;
    }
    return response;
  }

  async GetRelatedPaymentGatewayTransactionHistory(req: any): Promise<any> {
    let response = null;

    const getRelatedPaymentGatewayTransactionHistory: any = await this.postData(
      `${this.apiUrlCreator(`payment/Transactions`)}`,
      req
    );

    if (getRelatedPaymentGatewayTransactionHistory?.ResponseCode === 100) {
      response = getRelatedPaymentGatewayTransactionHistory?.Items;
    }
    return response;
  }

  async PaymentGatewayTransactionHistoryId(transacparent: any): Promise<any> {
    let response = null;

    const paymentGatewayTransactionHistoryId: any = await this.getData(
      `${this.apiUrlCreator(
        `PaymentProfile/IsPaymentSettled?paymentGatewayTransactionHistoryId=${transacparent}`
      )}`
    );
    if (paymentGatewayTransactionHistoryId?.ResponseCode === 100) {
      response = paymentGatewayTransactionHistoryId?.Item;
    }
    return response;
  }

  async serviceinstanceSignOff(req: any): Promise<any> {
    const serviceinstanceSignOff: any = await this.postData(
      `${this.apiUrlCreator(`serviceinstance/SignOff`)}`,
      req
    );
    return serviceinstanceSignOff;
  }


  async AvailablePlansToSubscribe(req: any): Promise<any> {
    const getServiceInstanceDetails: any = await this.postData(
      `${this.apiUrlCreator(`MembershipPlan/Filter`)}`,
      req
    );
    let response = [];
    if (getServiceInstanceDetails?.ResponseCode === 100) {
      response = getServiceInstanceDetails.Items;
    }
    return response;
  }

  async AvailableAddonsToSubscribe(req: any): Promise<any> {
    const getServiceInstanceDetails: any = await this.postData(
      `${this.apiUrlCreator(`Addon/Inventory`)}`,
      req
    );
    let response = [];
    if (getServiceInstanceDetails?.ResponseCode === 100) {
      response = getServiceInstanceDetails.Items;
    }
    return response;
  }

  async AvailableCouponsToApply(req: any): Promise<any> {
    const getServiceInstanceDetails: any = await this.postData(
      `${this.apiUrlCreator(`Coupon/GetSuitableCoupons`)}`,
      req
    );
    let response = [];
    if (getServiceInstanceDetails?.ResponseCode === 100) {
      response = getServiceInstanceDetails.Items;
    }
    return response;
  }

  async SignUpDetails(req: any): Promise<any> {
    const getServiceInstanceDetails: any = await this.postData(
      `${this.apiUrlCreator(`Subscription/GetSignUpDetails`)}`,
      req
    );
    let response = {};
    if (getServiceInstanceDetails?.ResponseCode === 100) {
      response = getServiceInstanceDetails.Item;
    }
    return response;
  }

  async SalesPerson(includeAffiliates: boolean): Promise<any> {
    const getServiceInstanceDetails: any = await this.postData(
      `${this.apiUrlCreator(`Associate/GetSalesPersons?includeAffiliates=${includeAffiliates}`)}`,
      {}
    );
    let response = {};
    if (
      getServiceInstanceDetails?.ResponseCode === 100 ||
      getServiceInstanceDetails?.ResponseCode === 0
    ) {
      response = getServiceInstanceDetails.Items;
    }
    return response;
  }

  async GetSubMembersWithParent(userid: any,includeInactive=false): Promise<any> {
    let response = [];

    const paymentGatewayTransactionHistoryId: any = await this.getData(
      `${this.apiUrlCreator(
        `client/GetSubMembersWithParent?userMemberId=${userid}&includeInactive=${includeInactive}`
      )}`
    );
    if (
      paymentGatewayTransactionHistoryId?.ResponseCode === 100 ||
      paymentGatewayTransactionHistoryId?.ResponseCode === 0
    ) {
      response = paymentGatewayTransactionHistoryId?.Items;
    }
    return response;
  }

  async CompleteSignUp(req: any): Promise<any> {
    const getCompletesignup: any = await this.postData(
      `${this.apiUrlCreator(`Subscription/CompleteSignUp`)}`,
      req
    );
    return getCompletesignup;
  }

  async BuildAgreement(req: any): Promise<any> {
    const buildaggrement: any = await this.postData(
      `${this.apiUrlCreator(`Subscription/BuildAgreement`)}`,
      req
    );
    return buildaggrement;
  }

  async getPlanDetails(req: any): Promise<any> {
    const getServiceInstanceDetails: any = await this.postData(
      `${this.apiUrlCreator(`MembershipPlan/GetById?membershipPlanId=${req}`)}`,
      req
    );
    let response = {};
    if (
      getServiceInstanceDetails?.ResponseCode === 100 ||
      getServiceInstanceDetails?.ResponseCode === 200
    ) {
      response = getServiceInstanceDetails.Item;
    }
    return response;
  }

  async UpdateStatusWithNotes(req: any): Promise<any> {
    const UpdateStatusWithNotes: any = await this.postData(
      `${this.apiUrlCreator(`serviceinstance/UpdateStatusWithNotes`)}`,
      req
    );
    return UpdateStatusWithNotes;
  }
  async GetSessionCost(req: any): Promise<any> {
    const GetSessionCost: any = await this.postData(
      `${this.apiUrlCreator(`service/SessionCost`)}`,
      req
    );
    return GetSessionCost;
  }

  async GetWaiverAgreement(userMemberId: any): Promise<any> {
    let response = null;

    const GetWaiverAgreement: any = await this.getData(
      `${this.apiUrlCreator(
        `Agreement/GetWaiverAgreement?userMemberId=${userMemberId}`
      )}`
    );
    response = GetWaiverAgreement?.Item;
    return response;
  }
  async SaveWaiverAgreement(req: any): Promise<any> {
    const GetWaiverAgreement: any = await this.postData(
      `${this.apiUrlCreator(`Agreement/SaveWaiverAgreement`)}`,
      req
    );
    return GetWaiverAgreement;
  }

  async UpdateRenewalMemberCoupon(req: any): Promise<any> {
    const UpdateRenewalMemberCoupon: any = await this.postData(
      `${this.apiUrlCreator(`Coupon/UpdateRenewal`)}`,
      req
    );
    return UpdateRenewalMemberCoupon;
  }

  async SubmitOnDemandPayment(req: any): Promise<any> {

    return await this.postData(
      `${this.apiUrlCreator(`payment/SubmitOnDemandPayment`)}`,
      req
    );
  }

  async GetDeclinedPayments(payload: any): Promise<any> {
    let response = null;

    const res: any = await this.postData(
      `${this.apiUrlCreator(
        `payment/DeclinedTransactions`
      )}`, payload || {
        "SearchKeyword": "",
        "SelectAll": true,
        "ServiceType": 0,
        "FilterFields": [],
        "PageNumber": 0,
        "PageSize": 10,
        "SortBy": "TotalAmount",
        "SortDirection": "desc"
    }
    );
    response = res?.Item;
    return response;
  }

  async GetUnusedPayments(payload: any): Promise<any> {
    let response = null;

    const res: any = await this.postData(
      `${this.apiUrlCreator(
        `PaymentProfile/unusedpaymentprofile`
      )}`, payload
    );
    response = res?.Item;
    return response;
  }


  async ChargeDeclinedPayments(payload: any): Promise<any> {
    let response = null;

    const res: any = await this.postData(
      `${this.apiUrlCreator(
        `payment/RunDeclinedTransactions`
      )}`, payload 
    );
    response = res?.Items;
    return response;
  }
  async GetUserNotifications(UserId: any): Promise<any> {
    let response = null;
    const getClientDetails: any = await this.getData(
      `${this.apiUrlCreator(`client/GetNotificationSubscription?userId=${UserId}`)}`
    );
    response = getClientDetails?.Item;
    return response;
  }

  async SaveNotificationSubscription(req: any): Promise<any> {
    const SaveNotificationSubscription: any = await this.postData(
      `${this.apiUrlCreator(`client/SaveNotificationSubscription`)}`,
      req
    );
    return SaveNotificationSubscription;
  }

  async addCreditsPaymentProfile(req: any): Promise<any> {
    const addCreditsPaymentProfile: any = await this.postData(
      `${this.apiUrlCreator(`PaymentProfile/addcredit`)}`,
      req
    );
    return addCreditsPaymentProfile;
  }

  async getClassAuditHistory(req: any): Promise<any> {
    const classAuditHistory: any = await this.postData(
      `${this.apiUrlCreator(`Class/ClassAudit`)}`,
      req
    );
    return classAuditHistory;
  }
  async serviceinstanceMultipleSignoff(req: any): Promise<any> {
    const serviceinstanceMultipleSignoff: any = await this.postData(
      `${this.apiUrlCreator(`serviceinstance/MultipleSignoff`)}`,
      req
    );
    return serviceinstanceMultipleSignoff;
  }

  async getProviderFilter(req: any): Promise<any> {
    const providerFilter: any = await this.postData(
      `${this.apiUrlCreator(`provider/filter`)}`,
      req
    );
    return providerFilter;
  }

  async getProviderAvailability(req: any): Promise<any> {
    console.log('Req',req);
    
    const providerFilter: any = await this.postData(
      `${this.apiUrlCreator(`provider/Availability`)}`,
      req
    );
    return providerFilter;
  }

  async saveWeekEvent(req: any): Promise<any> {
    const providerFilter: any = await this.postData(
      `${this.apiUrlCreator(`provider/SaveWeekEvent`)}`,
      req
    );
    return providerFilter;
  }

  async saveDayEvent(req: any): Promise<any> {
    const providerFilter: any = await this.postData(
      `${this.apiUrlCreator(`provider/SaveDayEventAvailablity`)}`,
      req
    );
    return providerFilter;
  }
  
  async getAllFormResponses(FormTemplateId:any): Promise<any> {
    const getAllFormResponses: any =  await this.getData(this.apiUrlCreator(`Forms/FormTemplate/downloadResponses/${FormTemplateId}`),{
      responseType: 'blob'
    });
    return getAllFormResponses;
  }
  async addTag(req: any): Promise<any> {
    const res: any = await this.postData(
      `${this.apiUrlCreator(`tag`)}`,
      req
    );
    return res;
  }
  // GET /api/tag/filter
  async getTags(): Promise<any> {
    let response = null;
    const res: any = await this.getData(
      `${this.apiUrlCreator(`tag/filter`)}`
    );
    response = res?.Items;
    return response;
  }

  async getCategory(type:number): Promise<any> {
    let response = null;
    const res: any = await this.getData(
      `${this.apiUrlCreator(`productcategory/filter?type=${type}`)}`
    );
    response = res?.Items;
    return response;
  }
  async getPaymentReferenceDocDownload(FileName:string): Promise<any> {
    const getPaymentReferenceDocDownload: any =  await this.getData(this.apiUrlCreator(`payment/Download?fileName=${FileName}`),{
      responseType: 'blob'
    });
    return getPaymentReferenceDocDownload;
  }
  async UpdatePlanRate(req: any): Promise<any> {
    const UpdatePlan: any = await this.postData(
      `${this.apiUrlCreator(`MemberPlan/UpdatePlan`)}`,
      req
    );

    return UpdatePlan;
  }
}
