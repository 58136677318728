import { Dialog } from "@progress/kendo-react-dialogs";
import React from "react";
import {
  checkIfUserAddPaymentGateway,
  checkIfUserCanAddBankAccount,
  paymentDropdownItems,
} from "utils";
import PaymentModalCreation from "components/PaymentCheckout/PaymentModalCreation";
import BuzopsLoader from "generic-components/BuzopsLoader";
import BuzopsButton from "generic-components/BuzopsButton";
import { Button, DropDownButton } from "@progress/kendo-react-buttons";
import { Input, NumericTextBox } from "@progress/kendo-react-inputs";
import { Error, Label } from "@progress/kendo-react-labels";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import {
  PaymentItemRender,
  PaymentValueRender,
} from "components/Payment/PaymentRenderItems";
import withNotification from "components/Hoc/withNotification";
import usePaymentProfile from "./PaymentProfile.hook";
import { Upload } from "@progress/kendo-react-upload";

const PaymentProfile = (props: any) => {
  const {
    handleSuccessClosePaymethodDialog,
    handleClosePaymethodDialog,
    handleChangeSavedCard,
    onCardOrBankAdd,
    addNewItem,
    loading,
    formik,
    paymentProfile,
    selectedPaymentProfile,
    showPaymentDialog,
    originalFiles,
    onAdd,
    onRemove,
    setRefNumber,
    refNumber,
    files,
  } = usePaymentProfile(props);
  const PaymethodDialog = () => {
    return (
      <>
        <Dialog
          className="bz-addCard-dialog"
          title={`${
            addNewItem !== "addOther"
              ? checkIfUserCanAddBankAccount()
                ? "Add Bank or Card"
                : "Add Card"
              : "Add Payment Mode"
          }`}
          onClose={() => handleClosePaymethodDialog()}
          style={{ zIndex: 9999 }}
        >
          <PaymentModalCreation
            staffPage={true}
            addNewItem={addNewItem}
            handleClosePaymethodDialog={handleClosePaymethodDialog}
            handleSuccessClosePaymethodDialog={
              handleSuccessClosePaymethodDialog
            }
            UserMemberId={props?.UserMemberId}
          />
        </Dialog>
      </>
    );
  };

  if (loading) {
    return <BuzopsLoader />;
  }

  return (
    <>
      <div className="bz-mt-2rem bz-mb-2_5rem pymentprfpadding">
        <form onSubmit={formik.handleSubmit}>
          <div className="payment-profile d-flex">
            <div className="paymentdropdown">
              <Label>Payment Profile</Label>
              <DropDownList
                // width={280}

                id={"selectedPaymentProfile"}
                name={"selectedPaymentProfile"}
                data={paymentProfile}
                listNoDataRender={() => "No Payment Methods"}
                textField="MaskedCCNumber"
                dataItemKey="PaymentProfileID"
                // className={"form-control col-md-5"}
                valueRender={(
                  el: React.ReactElement<HTMLSpanElement>,
                  value: any
                ) => PaymentValueRender(el, value, selectedPaymentProfile)}
                itemRender={PaymentItemRender}
                key={`${selectedPaymentProfile?.PaymentGatewayPaymentProfileID}`}
                onChange={(e) => {
                  handleChangeSavedCard(e.value, {
                    Reference: refNumber,
                    ReferrenceDocument: files?.[0] || null,
                  });
                  formik.setFieldValue(
                    "PaymentGatewayPaymentProfileID",
                    e.value?.PaymentGatewayPaymentProfileID
                  );
                }}
                style={{ width: "100%", maxWidth: "280px" }}
              />
            </div>
            {checkIfUserAddPaymentGateway() && (
              <div className="addNewBtn align-self-end">
                <DropDownButton
                  text="Add New"
                  onItemClick={(e: any) => onCardOrBankAdd(e)}
                  items={paymentDropdownItems()?.filter(
                    (i: any) => i?.visible === true
                  )}
                  look="flat"
                  className="btn btn-link add-new-btn"
                />
              </div>
            )}
          </div>
          {selectedPaymentProfile?.CardTypeId === -2 && (
            <>
              <div className="d-flex pt-2">
                <div className="paymentdropdown">
                  <Label>Transaction Reference Number</Label>
                  <Input
                    onChange={(event) => {
                      handleChangeSavedCard(selectedPaymentProfile, {
                        Reference: event.target.value,
                        ReferrenceDocument: files?.[0],
                      });
                      setRefNumber(event.target.value);
                    }}
                    value={refNumber}
                    type={"text"}
                    style={{ width: "320px" }}
                    id={"refNumber"}
                  />
                </div>
              </div>
              <div className="d-flex flex-column mt-2">
                <Label>Reference Document</Label>
                <div className="paymentdropdown">
                  {/* <input type="file" /> */}
                  <Upload
                    autoUpload={false}
                    batch={false}
                    restrictions={{
                      // allowedExtensions: [".jpg", ".png", ".jpeg"],
                      maxFileSize: 1000000,
                    }}
                    showActionButtons={false}
                    multiple={false}
                    onAdd={onAdd}
                    withCredentials={false}
                    onRemove={onRemove}
                    files={originalFiles}
                  />
                </div>
              </div>
            </>
          )}
        </form>
      </div>
      {showPaymentDialog && PaymethodDialog()}
    </>
  );
};

export default withNotification(PaymentProfile);
