import { filterBy } from "@progress/kendo-data-query";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import useState from 'react-usestateref';
import { useHistory } from "react-router-dom";
import { ClassService } from "services/class/index.service";
import { PackageService } from "services/package/index.service";
import { TenantService } from "services/tenant/index.service";
import { CheckUserMemberStatus, convertToTimezone, getFullDate } from "utils";
import { GetLocalStore, LocalStore, RemoveLocalStore } from "utils/storage";
import { useSelector } from "react-redux";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import copy from "copy-to-clipboard";

const usePurchaseClassList = (props: any) => {
  const history = useHistory();
  const domainName = process.env.REACT_APP_DOMAIN;
  const tenantDomainName = GetLocalStore("tenantUserSession")?.DomainName;
  const redirectionObj = useSelector((state: any) => state?.redirection);
  const showMoreRef = useRef<any>();
  const [PackagesData, setPackagesData, packagesDataRef] = useState<any>([]);
  const [AllClientsList, setAllClientsList] = useState<any>([]);
  const [loginStatus, setLoginStatus] = useState(false);
  const [confirmClientDialog, setConfirmClientDialog] = useState(false);
  const [classSessionsList, setclassSessionsList] = useState<any>({
    pageNumber: 0,
    maxPageNumber: 0,
    TotalItems: 0,
    startDate: moment().format("YYYY-MM-DD"),
    classSessionData: [],
  });
  const [expandedItem, setExpandItem] = React.useState(null);
  const [usePackageFilter, setUsePackageFilter] = React.useState(true);
  //client details
  const [clientFormValues, setClientFormValues] = useState<any>({});
  const [clientSubmitDetails, setClientSubmitDetails, clientSubmitDetailsRef] = useState<any>({});
  const userDetails = GetLocalStore("userDetails");
  const isLoggedIn = userDetails ? true : false;
  const [isClassLoading, setIsClassLoading] = useState(true);
  const [pageLoading, setPageLoading] = useState(false);
  const [selectedClasses, setSelectedClasses] = useState<any>(
    props?.selectedClasses ? props?.selectedClasses : []
  );
  const [classSelectedUserMemberId, setClassSelectedUserMemberId] =
    useState<any>(
      props?.classSelectedUserMemberId ? props?.classSelectedUserMemberId : null
    );
  const [classesList, setClassesList] = useState<any>([]);
  const [originalClassList, setOriginalClassList] = useState<any>([]);
  const currentDate=moment().subtract(1, "days").format("YYYY-MM-DD") 
  let date= props?.packageBillingPeriod?.ExpireOn 
  if(moment(props?.packageBillingPeriod?.ExpireOn).format("YYYY-MM-DD") > moment().format("YYYY-MM-DD")){
    date= moment().format("YYYY-MM-DD")
  }
  if(!date){
    date= moment().format("YYYY-MM-DD")
  }
  const initialFilterValues = {
    class: props?.selectedParentClass
      ? props?.selectedParentClass
      : { ClassId: 0, Name: "All Group" },
    fromDate:props?.SessionSignOff ? new Date(date) : !props.staffPage ? convertToTimezone(new Date(),true): new Date(),
  };
  const [filterContent, setFilterContent, filterContentRef] = useState<any>(initialFilterValues);

  const [take, setTake] = useState(3);
  const [selectedPackage, setSelectedPackage, selectedPackageRef] = useState<any>(props?.selectedPackage || null);
  const updatedData = props?.selectedPackage?.BillingPeriods?.length > 0
  ? props?.selectedPackage?.BillingPeriods.map((val: any) => ({ ...val, redeemableSchedules: val?.SchedulesToUse }))
  : null;
  const [packageBillingCycle, setPackageBillingCycle, packageBillingCycleRef] = useState<any>(updatedData || null);
  const [confirmCartClearDialog, setConfirmCartClearDialog] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
const initialPickPackageDialog={
  showDialog:false,
  selectedGroup:null,
  selectedPackage:null
}
  const [pickPackageDialog,setPickPackageDialog,pickPackageDialogRef]=useState<any>(initialPickPackageDialog)
 const [popoverIndex, setPopoverIndex] = useState<number | null>(null);
   
  useEffect(() => {
    fetchAllApis();
  }, []);

  const fetchAllApis=async()=> {
    // await fetchAllSubmembers()
    // await fetchPackageSummary(true)
    // await fetchClassSessions(true,undefined,true);
    // if (!props?.classShortName || props?.classShortName === null) {
    //   await fetchClassNames();
    // }

    const fetchPromises = [
      fetchAllSubmembers(),
      fetchPackageSummary(true),
      fetchClassSessions(true, undefined, true),
    ];
  
    if (!props?.classShortName) {
      fetchPromises.push(fetchClassNames());
    }
  
    await Promise.all(fetchPromises);

  }

  
  useEffect(() => {
    if(props?.selectedPackage){
      fetchClassSessions(true,props?.selectedPackage,true)
      setSelectedPackage(props?.selectedPackage);
      setBillingPeriods(props?.selectedPackage?.BillingPeriods);
    }else{
      setSelectedPackage(undefined);
      setBillingPeriods(undefined);
      // fetchPackageSummary(true);
    }
 
    handleClearAllSlots();
    setConfirmCartClearDialog(false);
    // fetchClassSessions(true,undefined,true);
    // if (!props?.classShortName || props?.classShortName === null) {
    //   fetchClassNames();
    // }
    
  }, [props?.selectedPackage,props?.userData]);

  useEffect(() => {
    if(props?.selectedClasses && props?.selectedClasses.length > 0){
      setSelectedClasses(props?.selectedClasses);
    }
  }, [props?.selectedClasses])
  
  const toggleClientConfirmDialog = () => {
    setConfirmClientDialog(!confirmClientDialog);
  };
  const handleUsePackageFilter = (val: boolean) => {
    setUsePackageFilter(val);
  }

  const handleExpand = (item: any) => {
    setExpandItem(item.ClassSessionId);
  };
  const handleCollapse = () => {
    setExpandItem(null);
  };
  const getPackageWithMinExpireOn = (packages: any[]) => 
    packages.reduce((min, item) => (new Date(min.ExpireOn) > new Date(item.ExpireOn) ? item : min), packages[0]);

  const CheckExactPackageItem = (packageList: any) => {
    const UnlimitedPackages = packageList.filter((i: any) => i?.SessionType === 2);
    const LimitedPackages = packageList.filter((i: any) => i?.SessionType === 1 && i?.SchedulesToUse > 0);
  
    if (UnlimitedPackages.length === 1) return UnlimitedPackages[0];
  
    if (UnlimitedPackages.length > 1) return getPackageWithMinExpireOn(UnlimitedPackages);
  
    if (LimitedPackages.length > 0) return getPackageWithMinExpireOn(LimitedPackages);
  
    return null;
  };
  
// Utility to collect all sessions from selectedClasses
const collectSessions = () => {
  return selectedClasses.flatMap((j: any) => j?.values || []);
};

const checkSkipPayment = (sessionId: any) => {
  const sessions = collectSessions();
  return sessions.some(
    (session: any) =>
      session?.ClassSessionId === sessionId &&
      (session?.PaymentOption === 2 || session?.PaymentOption === 3 || session?.ClassType !== 1 || session?.Cost <= 0)
  );
};

const checkZeroDollar = () => {
  const sessions = collectSessions();
  return sessions.every(
    (session: any) => session?.ClassType !== 1 || session?.Cost <= 0
  );
};

const checkCompleteReedemViaPackage = () => {
  const sessions = collectSessions();
  return sessions.every(
    (session: any) => session?.SelectedPackage && usePackageFilter
  );
};

  const handleEnrollWithoutCheckout = async() => {
    let ClassSessionDetails: any = [];
    selectedClasses.forEach((j: any) => {
      j?.values.forEach((k: any) => {
        const checkSkipPaymentStatus = checkSkipPayment(k?.ClassSessionId);
        const {
          ClassId,
          ClassSessionId,
          ClassType,
          Cost,
          CanBookAsWaitlist,
          SelectedPackage = null,
          ClassSessionStartDate,
          billingCycle=null
        } = k;
        if (SelectedPackage) {
          const { PackageInstanceId, Name, BillingPeriods } = SelectedPackage;
          const checkPayBy =
            checkSkipPaymentStatus && (ClassType !== 1 || Cost <= 0);
          let BillingPeriodFrom = undefined;
          // if (!checkPayBy) {
              const billingPeriods = BillingPeriods?.find((i: any) => {
                const expireFrom = moment(i?.ExpireFrom).format("YYYY-MM-DD");
                const expireOn = moment(i?.ExpireOn).format("YYYY-MM-DD");
                const StartDateTime = moment(ClassSessionStartDate).format(
                  "YYYY-MM-DD"
                );
                if (StartDateTime >= expireFrom && StartDateTime <= expireOn) {
                  return true;
                }
                return false;
              });
              BillingPeriodFrom = billingPeriods?.ExpireFrom;
              if(props?.SessionSignOff){
                BillingPeriodFrom = billingCycle?.ExpireFrom;
              }
          // }
          ClassSessionDetails.push({
            ClassId,
            ClassSessionId,
            EntityName: Name,
            // PayBy: checkPayBy ? 3 : 1,
            // PaymentSourceId: checkPayBy ? undefined : PackageInstanceId,
            PayBy:1,
            PaymentSourceId: PackageInstanceId,
            CanBookAsWaitlist,
            BillingPeriodFrom,
            IsQuickSignOff: props?.SessionSignOff ? true : undefined
          });
        } else if (selectedPackage) {
          const { PackageInstanceId, Name, BillingPeriods } = selectedPackage;
          const checkPayBy =
            checkSkipPaymentStatus && (ClassType !== 1 || Cost <= 0);
          let BillingPeriodFrom = undefined;
          // if (!checkPayBy) {
            const billingPeriods = BillingPeriods?.find((i: any) => {
              const expireFrom = moment(i?.ExpireFrom).format("YYYY-MM-DD");
              const expireOn = moment(i?.ExpireOn).format("YYYY-MM-DD");
              const StartDateTime = moment(ClassSessionStartDate).format(
                "YYYY-MM-DD"
              );
              if (StartDateTime >= expireFrom && StartDateTime <= expireOn) {
                return true;
              }
              return false;
            });
            BillingPeriodFrom = billingPeriods?.ExpireFrom;
            if(props?.SessionSignOff){
              BillingPeriodFrom = billingCycle?.ExpireFrom;
            }
          // }
          ClassSessionDetails.push({
            ClassId,
            ClassSessionId,
            EntityName: Name,
            PayBy:1,
            PaymentSourceId: PackageInstanceId,
            // PayBy:
            //   checkSkipPaymentStatus && (ClassType !== 1 || Cost <= 0) ? 3 : 1,
            // PaymentSourceId:
            //   checkSkipPaymentStatus && (ClassType !== 1 || Cost <= 0)
            //     ? undefined
            //     : PackageInstanceId,
            CanBookAsWaitlist,
            BillingPeriodFrom,
            IsQuickSignOff: props?.SessionSignOff ? true : undefined
          });
        } else if (props?.selectedPackage) {
          const { PackageInstanceId, Name, BillingPeriods } =
            props?.selectedPackage;
          const checkPayBy =
            checkSkipPaymentStatus && (ClassType !== 1 || Cost <= 0);
          let BillingPeriodFrom = undefined;
          // if (!checkPayBy) {
            const billingPeriods = BillingPeriods?.find((i: any) => {
              const expireFrom = moment(i?.ExpireFrom).format("YYYY-MM-DD");
              const expireOn = moment(i?.ExpireOn).format("YYYY-MM-DD");
              const StartDateTime = moment(ClassSessionStartDate).format(
                "YYYY-MM-DD"
              );
              if (StartDateTime >= expireFrom && StartDateTime <= expireOn) {
                return true;
              }
              return false;
            });
            BillingPeriodFrom = billingPeriods?.ExpireFrom;
            if(props?.SessionSignOff){
              BillingPeriodFrom = billingCycle?.ExpireFrom;
            }
          // }
          ClassSessionDetails.push({
            ClassId,
            ClassSessionId,
            EntityName: Name,
            PayBy:1,
            PaymentSourceId: PackageInstanceId,
            // PayBy:
            //   checkSkipPaymentStatus && (ClassType !== 1 || Cost <= 0) ? 3 : 1,
            // PaymentSourceId:
            //   checkSkipPaymentStatus && (ClassType !== 1 || Cost <= 0)
            //     ? undefined
            //     : PackageInstanceId,
            CanBookAsWaitlist,
            BillingPeriodFrom,
            IsQuickSignOff: props?.SessionSignOff ? true : undefined
          });
        } else if (k?.Complimentary) {
          ClassSessionDetails.push({
            ClassId,
            ClassSessionId,
            PayBy: 5,
            CanBookAsWaitlist,
            IsQuickSignOff: props?.SessionSignOff ? true : undefined
          });
        } else if (checkSkipPaymentStatus) {
          if (ClassType !== 1 || Cost <= 0) {
            ClassSessionDetails.push({
              ClassId,
              ClassSessionId,
              PayBy: 3,
              CanBookAsWaitlist,
              IsQuickSignOff: props?.SessionSignOff ? true : undefined
            });
          } else {
            ClassSessionDetails.push({
              ClassId,
              ClassSessionId,
              PayBy: 2,
              CanBookAsWaitlist,
              IsQuickSignOff: props?.SessionSignOff ? true : undefined
            });
          }
        } else {
          ClassSessionDetails.push({
            ClassId,
            ClassSessionId,
            PayBy: 0,
            CanBookAsWaitlist,
            IsQuickSignOff: props?.SessionSignOff ? true : undefined
          });
        }
      });
    });
    let req: any = {
      DownPayment: 0,
      Client: props?.from ==="calender" ?props?.userData : clientSubmitDetails,
      ClassSessionDetails,
      HasTermsAndConditionAccepted: true,
    };
    req = { ...req };
    const checkoutClass = new ClassService();
    try {
      setBtnLoading(true);
      const result = await checkoutClass.purchaseClass(req);
      setBtnLoading(false);

      if (result?.ResponseCode === 100) {
        setSelectedClasses([]);
        setConfirmClientDialog(false);
        handleClearAllSlots();
        await fetchPackageSummary(true);
        if(props?.selectedPackage){
          await fetchClassSessions(true,props?.selectedPackage,true)
        }else{
          await fetchClassSessions(true,undefined,true)
        }
        props?.handleNotificationMessage("Sessions Enrolled Successfully", "success");
      } else {
        const errorMsg = result?.ErrorMessages?.[0];
        props?.handleNotificationMessage(errorMsg, "error");
      }
      setBtnLoading(false);
    } catch (e) {
      console.log(e);
    }
  }
  const handleGroupCheckout = (obj: any) => {
    if(selectedPackage || props?.selectedPackage || checkCompleteReedemViaPackage()){
      handleEnrollWithoutCheckout();
      
      return true;
    }
    if(props?.from==="calender"){
      props?.handleGroupCheckoutProps(obj);
      return true;
    }
    if(props?.staffPage){
      if(checkZeroDollar()){
        handleEnrollWithoutCheckout();
        return
      }
    }
    const { firstPathValue, staffuuid, clientMemberId } = redirectionObj;
    if (firstPathValue === "member") {
      history.push(
        `/${firstPathValue}/${staffuuid}/${clientMemberId}/checkout`,
        obj
      );
    } else if (firstPathValue === "external") {
      history.push(`/${firstPathValue}/checkout`, obj);
    } else {
      history.push(`/checkout`, obj);
    }
  };

  const handlePageChangePackage = (status = "more") => {
    if (status === "less") {
      setTake(3);
    } else {
      setTake(take + 4);
    }
  };

  const fetchAllSubmembers = () => {
    if(props?.from==="calender"){
      return
    }
    let memberId;
    if (props?.pickpackage && props?.page === "inhouse") {
      memberId = props?.packageData?.UserMemberId;
    }
    if (props?.userMemberId && props?.page === "inhouse") {
      memberId = props?.userMemberId;
    }
    if (props?.userData) {
      memberId = props?.userData?.UserMemberId;
    }
    if (props?.selectedPackage && props?.selectedPackage?.MemberType === 0) {
      memberId = props?.selectedPackage?.UserMemberId;
    }
    if (memberId) {
      getAllSubMembers(memberId);
    }
    // else{
    //   fetchClassSessions(true)
    //   fetchClassNames()
    // }
  };

  const setSelectedPackageData = () => {
      if(props?.selectedPackage){
        const data = packagesDataRef?.current.find((p:any) => parseInt(p.PackageInstanceId) === parseInt(props?.selectedPackage?.PackageInstanceId));
        setSelectedPackage(data);
        setBillingPeriods(data?.BillingPeriods);
      }else if(selectedPackageRef?.current){
        const data = packagesDataRef?.current.find((p:any) => p.PackageInstanceId === selectedPackageRef?.current?.PackageInstanceId);
        setSelectedPackage(data);
        setBillingPeriods(data?.BillingPeriods);
      }else {
        const data = CheckExactPackageItem(packagesDataRef?.current);
        setSelectedPackage(data);
        setBillingPeriods(data?.BillingPeriods);
      }
      if(selectedPackageRef?.current){
        fetchClassSessions(true,selectedPackageRef?.current,false)
      }
  }
  const fetchPackageSummary = async (init = false) => {
    let memberId;
    if (clientSubmitDetailsRef?.current?.UserMemberId) {
      memberId = clientSubmitDetailsRef?.current?.UserMemberId;
    } else {
      if (props?.pickpackage && props?.page === "inhouse") {
        memberId = props?.packageData?.UserMemberId;
      }
      if (props?.userData) {
        memberId = props?.userData?.UserMemberId;
      }
    }
    let startDate =
      moment(filterContentRef?.current?.fromDate).format("YYYY-MM-DD") + "T00:00:00";
    let ToDate = moment().add(1, "year").format("YYYY-MM-DD") + "T23:59:59";
    if(props?.selectedPackage){
      startDate =
      moment(props?.selectedPackage?.StartOn).format("YYYY-MM-DD") + "T00:00:00";
      ToDate =
      moment(props?.selectedPackage?.StartOn).add(1, "year").format("YYYY-MM-DD") + "T23:59:59";
      if(props?.selectedPackage?.UserMemberId){
        memberId=props?.selectedPackage?.UserMemberId
      }
    }
    const req:any = {
      UserMemberId: memberId,
      FromDate: startDate,
      ToDate,
      PackageType:"Class",
      IsQuickSignOff:props?.SessionSignOff ? true : undefined
    };
    if (memberId) {
      const service = new PackageService();
      const result = await service.packageInstanceToUse(req);
      setPackagesData(result || []);
      if(init){
        if((!props?.selectedClasses || props?.selectedClasses?.length===0) && props?.from !=="calender") {
          setSelectedPackageData();
        }
        else if(props?.from ==="calender" && props?.SessionSignOff && !props?.purchaseGroup && !props?.showOnlySignoffAppts){
          setSelectedPackageData();
        }

      }

    }
  };

  const CheckValidPackages = (List: any, j: any) => {
    return List.filter((i: any) => {
      const isValidClass = i.ClassIds.includes(j?.ClassId) || i.ClassIds.includes(-1);
      if (isValidClass && j?.Cost > 0 && i.BillingPeriods?.length > 0) {
        const isValidBillingPeriod = i.BillingPeriods.some((bp: any) => 
          moment(j?.ClassSessionStartDate).isBetween(bp?.ExpireFrom, bp?.ExpireOn, 'day', '[]') &&
          bp?.SchedulesToUse > 0
        );
        return isValidBillingPeriod;
      }
      return false;
    }) || [];
  };
  

  const constructClassListForDate = (classList: any, initialCall: boolean) => {
    let oldClassData = initialCall ? [] : [...classSessionsList.classSessionData];
    if (!classList) return oldClassData;
  
    const currentTime = moment().format();
    const currentDateTime = !props.staffPage ? convertToTimezone(new Date(currentTime)) : currentTime;
  
    classList.forEach((val: any) => {
      let { MinimumBookingDurationType = "minutes", MinimumBookingDuration } = val;

      if (!props.staffPage) {
        MinimumBookingDurationType = ["hours", "minutes", "days"][val?.MinimumBookingDurationType - 1] || "minutes";
      } else {
        MinimumBookingDuration = 0; 
      }

  
      const MimimumBookingBefore = moment(val?.ClassSessionStartDate)
        .subtract(MinimumBookingDuration, MinimumBookingDurationType)
        .toISOString();
      const MimimumBookingBeforeTime = moment(MimimumBookingBefore).format("YYYY-MM-DDTHH:mm:ss");
      if (currentDateTime <= MimimumBookingBeforeTime || props?.SessionSignOff) {
        const record = {
          ...val,
          CanBookAsWaitlist: val.Slot - val.BookedSlot === 0 && val.HasWaitList && val.WaitList - val.WaitingSlot > 0,
          PackagesList: CheckValidPackages(packagesDataRef?.current, val)
        };
  
        const classIndex = oldClassData.findIndex(
          (item: any) => item.date === getFullDate(record?.ClassSessionStartDate)
        );
  
        if (classIndex !== -1) {
          oldClassData[classIndex].values.push(record);
          oldClassData[classIndex].values.sort((a: any, b: any) => {
            if(props?.SessionSignOff){
              return moment(a.ClassSessionStartDate).isAfter(b.ClassSessionStartDate) ? -1 : 1
            }else{
              return moment(a.ClassSessionStartDate).isAfter(b.ClassSessionStartDate) ? 1 : -1
            }
          }
          );
        } else {
          oldClassData.push({
            date: getFullDate(record?.ClassSessionStartDate),
            values: [record],
          });
        }
      }
    });
  
    return oldClassData.sort((a: any, b: any) => {
      if(props?.SessionSignOff){

        return moment(a.date).isBefore(b.date) ? 1 : -1
      }else{

        return moment(a.date).isBefore(b.date) ? -1 : 1
      }
    }
    );
  };
  

  const fetchClassNames = async () => {
    const service = new ClassService();
    let req = null;
    if (props?.pickpackage && props?.page === "inhouse") {
      req = {
        packageInstanceId: props?.packageData?.PackageInstanceId,
      };
    }
    if (selectedPackageRef?.current) {
      req = {
        packageInstanceId: selectedPackageRef?.current?.PackageInstanceId
      }
    }
    const result = await service.getClassNames(req);
    const res = [...result];
    const test = { ClassId: 0, Name: "All Group" };
    res.unshift(test);
    setClassesList(res);
    setOriginalClassList(res);
  };

  const ClassNamefilterChange = (e: any) => {
    const filteredData = classNameFilterData(e.filter);
    setClassesList(filteredData);
  };

  const classNameFilterData = (filter: any) => {
    const data = originalClassList.slice();
    return filterBy(data, filter);
  };

  const constructSelectedClassListForClass = (classList: any) => {
    if (!classList) return selectedClasses;
  
    const updatedClasses = [...selectedClasses];
    const classIndex = updatedClasses.findIndex(
      (cls) => cls?.className === classList?.ClassName
    );
  
    if (classIndex !== -1) {
      const values = updatedClasses[classIndex].values;
      const classValueIndex = values.findIndex(
        (val:any) => val?.ClassSessionId === classList?.ClassSessionId
      );
  
      if (classValueIndex !== -1) {
        values.splice(classValueIndex, 1);
        if (values.length === 0) {
          updatedClasses.splice(classIndex, 1);
        }
      } else {
        values.push(classList);
        values.sort((a:any, b:any) =>
          moment(a?.ClassSessionStartDate).isAfter(b?.ClassSessionStartDate) ? 1 : -1
        );
      }
    } else {
      updatedClasses.push({
        className: classList?.ClassName,
        CanCollectTaxes: classList?.CanCollectTaxes,
        ClassId: classList?.ClassId,
        values: [classList],
      });
    }
  
    return updatedClasses;
  };
  

  const getCurrentClientData = (allUsersData: any, userMemberId: any) => {
    return (
      allUsersData?.find((val: any) => val.UserMemberId === userMemberId) || {}
    );
  };

  const handleClientSelection = async (SelectedUser: any) => {
    setIsClassLoading(true)
    await updateSelectedClientData(SelectedUser,false);
    handleClearAllSlots();
    setIsClassLoading(false)
  };

  async function updateSelectedClientData(userData: any,init=true) {
    const result = userData;
    if (result) {
      const clientIntialValues = {
        FirstName: result?.FirstName,
        LastName: result?.LastName,
        PhoneNumber: result?.Phone,
        Email: result?.Email,
        UserMemberId: result?.UserMemberId,
      };
      const clientSubmitDetails = {
        Email: result?.Email,
        FirstName: result?.FirstName,
        Photo: result?.Photo,
        LastName: result?.LastName,
        FullName: result?.FullName,
        PrimaryMemberId: result?.ParentId || result?.UserMemberId,
        UserId: result?.UserId,
        UserMemberId: result?.UserMemberId,
        MemberNumber: result?.MemberNumber,
        MemberType: result?.MemberType,
        Status: result?.UserMemberStatus || result?.Status,
      };
      setClientFormValues(clientIntialValues);
      setClassSelectedUserMemberId(result?.UserMemberId);
      setClientSubmitDetails(clientSubmitDetails);
      if(!init){
        setSelectedPackage(undefined)
        await fetchPackageSummary(true);
      }
    }
  }

  const getAllSubMembers = async (memberId: any) => {
    const tenantService = new TenantService();
    const res = await tenantService.GetSubMembersWithParent(memberId);
    setAllClientsList(res);
    let Id = memberId;
    if (!props.staffPage && userDetails) {
      Id = parseInt(
        userDetails?.UserSession?.AdditionalAttributes?.LogedInMemberId ||
          memberId
      );
    }
    if (classSelectedUserMemberId) {
      Id = classSelectedUserMemberId;
    }
    if(props?.selectedPackage){
      Id = props?.selectedPackage?.UserMemberId;
    }
    await updateSelectedClientData(getCurrentClientData(res, Id),true);
    if(classSelectedUserMemberId){
      fetchPackageSummary(true)
    }
  };

  async function fetchClassSessions(intialCall = true, SelectedPackageData = selectedPackageRef?.current,filteredDate=false) {
    const service = new ClassService();
    let startDate = moment(filterContentRef?.current?.fromDate).format("YYYY-MM-DD");
    if (!intialCall) {
      startDate = moment(classSessionsList?.startDate)
        .add(1, "day")
        .format("YYYY-MM-DD");
    }
    let FromDate = moment(startDate).format("YYYY-MM-DD") + "T00:00:00";
    let check=false
    if (startDate === moment().format("YYYY-MM-DD")) {
      FromDate = moment().format("YYYY-MM-DDTHH:mm:ss");
      check=true
    }
    let ToDate =
      moment(startDate).add(9, "days").format("YYYY-MM-DD") + "T23:59:59";
    let ClassId = 0;
    if (filterContentRef?.current?.class !== "" && filterContentRef?.current?.class !== "All Group") {
      ClassId = filterContentRef?.current.class?.ClassId;
    }
    if((!filteredDate || check) && !props.staffPage){
      FromDate=convertToTimezone(new Date(FromDate))
      ToDate=convertToTimezone(new Date(ToDate))
    }

    let req:any = {
      FromDate: FromDate,
      // ToDate: ToDate,
      SortDirection: "ASC",
      IncludeInActive: false,
      ClassId: ClassId,
      SortBy: "ClassSessionStartDate",
      PageSize:100
    };
    if(props?.SessionSignOff){
      const currentDate=moment().format("YYYY-MM-DD")
      let ToDateValue =
      moment(currentDate).subtract(1, "days").format("YYYY-MM-DD") + "T23:59:59";
      if(moment(startDate).format("YYYY-MM-DD")===moment(props?.packageBillingPeriod?.ExpireOn).format("YYYY-MM-DD")){
        ToDateValue =
        moment(props?.packageBillingPeriod?.ExpireOn).subtract(1,'days').format("YYYY-MM-DD") + "T23:59:59";
      }else{
        ToDateValue =
        moment(startDate).subtract(1, "days").format("YYYY-MM-DD") + "T23:59:59";
      }
      if(!filteredDate){
        ToDateValue =
        moment(currentDate).subtract(1, "days").format("YYYY-MM-DD") + "T23:59:59";
      }
      req={
        FromDate:  undefined,
        ToDate:   ToDateValue,
        SortDirection: "DESC",
        ClassSessionStatus: "Active",
        ClassId: ClassId,
        SortBy: "ClassSessionStartDate",
        PageSize:100
      };
    }
    const newObj: any = new Object();

    if (props.staffPage) {
      newObj.CanClientBookViaOnline = false;
    } else {
      newObj.CanClientBookViaOnline = true;
    }
    if (props?.providerName) {
      newObj.UserAssociateId = props?.provider?.AssociateId;
    }
    if (props?.classShortName) {
      newObj.CanClientBookViaOnline = false;
      newObj.ShortCode = props?.classShortName;
    }
    if (props?.classSessionId) {
      newObj.ClassSessionId = parseInt(props?.classSessionId)
      newObj.ClassId = undefined
      newObj.FromDate= undefined
      newObj.ToDate= undefined
    }
    let memberId;
    if (clientSubmitDetailsRef?.current?.UserMemberId) {
      memberId = clientSubmitDetailsRef?.current?.UserMemberId;
    } else {
      if (props?.pickpackage && props?.page === "inhouse") {
        newObj.PackageInstanceId = props?.packageData?.PackageInstanceId;
        memberId = props?.packageData?.UserMemberId;
      }
      if (props?.userData) {
        memberId = props?.userData?.UserMemberId;
      }
    }
    if (memberId) {
      newObj.UserMemberId = memberId;
    }
    if (SelectedPackageData) {
      newObj.PackageInstanceId = SelectedPackageData?.PackageInstanceId;
    }
    if(props?.selectedPackage && props?.from !=="calender"){
      newObj.UserMemberId = props?.selectedPackage?.UserMemberId;
    }
    const reqObj = { ...req, ...newObj };
    if (intialCall) {
      setIsClassLoading(true);
    }

    const result = await service.filterClassSchedule(reqObj);
    if (intialCall) {
      setIsClassLoading(false);
    }
    if (result?.ResponseCode === 100) {
      const classItems = constructClassListForDate(
        result?.Items || [],
        intialCall
      );
      const nextDate=result?.Items[result?.Items?.length-1]?.NextAvailableDate
      if (intialCall) {
        setclassSessionsList({
          ...classSessionsList,
          startDate: nextDate,
          TotalItems: result.TotalItems,
          classSessionData: classItems || [],
        });
      }
      return { classItems, startDate: nextDate,TotalItems: result.TotalItems} || null;
    }
    return null;
  }

  const handleClientConfirmation = () => {
    if (AllClientsList?.length > 1) {
      const selectedClientInfo = AllClientsList?.filter(
        (res: any) => res?.UserMemberId === clientFormValues?.UserMemberId
      )[0];
      // if (selectedClientInfo?.MemberType === 0) {
        toggleClientConfirmDialog();
        return;
      // } else {
      //   handleSubmit();
      // }
    } else {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    if (!props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage("", "error", true, "client");
      return;
    } else if (props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage("", "error", false, "staff");
      return;
    }

    if (props?.providerName) {
      if (isLoggedIn) {

        handleGroupCheckout({
          provider: props?.provider,
          session: selectedClasses,
        });
      } else {
        RemoveLocalStore("serviceItem");
        LocalStore("classItem", selectedClasses);
        setLoginStatus(true);
      }
    } else {
      if (isLoggedIn || props.from==="calender") {
        if(props.from==="calender" && !props?.userData){
          props?.handleNotificationMessage(
            `Please select client to Proceed`,
            "error"
          );
          return true;
        }
        if (props?.staffPage) {
          handleGroupCheckout({
            session: selectedClasses,
            staffPage: true,
            userData: props.from==="calender" ? props?.userData :clientSubmitDetails,
            uuid: props?.uuid,
          });
        } else {
          handleGroupCheckout({
            session: selectedClasses,
            userData: clientSubmitDetails,
          });
        }
      } else {
        RemoveLocalStore("serviceItem");
        LocalStore("classItem", selectedClasses);
        setLoginStatus(true);
      }
    }
  };

  const handleCloseLogin = () => {
    setLoginStatus(false);
  };

  const handleCopyClick = (e:any,item: any) => {
    e.stopPropagation()
    const text = `https://${tenantDomainName}.${domainName}.com/${item?.ShortCode}/${item?.ClassSessionId}`;
    copy(text);
    props?.handleNotificationMessage("Copied!", "success");
  };

  const handlePageChange = async (status = "more") => {
    if (status === "more") {
      setPageLoading(true);
      let moreData;
      if(props?.selectedPackage){
        moreData = await fetchClassSessions(false,props?.selectedPackage,true);
      }else{
        moreData = await fetchClassSessions(false,undefined,true);
      }
      if (moreData && moreData?.classItems?.length > 0) {
        setclassSessionsList({
          ...classSessionsList,
          classSessionData: moreData?.classItems,
          startDate: moreData?.startDate,
          TotalItems: moreData?.TotalItems,
        });
      } else {
        setclassSessionsList({
          ...classSessionsList,
          startDate: moreData?.startDate,
          TotalItems: moreData?.TotalItems,

        });
      }
      setPageLoading(false);
    }
  };

  const handleShowPickPackageDialog=(item:any)=> {
    setPickPackageDialog({
      ...pickPackageDialogRef?.current,
      showDialog:true,
      selectedGroup:item
    })
  }

  const handleClosePickPackageDialog=()=> {
    setPickPackageDialog(initialPickPackageDialog)
  }

  const handleSelectGroup =(dataItem:any,valCheck:any)=> {
    if(props?.SessionSignOff && !props?.showOnlySignoffAppts){
      setPopoverIndex(dataItem?.ClassSessionId);
    }else{
      handleSelectClass(
        dataItem,
        valCheck !== -1 ? "delete" : "add"
      );
    }
  }

  const findSelectedClassSessionItem= (classSessionItem:any,add:boolean)=> {
    let allsessions:any=[]
    selectedClasses.forEach((j: any) => {
      j?.values.forEach((k: any) => {
        allsessions.push(k)
      })
    })
    if(allsessions?.length > 0 && !add){
      const findItem=allsessions?.find((i:any)=> i?.ClassSessionId===classSessionItem?.ClassSessionId)
      return findItem
    }else{
      return classSessionItem
    }
  }

  const handleSelectClass = (item: any, type = "add", pickPackage = null,SelectBillingPeriod=false) => {
    const { staffPage, userData, handleNotificationMessage, pickpackage, page, packageData } = props;
    
    // Check user status and show appropriate error
    if (!CheckUserMemberStatus(userData)) {
      const errorType = staffPage ? "staff" : "client";
      handleNotificationMessage("", "error", staffPage, errorType);
      return;
    }
    if(type==="add"){
      const currentTime = moment().format();
      const currentDateTime = !props.staffPage ? convertToTimezone(new Date(currentTime)) : currentTime;

      if (!props.staffPage) {
        let { MinimumBookingDurationType = "minutes", MinimumBookingDuration } = item;

        if (!props.staffPage) {
          MinimumBookingDurationType = ["hours", "minutes", "days"][item?.MinimumBookingDurationType - 1] || "minutes";
        } else {
          MinimumBookingDuration = 0; 
        }
  
        const MimimumBookingBefore = moment(item?.ClassSessionStartDate)
          .subtract(MinimumBookingDuration, MinimumBookingDurationType)
          .toISOString();
        const MimimumBookingBeforeTime = moment(MimimumBookingBefore).format("YYYY-MM-DDTHH:mm:ss");
          if (currentDateTime <= MimimumBookingBeforeTime || props?.SessionSignOff) {
          }else{
            const errorMsg = `This group class cannot be selected as the minimum scheduling time has passed.`;
            props?.handleNotificationMessage(errorMsg, "error");
            return true;
          }
      }
            
    }
  
    if (item?.AvailableSlot > 0 || item?.CanBookAsWaitlist) {
      // Handle package selection logic
      if (
        type === "add" &&
        !selectedPackage &&
        usePackageFilter &&
        item?.PackagesList?.length > 0 &&
        !pickPackageDialogRef?.current?.showDialog
      ) {
        if (item?.PackagesList?.length === 1) {
          const classRecord = { ...item, enroll: true, SelectedPackage: item?.PackagesList[0] };
          setSelectedClasses(constructSelectedClassListForClass(classRecord));
          return;
        } else {
          handleShowPickPackageDialog(item);
          return;
        }
      }
  
      let classItem = { ...item, enroll: true, SelectedPackage: pickPackage || item?.PackagesList[0] };
      if (pickPackage) handleClosePickPackageDialog();
  
      // Package expiration validation
      if (pickpackage && page === "inhouse" && type === "add") {
        if (moment(packageData?.ExpireOn).isBefore(moment(item?.ClassSessionStartDate))) {
          handleNotificationMessage("Package is expiring before Appointment Start Time", "error");
          return;
        }
      }
      console.log(selectedPackage,'selectedPackage')
      console.log(packageBillingCycle,'packageBillingCycle')
      // Billing cycle validation
      if (selectedPackage && packageBillingCycle?.length > 0) {
    
        const item =findSelectedClassSessionItem(classItem,type === "add")
        const getBillingCycle = getBillingCycleForAPackageAndClass(item,type === "add");
        if (getBillingCycle ) {
          if (getBillingCycle.redeemableSchedules === 0 && type === "add" && selectedPackage?.SessionType === 1) {
            handleNotificationMessage("Maximum appointments already selected for billing cycle.", "error");
            return;
          }
          if(type === "add"){
            classItem = { ...classItem, billingCycle: getBillingCycle };
          }else{
            classItem = { ...classItem, billingCycle: null };
          }
          updateBillingCycleForAPackageAndClass(getBillingCycle, type === "add");
        } else {
          if(!props?.SessionSignOff){
            handleNotificationMessage("Selected appointment not in the billing cycle of the selected Package.", "error");
            return;
          }
        }
      }
      if(props?.SessionSignOff && props?.showOnlySignoffAppts){
        classItem={...classItem,enroll: false, SelectedPackage:null}
      }
      setSelectedClasses(constructSelectedClassListForClass(classItem));
      setPopoverIndex(null)
    } else {
      handleNotificationMessage("Slots are filled for selected appointment", "error");
    }
  };
  

  const handleFilter = (val: any, name: string) => {
    setFilterContent({ ...filterContent, [name]: val });
    if(name === "fromDate"){
      fetchClassSessions(undefined,undefined,true);
    }else{
      if(moment(filterContentRef?.current?.fromDate).format("YYYY-MM-DD") !== moment().format("YYYY-MM-DD")){
        fetchClassSessions(undefined,undefined,true);
      }else{
        fetchClassSessions();
      }
    }
  };

  const handleClearAllSlots = () => {
    setSelectedClasses([]);
  
    if (selectedPackage?.BillingPeriods) {
      setBillingPeriods(selectedPackage.BillingPeriods);
    }
  
    if (props?.page !== "inhouse" && props?.addMoreFunctionality) {
      props.unloadFunc();
    }
  };
  
  const selectedClassLength = () => 
    selectedClasses.reduce((total:any, val:any) => total + (val?.values?.length || 0), 0);
  

  const checkIfADateBetween = (checkDate: any, startDate: any, EndDate: any) => {
    return (moment(checkDate).format('YYYY-MM-DD') >= moment(startDate).format('YYYY-MM-DD') && moment(checkDate).format('YYYY-MM-DD') <= moment(EndDate).format('YYYY-MM-DD'))
  }

  const getBillingCycleForAPackageAndClass = (item: any,add:boolean) =>  {
    if(props?.SessionSignOff && props?.packageBillingPeriod){
      if(add){
        return packageBillingCycle?.find((val: any) => moment(props?.packageBillingPeriod?.ExpireFrom).format('YYYY-MM-DD') === moment(val?.ExpireFrom).format('YYYY-MM-DD'));
      }else{
        return packageBillingCycle?.find((val: any) => moment(item?.billingCycle?.ExpireFrom).format('YYYY-MM-DD') === moment(val?.ExpireFrom).format('YYYY-MM-DD'));
      }
    }else{
      return packageBillingCycle?.find((val: any) => checkIfADateBetween(item?.ClassSessionStartDate, val?.ExpireFrom, val?.ExpireOn));
    }

  }
  
  const updateBillingCycleForAPackageAndClass = (billingCycle: any, add: boolean) => {
    if (billingCycle) {
      setPackageBillingCycle((prev: any) => 
        prev.map((val: any) => {
          if (moment(billingCycle?.ExpireFrom).format('YYYY-MM-DD') === moment(val?.ExpireFrom).format('YYYY-MM-DD'))
          {
            return {
              ...val,
              redeemableSchedules: val.redeemableSchedules + (add ? -1 : 1),
            };
          }
          return val;
        })
      );
    }
  };
  
  const setBillingPeriods = (data: any) => {
    const updatedData = data?.length
      ? data.map((val: any) => ({ ...val, redeemableSchedules: val?.SchedulesToUse }))
      : [];
  
    setPackageBillingCycle(updatedData);
  };
  

  const handlePackageChange = (e: ComboBoxChangeEvent) => {
    if(!e.target.value){
      setSelectedPackage(undefined);
      setBillingPeriods(undefined);
    }else {
      setSelectedPackage(e.target.value);
      setBillingPeriods(e.target.value?.BillingPeriods);
    }
    handleClearAllSlots();
    setConfirmCartClearDialog(false);
    fetchClassSessions(true,undefined,true);
    if (!props?.classShortName || props?.classShortName === null) {
      fetchClassNames();
    }
  };


  const handleCancelConfirmClearCart = () => {
    setConfirmCartClearDialog(false);
  }
  return {
    PackagesData,
    take,
    handlePageChangePackage,
    AllClientsList,
    clientFormValues,
    toggleClientConfirmDialog,
    handleSubmit,
    isLoggedIn,
    clientSubmitDetails,
    handleClientSelection,
    selectedClassLength,
    handleClearAllSlots,
    selectedClasses,
    handleCloseLogin,
    loginStatus,
    confirmClientDialog,
    handleClientConfirmation,
    classesList,
    handleFilter,
    ClassNamefilterChange,
    filterContent,
    isClassLoading,
    classSessionsList,
    showMoreRef,
    pageLoading,
    handlePageChange,
    handleSelectClass,
    selectedPackage,
    handlePackageChange,
    setConfirmCartClearDialog,
    confirmCartClearDialog,
    handleCancelConfirmClearCart,
    btnLoading,
    checkZeroDollar,
    usePackageFilter,
    handleCollapse,
    handleExpand,
    expandedItem,
    handleUsePackageFilter,
    pickPackageDialog,
    handleClosePickPackageDialog,
    checkCompleteReedemViaPackage,
    handleCopyClick,
    packageBillingCycle,
    popoverIndex,
    setPopoverIndex,
    handleSelectGroup
  };
};

export default usePurchaseClassList;
