import { Card, CardBody, CardHeader } from "@progress/kendo-react-layout";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { ListView } from "@progress/kendo-react-listview";
import BuzopsButton from "generic-components/BuzopsButton";
import { FormInput } from "utils/form-components";
import { CurrencyFormat, checkHasTaxEnable } from "utils";
import { Checkbox, NumericTextBox, Switch } from "@progress/kendo-react-inputs";
import {
  DropDownList,
  ListItemProps,
  MultiSelect,
} from "@progress/kendo-react-dropdowns";
import successCheck from "./../../Images/successCheck.png";
import React from "react";
import { Error, Hint } from "@progress/kendo-react-labels";
import { Popup } from "@progress/kendo-react-popup";
import { PackageFrequency, PackageFrequencyEnum } from "utils/form-utils";
import { Tooltip } from "@progress/kendo-react-tooltip";
import moment from "moment";
import CheckPermissions from "utils/permissions";
import { PermissionEnum } from "utils/PermissionEnum";
import bzImages from "./../../Images";

export const TooltipContentPackageService = (propData: any) => {
  if (propData?.data?.length === 0) {
    return <div>{"No Surcharges"}</div>;
  }
  return (
    <>
      <ul className="list-group list-group-flush taxes-list">
        {propData?.Name && (
          <li
            className="list-group-item"
            style={{ fontSize: "0.875rem", fontWeight: 600 }}
          >
            {propData?.Name}
          </li>
        )}

        {propData?.data?.map((i: any) => (
          <li className="list-group-item d-flex justify-content-between align-items-center">
            <span>
              {i?.TaxName}&nbsp;({i?.Percentage}%)
            </span>
            <span className="price">{CurrencyFormat(i?.Amount)}</span>
          </li>
        ))}
        {propData?.TotalTaxAmount > 0 && (
          <li className="list-group-item d-flex justify-content-between align-items-center">
            <span>{"Total Surcharges"}</span>
            <span className="price">
              {CurrencyFormat(propData?.TotalTaxAmount)}
            </span>
          </li>
        )}
      </ul>
    </>
  );
};
export const CustomPopup = (props: any) => {
  return (
    <Popup
      {...props}
      anchorAlign={{
        horizontal: "center",
        vertical: "top",
      }}
      popupAlign={{
        horizontal: "center",
        vertical: "top",
      }}
    />
  );
};
const PackageOrderSummary = (props: any) => {
  const { appliedCoupons, visibleCouponField } = props;
  const renewOptionsNOLastPayment = [
    {
      id: 0,
      text: "None",
    },
    {
      id: 2,
      text: "Renew after Last Appointment Completion",
    },
  ];
  const { RenewalType } = props?.package;

  const MyCouponItemRender = (listItems: any) => {
    let item = listItems.dataItem;
    const amt = item?.couponAmt;
    let showDismiss = true;

    showDismiss = props?.package?.CouponDescription ? false : true;

    return (
      <>
        <div
          className="alert alert-success alert-dismissible bz-apply-coupon-new coupon-oneonone fade show mb-2"
          role="alert"
        >
          <h6 className="alert-heading">
            <img
              src={successCheck}
              style={{ width: "15px", height: "15px" }}
              alt=""
            />
            {item.CouponInformation}
          </h6>
          <p className="bz-coupon-desc">
            <span>Type:&nbsp; {item.TypeDescription},</span>&nbsp;
            <span>Coupon Amount: - {CurrencyFormat(amt)}</span>
          </p>
          {showDismiss && (
            <button
              type="button"
              className="close"
              title="Delete"
              onClick={() => {
                props.handleRemoveCoupon(listItems.index);
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          )}
        </div>
      </>
    );
  };

  const CouponApplyField = () => {
    return (
      <Form
        render={(couponRenderForm: any) => (
          <>
            <FormElement style={{ width: "100%" }}>
              <div className="row bz-coupon-field mt-0">
                <div className="col-md-12">
                  <div className="input-group mb-0">
                    <Field
                      id={"coupon"}
                      name={"coupon"}
                      component={FormInput}
                      placeholder="Enter Coupon Code"
                      hidelabel={true}
                      onChange={(e) => {
                        props.handleChangeCoupon(e.value);
                      }}
                      value={props.coupon}
                    />
                    <div className="input-group-append">
                      <BuzopsButton
                        primary
                        label={"Apply"}
                        disable={props.disable}
                        loading={props.couponBtnLoading}
                        onClick={() => props.handleCoupon()}
                        key={props.couponBtnLoading?.toString()}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </FormElement>
          </>
        )}
      />
    );
  };
  const itemRenderCoupon = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    let itemChildren;
    const index = itemProps?.dataItem?.Amount;
    if (itemProps?.dataItem?.TypeDescription === "Percentage") {
      itemChildren = (
        <span style={{ fontSize: "18px" }}>
          {itemProps?.dataItem.Description} <br />
          {index} {"%"}
        </span>
      );
    } else if (itemProps?.dataItem?.TypeDescription === "Fixed") {
      itemChildren = (
        <span style={{ fontSize: "18px" }}>
          {itemProps?.dataItem.Description} <br />
          {CurrencyFormat(index)}
        </span>
      );
    }

    return React.cloneElement(li, li.props, itemChildren);
  };

  const ApplyMultipleCouponField = () => {
    return (
      <div className="mt-1">
        <label className="d-flex justify-content-start ft13 mb-1">Coupons</label>
        <div className="couponsordersummary">
          <MultiSelect
            data={props?.filterCoupondata}
            textField="Description"
            dataItemKey="CouponId"
            placeholder="Please select coupon"
            onChange={(e) => {
              props?.handlePackageCoupon(e.value);
            }}
            filterable={true}
            onFilterChange={(e) => props?.filterCouponChange(e)}
            itemRender={itemRenderCoupon}
            value={appliedCoupons}
            className="bz-multiselect-arrow"
          />
        </div>
        <Hint className="d-flex justify-content-start">
          <span className="form-text text-muted text-left">
            Select a coupon from the list applicable for the package selected
            above.
          </span>
        </Hint>
      </div>
    );
  };

  const termsAndSignatureGrid = (props: any) => {
    let checkoutLabel = "Purchase";

    return (
      <>
        <div className="signature-terms mt-3">
          <div className={`signature-section `}>
            <div
              className={
                props?.signatureType === "type"
                  ? `buz-signature-type mt-3`
                  : `buz-signature mt-3`
              }
              onClick={() => {
                props.handleSignaturePad();
              }}
            // style={{ display: "flex", justifyContent: "center" }}
            >
              {props.signature !== "" ? (
                props.signatureText && props.signatureFont ? (
                  <span
                    className="text-signature"
                    style={{
                      fontFamily: props.signatureFont,
                      alignSelf: "center",
                      fontSize:
                        props?.signatureType === "type" ? `25px` : `36px`,
                      overflow: "visible",
                      padding: props?.signatureType === "type" ? `2px` : `0px`,
                    }}
                  >
                    {props.signatureText}
                  </span>
                ) : (
                  <img
                    src={props.signature}
                    placeholder={"Please Sign"}
                    width={"100%"}
                    height={"100%"}
                    onClick={() => {
                      props.handleSignaturePad();
                    }}
                  ></img>
                )
              ) : (
                <div className="buz-signature-pad">
                  <img src={bzImages.SignHereImg} alt="Sign-here"/>
                </div>
              )}
            </div>

            <p
              className={
                "text-left mb-1 buz-text-small buz-font-500 agree-copy"
              }
            >
              {props.disable ? (
                <>
                  <a className={"disable_link"}>View Your Agreement Copy</a>
                </>
              ) : (
                <>
                  <a
                    className={"buzops_link "}
                    onClick={() => props.handleAgreement()}
                  >
                    View Your Agreement Copy
                  </a>
                </>
              )}
            </p>
            <div className={"text-left mb-0 buz-text-small mt-2 buz-font-500"}>
              <Checkbox
                checked={props?.termsandConditions}
                defaultChecked={false}
                disabled={props?.disable}
                onChange={() => props?.handleTermsConditionDialog(!props?.termsandConditions)}
              />{" "}
              <span style={{ paddingLeft: '5px' }}> I agree to the{" "}</span>
              <span className={"mb-1 buz-text-small buz-font-500 agree-copy"}>
                {props?.disable ? (
                  <>
                    <a className={"disable_link"}>{"Terms & Conditions"}</a>
                  </>
                ) : (
                  <>
                    <a
                      className={"buzops_link "}
                      onClick={() => props?.handleTermsConditionDialog(null)}
                    >
                      {"Terms & Conditions"}
                    </a>
                  </>
                )}
              </span>
            </div>
          </div>

          <div className="button-submit-section">
            <BuzopsButton
              primary
              label={checkoutLabel}
              disable={props.disable}
              loading={props.btnLoading}
              key={props.btnLoading}
              onClick={() => props.handlePayNow()}
            />
          </div>
        </div>
      </>
    );
  };

  const OrderSummary = (props: any) => {
    const {
      packageCostAfterDiscount,
      handleIsTaxSkipped,
      IsTaxSkipped,
      staffPage,
      Taxes,
      downpaymentForm,
      PredictedTaxes,
      PredictedTotalTaxAmount,
      TotalTaxAmount,
    } = props;
    const PackageItem = props?.package;
    const { paidToday } = downpaymentForm;
    let totalPackageAdditionalFees = 0;

    if (props?.package?.PackageFeeMappings?.length > 0) {
      const PackageAdditionalFees = props.package.PackageFeeMappings
        .filter((fee: any) => fee.CanFeesRemoved === false)
        .map((fee: any) => ({
          ...fee,
          RevisedAmount: fee.RevisedAmount ? fee.RevisedAmount : 0
        }));

      totalPackageAdditionalFees = PackageAdditionalFees.reduce((sum: number, fee: any) => sum + fee.RevisedAmount, 0);
    }


    const downPaymentAmount = !IsTaxSkipped && PackageItem?.CanCollectTaxes
      ? paidToday + TotalTaxAmount
      : paidToday;

    const finaldownPaymentAmount = PackageItem?.ClientCanSkipPayment ? 0 : ((packageCostAfterDiscount + totalPackageAdditionalFees === 0) && paidToday === 0) ? 0 :  downPaymentAmount + totalPackageAdditionalFees;

    console.log('finalDownPayment',finaldownPaymentAmount);
    console.log('TotalTaxAmount',TotalTaxAmount);
    console.log('paidToday',paidToday);
    
    
    
    console.log('totalPackageAdditionalFees', totalPackageAdditionalFees);
    console.log('props', props);
    return (
      <>
        {checkHasTaxEnable() &&
          PackageItem?.CanCollectTaxes &&
          staffPage &&
          Taxes?.length > 0 && (
            <div
              className={
                "d-flex align-items-center text-left mb-3 buz-text-small buz-font-500 mt-2"
              }
            >
              <Switch
                checked={!IsTaxSkipped}
                defaultChecked={false}
                onChange={() => handleIsTaxSkipped()}
                className="mr-1 d-inline-flex"
              />
              {`Surcharges`}
            </div>
          )}
        {checkHasTaxEnable() && PackageItem?.CanCollectTaxes && !IsTaxSkipped && Taxes?.length > 0 && (
          <>
            <p className={"text-left mb-1 buz-font-500  mt-3"} style={{ display: 'flex', gap: '10px', justifyContent: 'space-between' }}>
              <span className="service-titlename2">
                Total before Surcharges
              </span>
              <span className={"service-value float-right"}>
                {CurrencyFormat(packageCostAfterDiscount + totalPackageAdditionalFees)}
              </span>
            </p>
            <p className={"text-left mb-1 buz-font-500 "} style={{ display: 'flex', gap: '10px', justifyContent: 'space-between' }}>
              <span className="service-titlename2 buz-font-600">
                <Tooltip
                  content={(tooltipProps: any) => {
                    return (
                      <TooltipContentPackageService
                        title={tooltipProps.title}
                        data={PredictedTaxes}
                        TotalTaxAmount={PredictedTotalTaxAmount}
                        Name={PackageItem?.Name}
                      />
                    );
                  }}
                  tooltipClassName="bz-tooltip-table"
                >
                  <a
                    title="Surcharges"
                    style={{
                      borderBottom: "1px dotted #0d6efd",
                      cursor: "pointer",
                    }}
                  >
                    {"Surcharges to be collected"}
                  </a>
                </Tooltip>
              </span>
              <span className={"service-value buz-font-600 float-right"}>
                {CurrencyFormat(PredictedTotalTaxAmount)}
              </span>
            </p>
            <hr />
          </>
        )}
        <p className={"text-left mb-1 buz-font-500 mt-2"} style={{ display: 'flex', gap: '10px', justifyContent: 'space-between' }}>
          <span className="service-titlename2 buz-font-600">
            {"Order Total"}
          </span>
          <span className={"service-value buz-font-600 float-right"}>
            {CurrencyFormat(
              !IsTaxSkipped && PackageItem?.CanCollectTaxes
                ? packageCostAfterDiscount + PredictedTotalTaxAmount + totalPackageAdditionalFees
                : packageCostAfterDiscount + totalPackageAdditionalFees
            )}
          </span>
        </p>
        <p className={"text-left mb-1 buz-font-500 "} style={{ display: 'flex', gap: '10px', justifyContent: 'space-between' }}>
          <span className="service-titlename2 buz-font-600">
            {"Down Payment"}
          </span>
          <span className={"service-value buz-font-600 float-right"}>
            {CurrencyFormat(
              finaldownPaymentAmount
            )}
          </span>
        </p>
        {PackageItem?.ClientCanSkipPayment && (
          <p className={"text-left mb-1 buz-font-500 "} style={{ display: 'flex', gap: '10px', justifyContent: 'space-between' }}>
            <span className="">{`Pay On ${moment(PackageItem?.StartDate).format(
              "MM/DD/YYYY"
            )}`}</span>
            <span className={"service-value  float-right"}>
              {CurrencyFormat(
                !IsTaxSkipped && PackageItem?.CanCollectTaxes
                  ? packageCostAfterDiscount + PredictedTotalTaxAmount + totalPackageAdditionalFees
                  : packageCostAfterDiscount + totalPackageAdditionalFees
              )}
            </span>
          </p>
        )}
      </>
    );
  };

  const showPageType = (props: any) => {
    const packageFreq = PackageFrequency?.filter(
      (i: any) => i?.id === props?.package?.Frequency
    )[0]?.description;

    return (
      <>
        <p className={"text-left pt-1 pb-2 buz-font-600 mt-0 ft13"}>
          Packages <span className={"float-right"}></span>
        </p>
        <div className={"mb-1 order-summary-class-details"}>
          <span className={"order-summary-class-name"}>
            {props?.package?.Name}
            {props?.package?.Frequency !== PackageFrequencyEnum.ONETIME && (
              <small>{` (${props?.downpaymentForm?.recurrenceUnit?.description})`}</small>
            )}
          </span>
          <span className={"order-summary-class-cost"}>
            {CurrencyFormat(
              props?.staffPage
                ? props?.package?.DiscountedCost
                : props?.package?.Cost
            )}
          </span>
        </div>
        {props?.package?.IsProrated && props?.package?.ProratedCost > 0 && (
          <div className={"mb-1 order-summary-class-details"}>
            <span className={"order-summary-class-name"}>
              {"Prorated Amount"}
            </span>
            <span className={"order-summary-class-cost"}>
              {CurrencyFormat(props?.package?.ProratedCost)}
            </span>
          </div>
        )}


        {props?.package?.PackageFeeMappings?.length > 0 && (
          <>
            <div >
              {props?.package?.PackageFeeMappings.map((i: any, index: any) => (
                <p key={index} className={`mb-2 buz-font-500 service-para ${CheckPermissions(PermissionEnum["AdministratorAccess"]) ? "additionalfees" : ""}`} style={{ display: "flex", justifyContent: "space-between", alignItems: 'start', marginBottom: '10px' }}>
                  <div className="w-100 order-summary-class-details w-100">



                    <div className={"mb-1 text-left "}>
                      <span className={"order-summary-class-name text-dark "}>
                        {CheckPermissions(PermissionEnum["AdministratorAccess"]) && (
                          <Switch
                            checked={!i?.CanFeesRemoved}
                            defaultChecked={false}
                            onChange={() => props?.handlePackageReccurence("CanFeesRemoved", !i?.CanFeesRemoved, i)}
                            className="mr-1 d-inline-flex"
                          />
                        )}
                        {i?.FeeType}
                      </span>

                    </div>
                    <span className="valuememebership text-dark">
                      {CheckPermissions(PermissionEnum["AdministratorAccess"]) ? <NumericTextBox
                        id={"RevisedAmount"}
                        name={"RevisedAmount"}

                        placeholder="RevisedAmount"
                        min={1}
                        format={"c2"}
                        disabled={i?.CanFeesRemoved}
                        value={i?.RevisedAmount}
                        onChange={(e) => {
                          const value: any = e.target.value;
                          if (value >= 1) {
                            props?.handlePackageReccurence("RevisedAmount", value, i);
                          } else {
                            props?.handlePackageReccurence("RevisedAmount", 1, i);
                          }
                        }}
                      /> : CurrencyFormat(i?.Amount)}
                    </span>
                  </div>

                </p>
              ))}
            </div>
          </>
        )}
      </>
    );
  };

  const today1 = new Date();
  const threeMonths = new Date(today1);
  threeMonths.setMonth(threeMonths.getMonth() + 3);

  return (
    <>

      <Card
        className={`text-center order-summary-card order-summary-card bordernone row ${props?.staffPage ? "staff-order-summary-card" : "client-order-summary mt-0"
          }`} style={{ border: '0' }}
      >
        <CardHeader
          className={"text-left px-0 order-summary-header d-flex align-items-center"}
        >
          <h6 className={"card-heading"}>Order Summary</h6>
        </CardHeader>
        <CardBody className="p-1">
          {(props?.page === "inhouse-package" || props?.staffPage) && (
            <div
              className="savedCard bz-salesPerson-select pb-2 pt-0 mt-0"
              style={{ marginTop: "0px", textAlign: 'left' }}
            >
              <label>Sales Person</label>
              <DropDownList
                data={props?.salesPersons}
                textField="UserName"
                dataItemKey="UserId"
                listNoDataRender={() => (
                  <span className={"bzo-paymentProfile-noData"}>
                    No Sales Person Available
                  </span>
                )}
                value={props?.selectedSalesPerson}
                style={{ width: "100%" }}
                onChange={(e) => props.handleSalesPersonSelection(e.value)}
              />
              {!props?.selectedSalesPerson && (
                <Error>{"Please select SalesPerson"}</Error>
              )}
            </div>
          )}
          {showPageType(props)}
          {props?.package?.SessionType === 3 ? (
            <div className="my-2">
              <label className="d-flex mb-1">Renewal Type</label>
              <div style={{ display: "flex" }}>
                <DropDownList
                  id={"RenewalType"}
                  data={renewOptionsNOLastPayment}
                  onChange={(e) => {
                    props?.handlePackageReccurence("RenewalType", e.value?.id);
                  }}
                  textField="text"
                  value={
                    renewOptionsNOLastPayment?.filter(
                      (i: any) => i?.id === props?.package?.RenewalType
                    )[0]
                  }
                  defaultValue={renewOptionsNOLastPayment[0]}
                  dataItemKey="id"
                  style={{ width: "100%" }}
                  disabled={
                    !props?.staffPage &&
                    !props?.package?.CanClientOverrideRenewalOption
                  }
                />
              </div>
            </div>
          ) : null}
          {!props?.staffPage &&
            props.page === "online-package" &&
            props?.package?.Cost > 0 && (
              <>
                <div className="coupon-list mt-1">
                  <ListView data={appliedCoupons} item={MyCouponItemRender} />
                </div>
                {appliedCoupons.length === 0 && CouponApplyField()}
                {appliedCoupons.length > 0 &&
                  visibleCouponField &&
                  CouponApplyField()}
              </>
            )}

          {props?.staffPage &&
            props.page === "online-package" &&
            props?.package?.Cost > 0 && (
              <>
                <ApplyMultipleCouponField />
                <div className="coupon-list mt-3">
                  <ListView data={appliedCoupons} item={MyCouponItemRender} />
                </div>
              </>
            )}

          <OrderSummary {...props} />
          {termsAndSignatureGrid(props)}
        </CardBody>
      </Card>
    </>
  );
};

export default PackageOrderSummary;
