import React from "react";
import SelectedSlotListCard from "./SelectedSlotListCard";
import PaymentProfile from "components/booking/PaymentProfile";
import OrderSummary from "components/booking/OrderSummary";
import { Checkbox, Input, NumericTextBox } from "@progress/kendo-react-inputs";
import BuzopsButton from "generic-components/BuzopsButton";
import AdditionalPayment from "components/AdditionalPayment/AdditionalPayment";
import TermsAndConditions from "components/checkout/TermsAndConditions";
import SessionSignoffSelectedSlots from "./SessionSignoffSelectedSlots";
interface IAppointmentCheckout {
  mainState?: any;
  schedulerCalendarState?: any;
  refs?: any;
  callBacks?: any;
  userDetails?: any;
  calendarPage?: boolean;
  showAdditionalPayment?: any;
  staffPage?: boolean;
  quickCheckout?: boolean;
  quicklinks?: boolean;
}
const AppointmentCheckout = ({
  callBacks,
  mainState,
  schedulerCalendarState,
  refs,
  userDetails,
  calendarPage = false,
  showAdditionalPayment,
  staffPage = false,
  quickCheckout = false,
  quicklinks = false
}: IAppointmentCheckout) => {
  console.log(schedulerCalendarState, 'schedulerCalendarStateschedulerCalendarStateschedulerCalendarState')
  return (
    <>
      <div className="row col-md-12">
        <div className="col-md-7 selectedslotscheckout">
          <div className="three-panel">
            {quickCheckout ? (
              <SessionSignoffSelectedSlots
                callBacks={callBacks}
                mainState={mainState}
                refs={refs}
                checkoutPage={true}
                schedulerCalendarState={schedulerCalendarState}
              />
            ) : (
              <SelectedSlotListCard
                callBacks={callBacks}
                mainState={mainState}
                refs={refs}
                schedulerCalendarState={schedulerCalendarState}
                checkoutPage={true}
              />
            )}
    
            <PaymentProfile
              UserMemberId={
                userDetails?.PrimaryMemberId != undefined
                  ? userDetails?.PrimaryMemberId
                  : userDetails?.UserMemberId
              }
              onSelectedCard={callBacks?.handleChangeSavedCard}
              fetchCard={callBacks?.handlefetchCards}
            />
          </div>
        </div>
        <div className="col-md-5 ordersummaryappts pl-2 pr-0">
          <OrderSummary
            staffPage={staffPage || calendarPage || quicklinks}
            serviceData={mainState.Appointment}
            page="service"
            Taxes={schedulerCalendarState?.taxes}
            TotalTaxAmount={schedulerCalendarState?.TotalTaxAmount}
            appliedCoupons={schedulerCalendarState?.appliedCoupons}
            handleCoupon={() => callBacks?.handleCoupon()}
            handleChangeCoupon={(val: string) =>
              callBacks?.handleChangeCoupon(val)
            }
            handleProratedRate={(val: number) =>
              callBacks?.handleProratedRate(val)
            }
            packageCostAfterDiscount={
              schedulerCalendarState?.packageCostAfterDiscount
            }
            couponAmount={schedulerCalendarState?.couponAmount}
            handleRemoveCoupon={(index: number) =>
              callBacks?.handleRemoveCoupon(index)
            }
            filterCoupondata={schedulerCalendarState?.filterCoupondata}
            handleOneonOneCoupon={(val: any) =>
              callBacks?.handleOneonOneCoupon(val)
            }
            IsTaxSkipped={schedulerCalendarState?.IsTaxSkipped}
            handleIsTaxSkipped={callBacks?.handleIsTaxSkipped}
            handleSalesPersonSelection={(data: any) =>
              callBacks?.handleSalesPersonSelection(data)
            }
            salesPersons={mainState?.salesPersons}
            selectedSalesPerson={schedulerCalendarState?.selectedSalesPerson}
            quickCheckout={quickCheckout}
          />
          <div className="row col-md-12 mt-3">
            <div className={"text-left mb-0 buz-text-small p-0 buz-font-500"}>
              <Checkbox
                checked={schedulerCalendarState?.termsandConditions}
                defaultChecked={false}
                disabled={schedulerCalendarState?.disable}
                onChange={() =>
                  callBacks?.handleTermsConditionDialog(
                    !schedulerCalendarState?.termsandConditions
                  )
                }
              />{" "}
              <span className="ml-1">  I agree to the{" "}</span>
              <span className={"mb-1 buz-text-small buz-font-600 agree-copy"}>
                {schedulerCalendarState?.disable ? (
                  <a className={"disable_link"}>{"Terms & Conditions"}</a>
                ) : (
                  <a
                    className={"buzops_link "}
                    onClick={() => callBacks?.handleTermsConditionDialog(null)}
                  >
                    {"Terms & Conditions"}
                  </a>
                )}
              </span>
            </div>
          </div>
          <div className="row mt-3">
            <div style={{ textAlign: "right" }}>
              <BuzopsButton
                label={"Purchase"}
                loading={schedulerCalendarState?.btnLoading}
                disable={
                  schedulerCalendarState?.disable ||
                  schedulerCalendarState?.btnLoading
                }
                onClick={callBacks?.handleCheckOut}
              />
            </div>
          </div>
        </div>
      </div>
      {showAdditionalPayment?.dialog && (
        <AdditionalPayment
          fetchPaymentProfiles={callBacks?.fetchPaymentProfiles}
          userMemberId={userDetails.UserMemberId}
          setShowAdditionalPayment={callBacks?.setShowAdditionalPayment}
          paymentProfile={schedulerCalendarState?.paymentProfile}
          PurchaseAmount={schedulerCalendarState?.packageCostAfterDiscount}
          CreditAmount={schedulerCalendarState?.paymentSubmitProfile?.Credit}
          showAdditionalPayment={showAdditionalPayment}
          handleChangeAdditionalPaymentProfile={
            callBacks?.handleChangeAdditionalPaymentProfile
          }
          handleAddAdditionalPayment={callBacks?.handleAddAdditionalPayment}
        />
      )}
      {schedulerCalendarState?.termsandConditionsDialog && (
        <TermsAndConditions
          handleTermsConditionDialog={callBacks?.handleTermsConditionDialog}
          handleTermsConditions={callBacks?.handleTermsConditions}
        />
      )}
    </>
  );
};

export default AppointmentCheckout;
